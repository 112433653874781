import styled from "styled-components";
import banner from "../../Images/sevice.jpg";
export const Demobanner = styled.div`
  .main {
    background-image: url(${banner});
    width: 99.1vw;
   
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom:100px;
    padding-top:100px;
    .top {
      display: flex;
      justify-content: center;
  
      align-items: center;
      margin: 0 auto;
      .content {

    
        border-radius: 10px;
        width: 80%;
        // height: 500px;
        background: white;
       display:flex;
       flex-direction:column;
       gap:10px;
        .heading {
          display: flex;
          justify-content: center;
          margin-top: 50px;
        }
        .msg {
          display: flex;
          justify-content: center;
          margin-top: 50px;
          h1 {
            font-family: "pt_serifbold";
            font-weight: 425;
            color: #2829328a;
            margin: 0px;
          }
        }
        .para {
          margin-bottom: 100px;
          display: flex;
          justify-content: center;
          h1 {
            font-family: "paytone_oneregular";
            margin: 0px;
            font-size: 18px;
            line-height: 35px;
            opacity: 0.8;
            @media(max-width:400px){
              font-size:16px;
            }
          }
          @media(max-width:750px){
            margin-bottom:20px
          }
        }
        .sub {
          display: flex;
          justify-content:space-around;
          .wrapper {
            width: 90%;
            display: flex;
            justify-content: space-between;
            flex-wrap:wrap;
            @media(max-width:750px){
              flex-direction:column;
            }
          }
          .first {
            margin-bottom:20px;
            img {
              width: 60px;
              height: 60px;
              
            }
            small {
              font-family: "dm_sansregular";
              font-size: 18px;
              @media(max-width:938px){
                font-size:16px;
              }
              @media(max-width:886px){
                font-size:15px;
              }
              @media(max-width:858px){
                font-size:14px;
              }
              @media(max-width:782px){
                font-size:13px;
              }
              @media(max-width:750px){
                font-size:20px;
              }
              @media(max-width:580px){
                font-size:18px;
              }
              @media(max-width:454px){
                font-size:16px;
              }
              @media (max-width:400px){
                font-size:16px;
              }
             @media(max-width:340px){
               font-size:15px;
             }
            }
            .sent {
              margin-top: 20px;
              h2 {
                font-family: "montserratregular";
                font-size: 14px;
                width: 300px;
                text-align: justify;
                @media(max-width:1214px){
                  width:200px;
                }
                @media(max-width:938px){
                  width:190px;
                }
                @media(max-width:886px){
                  width:177px;
                  font-size:14px;
                }
                @media(max-width:858px){
                  width:192px;
                  font-size:12px;
                }
                @media(max-width:820px){
                  width:175px;
                  font-size:12px;
                }
                @media(max-width:760px){
                font-size:11px;
                }
                @media(max-width:750px){
                  width:397px;
                  font-size:14px;
                }
                @media(max-width:600px){
                  width: 320px;
                  font-size: 13px;
                }
                @media(max-width:540px){
                  width:250px;
                  font-size: 13px;
                }
                @media(max-width:454px){
                width: 240px;
                font-size:14px; 
                }
                @media(max-width:403px){
                  font-size:14px;
                  width:200px;
                }
                @media(max-width:360px){
                  width:170px;
                }
              }
             
              
            }
            @media(max-width:750px){
              display:flex;
              flex-direction:column;
              align-items:center;
              justify-content:center;
         
            }
          }
          }
          @media(max-width:750px){
            height:unset;
          }
        }
        @media(max-width:750px){
          padding-top:unset;
          }
      }
     
    }
  }
`;
