import {
  DeleteNotificationlist,
  MyNotificationlist,
} from "../../../infrastructure/Notification";

const actions = {
  setisLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },

  setDelNotifi:
    (DelNotfy) =>
    ({ setState }) => {
      setState({ DelNotfy });
    },
  GetNotifications:
    () =>
    async ({ setState, dispatch, getState }) => {
      try {
        dispatch(actions.setisLoading(true));
        let res = await MyNotificationlist();
        setState({Notify:res.data.data})
        // dispatch(actions.setNotifi(res.data.data));
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
  DelNotifications:
    () =>
    async ({ setState, dispatch, getState }) => {
      const { DelNotfy } = getState();

      try {
        dispatch(actions.setisLoading(true));
        await DeleteNotificationlist(DelNotfy);
        dispatch(actions.GetNotifications())
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
};
export default actions;
