import React, { useEffect, useState } from "react";
import { Request } from "./style";
import { useHistory } from "react-router-dom";
import {
  CloseOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { RequestItemForm } from "./Store";

function RequestForm() {
  const [
    { name, phone_number, address, remarks, item_name, quantity, },
    {
      setName,
      setPhone_number,
      setAddress,
      setRemarks,
      setItem_name,
      setQuantity,
      postrequestitem,
      setitem_request
    },
  ] = RequestItemForm();
  console.log(name, ".................name");
  console.log(phone_number, ".................phone_number");
  console.log(address, ".................address");
  console.log(remarks, ".................remarks");
  console.log(item_name, ".................item_name");
  console.log(quantity, ".................quantity");

  const handlerequestitem = (value) => {
    postrequestitem(value);
    console.log(value,'...........value')
  };
  console.log(postrequestitem,'...........post')
  const [showResults, setShowResults] = React.useState(false);
  console.log(showResults, "...................showresult");
  const onClick = () => setShowResults(true);

  const buttonClicks = () => {
    setShowResults(false);
    setTimeout(function open(event) {
      document.querySelector(".requestform").style.display = "block";
    }, 500);
  };
  //......................................................................................
  const [serviceList, setServiceList] = React.useState([{ service: "" }]);
  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...serviceList];
    list[index][name] = value;
    setServiceList(list);
  };

  const handleServiceAdd = () => {
    setServiceList([...serviceList, { service: "" }]);
  };

  const handleServiceRemove = (index) => {
    const list = [...serviceList];
    list.splice(index, 1);
    setServiceList(list);
  };
  //.............................................................................................
  useEffect(() => {
    setTimeout(function open(event) {
      document.querySelector(".requestform").style.display = "block";
    }, 500);
  }, []);

  return (
    <Request>
      {showResults ? (
        <div className="popupss">
          <button className="docker" onClick={buttonClicks}>
            Request Form
          </button>
        </div>
      ) : (
        <div>
          <div className="requestform">
            <div>
              <CloseOutlined
                style={{
                  color: "#f5f5f5",
                  opacity: 0.6,
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "10px",
                  fontSize: "22px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                onClick={onClick}
              />
            </div>

            <div className="popupheaders">
              <h1>order request</h1>
            </div>

            <div className="popupdeals">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  height: "220px",
                  overflow: "scroll",
                }}
                className="itemsection"
              >
                <div className="inputsection">
                  <input
                    type="text"
                    placeholder="Name"
                    onChange={(e) => setName(e.target.value)}
                  />

                  <input
                    type="number"
                    placeholder="Mobile"
                    onChange={(e) => setPhone_number(e.target.value)}
                  />
                </div>
                {serviceList.map((singleService, index) => (
                  <div>
                    <div
                      className="inputsection"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <input
                        type="text"
                        placeholder="Item"
                        onChange={(e) => setItem_name(e.target.value)}
                      />

                      <input
                        type="text"
                        placeholder="Quantity"
                        onChange={(e) => setQuantity(e.target.value)}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "flex-end",
                      }}
                    >
                      {serviceList.length - 1 === index && (
                        <div>
                          <PlusCircleOutlined
                            style={{ color: "white" }}
                            onClick={handleServiceAdd}
                          />
                        </div>
                      )}
                      {serviceList.length !== 1 && (
                        <div>
                          <MinusCircleOutlined
                            style={{ color: "white" }}
                            onClick={handleServiceRemove}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                <div className="inputsection">
                  <textarea
                    placeholder="Address"
                    onChange={(e) => setAddress(e.target.value)}
                  />

                  <textarea
                    placeholder="Notes"
                    onChange={(e) => setRemarks(e.target.value)}
                  />
                </div>
              </div>

              <div className="inputsection">
                <button onClick={handlerequestitem}>Send</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Request>
  );
}

export default RequestForm;
