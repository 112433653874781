import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Fooditems from "../FoodCategories";
import { DIV } from "./style";
import { firebase } from "../../common/Firebase/index";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GetAdvertisementsHome } from "./store";
import Loader from "../common/widgets/Loader";
import chatbot from "../../Images/chatbot.png";
import { OurProfile } from "../UserProfile/store/index";
import { ShopsearchType } from "../Shopsearch/store/index";
import Carousel from "react-bootstrap/Carousel";
import Chatbot from "react-chatbot-kit";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../Chatbot/config";
import ActionProvider from "../Chatbot/ActionProvider";
import MessageParser from "../Chatbot/MessageParser";
// import { getToken } from "../../common/Firebase/index";
// import { Getmyfcm } from "../FCM/store";
toast.configure();
function Home() {
  let history = useHistory();
  const [{ profile_photoe, user }, { getUser, settokenres }] = OurProfile();

  const [
    { adds, isLoading, items, iSauthenticated, showBot },
    { getadds, getitems, setiSauthenticated, settoggleBot },
  ] = GetAdvertisementsHome();

  // const [
  //   { phoneNumber, fcmtoken },
  //   { setfcmtoken,setphoneNumber, posttokens },
  // ] = Getmyfcm();

  const [{ search }, { setSearch }] = ShopsearchType();
  const [token, setToken] = useState("");
  localStorage.setItem("token", token);

  useEffect(() => {
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          //  setphoneNumber(user.phoneNumber)
          .then(
            (latestToken) => setToken(latestToken),
            setiSauthenticated(firebase.auth().currentUser)
          )
          .catch((err) => console.log(err));
      }
      if (token === "") {
      } else {
        getadds();
        getUser();
      }
    });
  }, [token]);

  ///..........global search............................
  const mypagelink = (value) => {
    setSearch(value);
    setTimeout(() => {
      history.push(`/shopsearch`);
    }, 1000);
  };

  const showbutton = () => {
    if (user.gender === null) {
      toast("Complete Your Profile ");
    } else {
      settoggleBot(!showBot);
    }
  };

 

  if (isLoading) {
    return <Loader />;
  }
  return (
    <DIV>
      <Carousel>
        {adds?.map((res) => (
          <Carousel.Item className="citem">
            {res.file_type === "image" ? (
              <img
                className="d-block w-100"
                // style={{ width: "100%", height: "95vh" }}
                src={res?.file}
                alt=""
              />
            ) : (
              <video
                style={{ width: "100%" }}
                loop="true"
                autoplay="true"
                muted
              >
                <source src={res?.file} type="video/mp4"></source>
              </video>
            )}
          </Carousel.Item>
        ))}
      </Carousel>

      <div className="color">
        <div className="search">
          <input
            placeholder="  Search for restaurant/caters/chef/food"
            onChange={(e) => mypagelink(e.target.value)}
          ></input>
        </div>

        <div className="category">
          <Fooditems />
        </div>
      </div>

      <div
        className="uparrow"
        style={{ postion: "fixed", position: "relative" }}
      >
        <div style={{ position: "absolute", top: -400, zIndex: 100 }}>
          {showBot && (
            <Chatbot
              config={config}
              actionProvider={ActionProvider}
              messageParser={MessageParser}
              headerText="Chatbot"
              placeholderText="Chat with us"
            />
          )}
        </div>

        <img
          src={chatbot}
          alt="chatbot"
          style={{
            height: "60px",
            width: "60px",
            objectFit: "cover",
            cursor: "pointer",
          }}
          onClick={() => showbutton()}
        />
      </div>
    </DIV>
  );
}

export default Home;
