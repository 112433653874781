import { get, del, put, post } from "../common/remote/base_api";
export const Eventlist = (data) => {
  return get(`user/event/list/`, data);
};

export const Eventdel = (id) => {
  return del(`user/event/delete/${id}`);
};
export const Eventupdate = (id, data) => {
  return put(`user/event/update/${id}/`, data);
};
export const Eventlistbyid = (id) => {
  return get(`user/event/view/${id}/`);
};
export const Eventmakedefault = (id) => {
  return get(`user/event_set_default/${id}/`);
};
