import styled from "styled-components";
export const Search = styled.div`

.arrow {
    padding-left: 20px;
    padding-top: 50px;
   
    background: #f9d500;
    button{
      border-radius:60%;
      height: 55px;
width: 55px;
display: flex;
justify-content: center;
align-items: center;
border: none;
background: #f9fcf1;

    }
  }
  .background {
    background: #f9d500;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .wrapper {
      width: 80%;

      img {
      }
      .separate {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
        margin-bottom: 50px;
      }
    
      .searchbar {
        margin-bottom: 150px;
        input {
          font-family: "dm_sansregular";
          background-color: white;
          height: 80px;
          border: solid;
          border-radius: 15px;
          width: 100%;
          padding-left: 20px;
          font-size: 18px;
          outline: none;
          border-color: white;
          letter-spacing: 1px;
          input::placeholder {
            color: black;
           
          }

          input:textarea {
            color: white;
          }
        }
      }
          }
        }
  .heading{
   display: flex;
   margin-top: 30px;
   justify-content:center;
    .wrapper2{
      width:80%;
   
    
      .categories{
        gap:10px;
        display: flex;
       
        align-items:center;
       
        h1{
          font-family: "Quicksand", sans-serif;
           font-size:28px;
           font-weight:bold
        }
       
        }
        .border{
           margin-bottom:50px;
          border-bottom:2px solid #e6e6e6;
        }
      
    }
    .item{

      
      .shopnamess{
        display: flex;
        justify-content:space-between;
        margin-top:10px;
        padding:5px;
        flex-direction: column;
        cursor:pointer;
        &:hover{
          box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
          
          border-radius:10px;
        }
        h2{
          font-size:18px;
          font-weight:bold;
          font-family: "dm_sansregular";
          @media (max-width:924px){
            font-size:16px;
          }
          @media (max-width:710px){
            font-size:14px;
          }
          @media (max-width:598px){
            font-size:16px;
          }
          @media(max-width:506px){
            font-size:14px;
          }
          @media (max-width:434px){
            font-size:12px;
          }
        }
        small{
          width:50px;
          height:20px;
          background-color:#96d707;
          border-radius:4px;
          display:flex;
          display: flex;
          color: white;
          justify-content: space-around;
          align-items: center;
        }
      }
      .small{
        h1{
          font-size:16px;
          font-family: "dm_sansregular";
         
          @media(max-width:924px){
            font-size:16px;
          }
          @media (max-width:710px){
            font-size:14px;
          }
          @media (max-width:598px){
            font-size:16px;
          }
          @media(max-width:506px){
            font-size:14px;
          }
          @media (max-width:434px){
            font-size:12px;
          }
        }
      }


      display: flex;
      gap:30px;
      flex-wrap: wrap;
      padding-bottom:50px;
      justify-content:center;
      .prods{
        width:355px;
        height:300px;
       
        cursor: pointer;
        border-radius:12px;
        @media (max-width:924px){
          width: 333px;
          height: 294px;
        }
        @media (max-width:869px){
          width: 300px;
          height: 252px;
        }
        @media(max-width:815px){
          width: 266px;
          height: 240px;
        }
        @media(max-width:780px){
          width: 233px;
          height: 210px;
        }
        @media(max-width:710px){
          width:220px;
          height:180px;
        }
        @media(max-width:612px){
          width: 200px;
          height: 170px;
        }
        @media (max-width:598px){
          width: 290px;
          height: 250px;
        }
        @media (max-width:506px){
          width: 260px;
          height: 230px;
        }
        @media (max-width:434px){
          width: 230px;
          height: 200px;
        }
        @media(max-width:430px){
          width: 200px;
          height: 189px;
        }
        &:hover{
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }
        .image{
          display: flex;
          justify-content:center;
           width:355px;
          height:300px;
          border-radius:12px;
         overflow: hidden;
          img{
            object-fit:cover;
              transition: transform 0.1s;
          :hover {
            transform: scale(1.1);
   
            }
            
            border-radius:8px;
           width:100%;
           height:100%;
          
          }
          border-radius:12px;
          @media (max-width:924px){
            width: 333px;
            height: 294px;
          }
          @media (max-width:869px){
            width: 300px;
            height: 252px;
          }
          @media(max-width:815px){
            width: 266px;
            height: 240px;
          }
          @media(max-width:780px){
            width: 233px;
            height: 210px;
          }
          @media(max-width:710px){
            width:220px;
            height:180px;
          }
          @media(max-width:612px){
            width: 200px;
            height: 170px;
          }
          @media (max-width:598px){
            width: 290px;
            height: 250px;
          }
          @media (max-width:506px){
            width: 260px;
            height: 230px;
          }
          @media (max-width:434px){
            width: 230px;
            height: 200px;
          }
          @media(max-width:430px){
            width: 200px;
            height: 189px;
          }
        }
        .heading{
          display:flex;
          flex-direction: column;
         position: relative;
         top:-174px;
         margin-left:10px;
         margin-right:10px;
       
     
         justify-content:center;
         height:50px;
         display: flex;
         align-items: center;
         padding:0;
         margin:0;
        .section{
        
           width:85%;
           border-radius:8px;
           display:flex;
           flex-direction: column;
           justify-content:center;
           align-items:center;
           gap:8px;
         .name{
           
           height:10px;
         }
         h2{
            font-family: "dm_sansregular";
            font-size:16px;
            margin: 0px;
            letter-spacing:1px;
            padding: 0px;
            font-weight:bold;
            color:black;
           
          }
        }
        .sml{
         height:10px;
        }
          h1{
             font-family: "dm_sansregular";
             font-size:18px;
             color:gray;
           
          }
        
        }

        }
        
          
        }
       

      }
    }
  }

`;
