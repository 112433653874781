import { createStore, createHook, defaults } from "react-sweet-state";
import initialState from "./initialState";
import actions from "./actions";

defaults.devtools = true;
const FoodCategory = createStore({
  name: "food_type",
  initialState,
  actions,
});
export const FoodItems = createHook(FoodCategory, {
  selector: (state) => state,
});
