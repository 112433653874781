import styled from "styled-components";
import Colors from "../common/Colors/Colors"
export const Top = styled.div`
  .main {
    .back {
      padding-top: 30px;
      margin-left: 20px;
    }
    background: #fdd305;

    .wrappers {
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;

      .box {
        width: 80%;
        .image {
          display: flex;
          gap: 50px;

          img {
            border-radius: 12px;
            width: 260px;
            height: 260px;

            object-fit: cover;
            @media (max-width: 650px) {
              height: 220px;
            }
            @media (max-width: 583px) {
              height: 194px;
              width: 225px;
            }
            @media (max-width: 495px) {
              width: 212px;
            }
            @media (max-width: 348px) {
              width: 194px;
              height: 160px;
            }
          }
          .contact {
            h1 {
              font-size: 26px;
              font-weight: bold;
              color: #001729;
              font-family: "dm_sansregular";
              @media (max-width: 615px) {
                font-size: 24px;
              }
              @media (max-width: 348px) {
                font-size: 22px;
              }
            }
            small {
              font-size: 16px;

              color: #001729;
              font-family: "dm_sansregular";
              @media (max-width: 470px) {
                text-align: center;
                width: 200px;
              }
              @media (max-width: 348px) {
                font-size: 14px;
              }
            }
            .rating {
              display: flex;
              color: white;
              align-items: center;
              gap: 5px;
              width: 50px;
              height: 20px;
              background-color: #96d707;
              border-radius: 4px;
              display: flex;
              display: flex;
              justify-content: space-around;

              small {
                color: white;
              }
              margin-top: 100px;
              @media (max-width: 650px) {
                margin-top: 45px;
              }
              @media (max-width: 583px) {
                margin-top: 30px;
              }
            }
            @media (max-width: 470px) {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              margin-bottom: 20px;
            }
          }
          @media (max-width: 495px) {
            gap: 30px;
          }
          @media (max-width: 470px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
        .viewmore {
          padding-top: 10px;
          button {
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            background: #ffbf00;
            border: none;
            padding: 10px;
            border-radius: 6px;
            @media (max-width: 615px) {
              font-size: 16px;
            }
            @media (max-width: 583px) {
              font-size: 14px;
            }
          }
          @media (max-width: 615px) {
            width: 170px;
          }
        }
        @media (max-width: 521px) {
          width: 86%;
        }
      }
      @media (max-width: 470px) {
        height: unset;
      }
    }
  }

  .wrapper2 {
    height: 200px;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    .search {
      width: 90%;
      .serch {
        display: flex;
        justify-content: center;
        padding-top: 64px;
        input {
          font-family: "dm_sansregular";
          background-color: white;
          height: 70px;
          border: solid;
          border-radius: 15px;
          width: 60%;
          padding-left: 20px;
          font-size: 18px;
          outline: none;
          border-color: white;
          @media (max-width: 434px) {
            font-size: 16px;
          }
          @media (max-width: 388px) {
            font-size: 14px;
          }
          @media (max-width: 340px) {
            font-size: 12px;
          }
        }
      }
    }
  }
  .wrapper3 {
    display: flex;
    justify-content: center;
    min-height: 500px;
    align-items: center;
    .prdts {
      width: 40%;
      .itm {
        margin-top: 10px;
        margin-bottom: 10px;
        align-items: center;
        display: flex;
        justify-content: space-between;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        padding: 20px;
        border-radius: 8px;
        .imgL {
          display: flex;
          flex-direction: column;
          width: 25%;
          h1 {
            font-weight: bold;
            font-family: "dm_sansregular";
            font-size: 18px;
            font-family: "dm_sansregular";
            @media (max-width: 380px) {
              font-size: 16px;
            }
          }
          h2 {
            font-family: "dm_sansregular";
            font-size: 14px;
          }
        }

        .detailsR {
          width: 25%;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 20px;
          img {
            width: 120px;
            height: 120px;
            border-radius: 6px;
            @media (max-width: 980px) {
              width: 110px;
              height: 110px;
            }
          }
          button {
            cursor: pointer;
            font-size: 16px;
            font-family: "dm_sansregular";
            width: 125px;
            height: 30px;
            background-color: ${Colors.secondary};
            border: none;
            color: White;
            border-radius: 4px;
            cursor: pointer;
            display: flex;
            align-items: center;
            &:active {
              background-color: black;
              color: white;
            }
            @media (max-width: 980px) {
              width: 114px;
              font-size: 14px;
            }
            @media (max-width: 380px) {
              width: 100px;
              font-size: 14px;
            }
          }
          @media (max-width: 900px) {
            width: 40%;
          }
        }
      }
      @media (max-width: 980px) {
        width: 60%;
      }
      @media (max-width: 540px) {
        width: 70%;
      }
      @media (max-width: 380px) {
        width: 80%;
      }
    }
  }
`;
