
import {  message as msg } from "antd";
import { RequestItem } from "../../../../infrastructure/Groceries/RequestForm";
const actions = {
    setisLoading:
      (isLoading) =>
      ({ setState }) => {
        setState({ isLoading });
      },
      setName:
      (name) =>
      ({ setState }) => {
        setState({ name });
      },
      setPhone_number:
      (phone_number) =>
      ({ setState }) => {
        setState({ phone_number });
      },
      setAddress:
      (address) =>
      ({ setState }) => {
        setState({ address });
      },
      setRemarks:
      (remarks) =>
      ({ setState }) => {
        setState({ remarks });
      },
      setItem_name:
      (item_name) =>
      ({ setState }) => {
        setState({ item_name });
      },
      setQuantity:
      (quantity) =>
      ({ setState }) => {
        setState({ quantity });
      },
      postrequestitem :
      (data) =>
      async ({ dispatch, getState }) => {
        dispatch(actions.setisLoading(true));
        const {
          name,
          phone_number,
          address,
          remarks,
          item_name,
          quantity
          
        } = getState();
        var bodyFormdata = new FormData();
        bodyFormdata.append("name",name);
        bodyFormdata.append("phone_number",phone_number);
        bodyFormdata.append("address",address);
        bodyFormdata.append("remarks",remarks);
        bodyFormdata.append("item_name",item_name);
        bodyFormdata.append("quantity",quantity);
        try {
          const res = await RequestItem(bodyFormdata);
          dispatch(actions.setisLoading(false));
        } catch (err) {
        } finally {
          dispatch(actions.setisLoading(false));
        }
      },
     
    }
    export default actions;