import React, { useState, useEffect } from "react";
import { CartItem } from "./style";
import { Modal, Button } from "antd";
import {
  ArrowLeftOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  DeleteFilled,
} from "@ant-design/icons";
import BackArrow from "../BackArrow";
import { useHistory } from "react-router-dom";
import { Getmycartitems } from "./store";
import Loader from "../common/widgets/Loader";
import CartEmpty from "./Component/EmptyCart";
import { Popconfirm } from "antd";
import { firebase } from "../../common/Firebase/index";
import { Shopsanditems } from "../Products/store";
function Cart() {
  const [checkouts, setcheckouts] = useState("");
  const [rerender, setrerender] = useState("");

  let history = useHistory();
  const ButtonLink = () => {
    history.push("/foodtypes/:id");
  };
  const ButtonEventLink = () => {
    history.push("/eventpage");
  };
  const [
    { mycart, quantity, isLoading, Cartt, Removedata, rerendercart },
    {
      Getcartitems,
      RemoveCartitem,
      setCartRemovedata,
      Addquantity,
      Checkoutcartitem,
      setQuantity,
      setrerndercart,
    },
  ] = Getmycartitems();
  const [{ rendercart }, { setrendercart }] = Shopsanditems();
  //modal state................................................................
  const [iscartqty, setIscartqty] = useState(1);

  // const handleOkCart = () => {
  //   let number = parseInt(unit);
  //   let cart = modalcart;
  //   let shoping
  //   shoping({
  //     food_id: cart.food_id,
  //     quantity: cart.quantity + number,
  //     action: cart.action,
  //     force_add: cart.force_add,
  //   });
  //  console.log(shoping,"shoping...............")
  //   setQuantity(shoping);
  //   Addquantity();
  //   setIscart(false);
  // };

  //'''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''

  const Handlechange = (res) => {
    setCartRemovedata(res);
    RemoveCartitem();
    setrerndercart(!rerendercart);
    setrendercart(!rendercart);
    localStorage.setItem("cart", mycart?.order_items?.length - 1);
  };

  //Add an items................................................................
  const quantityAdd = (res) => {
    setQuantity(res);
    Addquantity();
    setIscartqty(1);
  };
  const quantitydecrease = (res) => {
    setQuantity(res);
    Addquantity();
    setIscartqty(1);
    setrerndercart(!rerendercart);
    setrerender(!rerender);
  };

  const CheckoutLink = (id) => {
    Checkoutcartitem(id, history);
  };

  const [token, setToken] = useState("");

  useEffect(() => {
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => {});
      }
    });
  }, []);

  // useEffect(() => {
  //   window.addEventListener("keypress", (e) => {
  //     if (e.key === "Enter") {
  //       quantityAdd();
  //     }
  //   });
  // }, []);

  useEffect(() => {
    Getcartitems();
  }, [token, rerendercart]);
  if (isLoading) {
    return <Loader />;
  }
  return (
    <CartItem>
      <div className="top">
        <div style={{ marginLeft: 50 }}>
          <BackArrow />
        </div>
        <h1>Your Cart </h1>
      </div>
      {mycart?.order_items?.length === 0 ? (
        <CartEmpty />
      ) : (
        <div className="content">
          <div className="border">
            <table className="official">
              <thead>
                <tr className="head-row-off">
                  <th className="head-off"></th>
                  <th className="head-off">
                    {" "}
                    <h1>Item</h1>
                  </th>
                  <th className="head-off">
                    {" "}
                    <h1>Price</h1>
                  </th>
                  <th className="head-off">
                    {" "}
                    <h1>Quantity</h1>{" "}
                  </th>
                  <th className="head-off1">
                    {" "}
                    <h1>Add Quantity</h1>{" "}
                  </th>
                  <th className="head-off">
                    {" "}
                    <h1>Total</h1>
                  </th>

                  <th className="head-off"></th>
                </tr>
              </thead>

              <tbody>
                {mycart?.order_items?.map((res) => (
                  <tr className="body-row-off">
                    <td>
                      <img src={res.food.image}></img>
                    </td>
                    <td style={{ display: "flex", marginTop: "30px" }}>
                      {" "}
                      <h1>{res.food.name}</h1>
                    </td>
                    <td style={{ marginTop: "30px" }}>
                      {" "}
                      <h2> &#8377; {res.food.price}</h2>
                    </td>
                    <td style={{ marginTop: "30px" }}>
                      {" "}
                      <h2 style={{ padding: 0, margin: 0, width: "100" }}>
                        {" "}
                        {res?.quantity}
                      </h2>
                    </td>
                    <td style={{ display: "flex", gap: 10 }}>
                      {" "}
                      <div className="qty">
                        <small id="myelement">
                          <MinusCircleOutlined
                            style={{ fontSize: 25, cursor: "pointer" }}
                            onClick={() =>
                              quantitydecrease({
                                food_id: res.food.id,
                                quantity: res.quantity - parseInt(iscartqty),
                                action: "add",
                                force_add: false,
                              })
                            }
                          />
                        </small>
                        {/* <label>{res?.quantity}</label> */}
                        <input
                          style={{
                            minWidth: "30px",
                            maxWidth: "40px",
                            paddingLeft: 2,
                            background: "white",
                            border: "1px solid grey",
                            textAlign: " center",
                          }}
                          type="text"
                          name="name"
                          pattern="[0-9]"
                          title="Title"
                          autocomplete="off"
                          onChange={(e) => setIscartqty(e.target.value)}
                        ></input>
                        <small>
                          <PlusCircleOutlined
                            style={{ fontSize: 25, cursor: "pointer" }}
                            onClick={() =>
                              quantityAdd({
                                food_id: res.food.id,
                                quantity: res.quantity + parseInt(iscartqty),
                                action: "add",
                                force_add: false,
                              })
                            }
                          />
                        </small>
                      </div>
                    </td>

                    <td>
                      {" "}
                      <h2> &#8377; {res?.item_total}</h2>
                    </td>
                    <td style={{ cursor: "pointer", display: "flex" }}>
                      <Popconfirm
                        placement={"rightBottom"}
                        title="Are you sure？"
                        onConfirm={() =>
                          Handlechange({
                            food_id: res.food.id,
                            quantity: 0,
                            action: "remove",
                            force_add: false,
                          })
                        }
                      >
                        <DeleteFilled />
                      </Popconfirm>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {mycart?.order_items?.length === 0 ? (
        ""
      ) : (
        <div className="footercart">
          <div className="containercart">
            <div className="rowcart">
              <div className="arrowcart">
                <div
                  className="Arrow"
                  style={{ marginLeft: "15px", marginBottom: "10px" }}
                >
                  <ArrowLeftOutlined
                    style={{ fontSize: "18px", color: "white" }}
                  />
                </div>
                <button onClick={() => ButtonLink()}> Continue Shopping</button>
              </div>
            </div>
            <div className="column">
              <div className="check">
                <div className="tag">
                  <h1>Items :</h1>
                  <h2>{Cartt.item_count}</h2>
                </div>
                <div className="tag">
                  <h1>Total :</h1>
                  <h2>&#8377; {Cartt.total_amount}</h2>
                </div>
                <div className="tag">
                  <h1>Gst :</h1>
                  <h2>&#8377; {Cartt.gst_amount}</h2>
                </div>
                <div className="tag">
                  <h1>Service charge :</h1>
                  <h2>&#8377; {Cartt.service_charge}</h2>
                </div>
                <div className="tag">
                  <h1>Subtotal :</h1>
                  <h2>&#8377; {Cartt.final_price}</h2>
                </div>
              </div>
              <div className="addressevent">
                <button onClick={() => ButtonEventLink()}>
                  Change address for the event
                </button>
              </div>
              <div className="btn">
                <button onClick={() => CheckoutLink(Cartt.id)}>
                  {" "}
                  Check out
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </CartItem>
  );
}
export default Cart;
