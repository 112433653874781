import styled from "styled-components";
import background from "../../Images/backgroundlogin.png";

export const DIV = styled.div`
  margin-top: 150px;
  margin-bottom: 100px;
  .heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
    img {
      width: 100px;
      height: 10px;
      margin-bottom: 20px;
    }
    h1 {
      font-family: "pt_serifbold";
      font-size: 27px;
      font-weight: 425;
      color: #2829328a;
      margin: 0 px;
    }
    h2 {
      font-family: "paytone_oneregular";
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #282932;
      margin: 0 px;
    }
  }
  .main {
    position: relative;
    background-image: url(${background});
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .mobile {
      display: flex;
      justify-content: center;
      .image {
        justify-content: space-around;
        width: 50%;
        @media (max-width: 1350px) {
          width: 40%;
        }
        @media (max-width: 1086px) {
          display: none !important;
        }
        img {
          left: 85px;
          width: 500px;
          height: 500px;
          top: 60px;
        }
      }
    }
    .content {
      width: 700px;
      height: 500px;
      @media (max-width: 1086px) {
        width: unset;
        height: unset;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
      .text {
        margin-top: 60px;
        @media (max-width: 872px) {
     display:flex;
     justify-content: center;
         
        }
        h1 {
          line-height: 1.2;
          font-size: 50px;
          font-weight: 600;
          font-family: "paytone_oneregular";
          color: white;
          margin: 0px;
          padding: 0px;

          @media (max-width: 1030px) {
            font-size: 43px;
          }
          @media (max-width: 954px) {
            font-size: 40px;
          }
          @media (max-width: 872px) {
            font-size: 35px;
           
          }
          @media (max-width: 380px) {
            font-size: 26px;
           
          }
        }
      }
      .text2 {
        h1 {
          color: #ddff00;
          font-size: 24px;
          font-weight: 700;
          font-family: "open_sansitalic";
          padding-bottom: 60 px;
          padding-top: 23 px;
          @media (max-width: 1030px) {
            font-size: 18px;
          }
          @media (max-width: 1014px) {
            font-size: 16px;
       
          }
          @media (max-width: 540px) {
            font-size: 16px;
            width:200px;
            display:flex;
            justify-content: center;
          }
         
        }
      }
      .img2 {
        display: flex;
        gap: 20px;
        margin-top: 100px;
        &:hover {
          gap: 30px;
        }
        img {
          overflow: hidden;

          transition: transform 0.2s;
          &:hover {
            transform: scale(1.2);
          }
          cursor: pointer;
          width: 200px;
          height: 70px;
          @media (max-width: 1014px) {
            width: 180px;
            height: 65px;
          }
          @media (max-width: 980px) {
            width: 167px;
            height: 58px;
          }
          @media (max-width: 954px) {
            width: 162px;
            height: 52px;
          }
          @media (max-width: 954px) {
            width: 150px;
          
          }
        }
        @media (max-width: 886px) {
          flex-direction: column;
          margin-left: 70px;
        }
        @media (max-width: 872px) {
          gap: 28px;
          margin-top: 80px;
        }
        @media (max-width: 786px) {
          gap: 30px;
          margin-top: 65px;
        }
      }
    }
    @media (max-width: 1096px) {
    }
  }
`;
