const initialState = {
  customer: "",
  isLoading: true,
  rerender: false,
  event_name: "",
  name: "",
  delivery_address: "",
  pincode: "",
  landmark: "",
  latitude: "",
  longitude: "",
  delivery_date: "",
  delivery_time: "",
  caretaker_name: "",
  caretaker_contact: "",
  alternate_contact: "",
  geo_location: "geolocation",
};

export default initialState;
