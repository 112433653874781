import React from "react";
import { Back } from "./style";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
function BackArrow() {
  let history = useHistory();

  const goBack = () => {
    history.goBack();
  };
  return (
    <Back className="buttonArrow"  onClick={() => goBack()}>
      <button type="submit">
        <ArrowLeftOutlined style={{fontSize:26,fontWeight: "bold",color:'black'}} />
      </button>
    </Back>
  );
}

export default BackArrow;
