import React, { useEffect } from "react";
import { Deal } from "./style";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import wtsap from "../../../Images/images.jpeg";
import { Getgroceries } from "../Popup/store";
import soldout from "../../../Images/soldout.png";
import BackArrow from "../../BackArrow";
function DetailedPage() {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [{ isLoading, groceries }, { setisLoading, getgroceries }] =
    Getgroceries();
  useEffect(() => {
    getgroceries();
  }, []);
  return (
    <Deal>
      <div className="dealheader">
        <div style={{borderRadius:"50%",border:"1px solid grey",marginLeft:"20px"}}> 
        <BackArrow />
        </div>
       
      </div>
      <div className="dealslider">
        <div className="dealssss">
          {/* <Slider {...settings}>
           <div className="dealone">
            <div className="dealscontent"></div>
            <div className='headdeal'>
              <h1>Indian Pear</h1>
              <h6>250gm/70</h6>
            </div>
          </div>
        </Slider> */}
          {groceries?.map((res) => (
            <div className="dealone">
              {res.is_active === true ? (
                <div className="dealscontent">
                  <img src={res.image} />
                  
               
                </div>
              ) : (
                <div className="dealsscontent">
                  <div className="backimage"> 
                  <img src={res.image} />
                  </div>
                  <div className="soldoutimg">
                  <img src={soldout} className="soldouts" />
                  </div>
                </div>
              )}
              <div className="headdeal">
                <h1>{res.item_name}</h1>
                <h6>
                  FBP : ₹{res.our_price}/{res.quantity}{res.item_unit}
                </h6>
                {res.online_price===null?(
                       <div style={{color:"white"}}>""</div>
                        ):(
                      <h6>
                        AOP : ₹ {res.online_price}/ {res.quantity}{" "}
                        {res.item_unit}
                      </h6>
                        )}
                {res.local_price === null ? (
                <div style={{color:"white"}}>""</div>
                ) : (
                  <h1>
                    LMP :₹ {res.local_price}/{res.quantity}
                    {res.item_unit}
                  </h1>
                )}
               
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="contactno">
        <div className="wtsap">
          <img src={wtsap} />
        </div>
        <div className="nmbrdeal">
          <h1>9020880206</h1>
        </div>
      </div>
    </Deal>
  );
}

export default DetailedPage;
