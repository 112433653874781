import { Getmoredetails } from "../../../infrastructure/Shopinformation";

const actions = {
  setisLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  // setViews:
  //   (views) =>
  //   ({ setState }) => {
  //     setState({ views });
  //   },
  // setshoplists:
  //   (shoplists) =>
  //   ({ setState }) => {
  //     setState({ shoplists });
  //   },
  // setLicences:
  //   (licences) =>
  //   ({ setState }) => {
  //     setState({ licences });
  //   },
  //   setImages:
  //   (Images) =>
  //   ({ setState }) => {
  //     setState({ Images });
  //   },
  getviews:
    (id) =>
    async ({ setState,dispatch }) => {
      dispatch(actions.setisLoading(true));
      try {
        const res = await Getmoredetails(id);
        setState({views:res.data.data})
        setState({shoplists:res.data.data?.certificates})
        setState({licences:res.data.data?.licences})
        setState({Images:res.data.data?.images})
        // dispatch(actions.setViews(res.data.data));
        // dispatch(actions.setshoplists(res.data.data?.certificates));
        // dispatch(actions.setshoplists(res.data.data?.licences));
        // dispatch(actions.setImages(res.data.data?.images))
        dispatch(actions.setisLoading(false));
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
};
export default actions;
