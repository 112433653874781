import styled from "styled-components";
export const CloseButton = styled.div`
  display: flex;
  justify-content:space-between;
  small {
    font-family: "dm_sansregular";
    margin-left:10px;
    font-weight:bold;
    letter-spacing:.2px;
  }
  button {
    width: 40px;
    border: none;
    background-color: white;
    color:#001729;
    padding-bottom:20px;
  }
`;
