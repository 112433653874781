import { Getfoodtypes } from "../../../infrastructure/foodcategories";

const actions = {
 
  setLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  Getitems:
    () =>
    async ({ setState,dispatch }) => {
      dispatch(actions.setLoading(true));
      try {
        let res = await Getfoodtypes();
        setState({items: res.data.data})
        // dispatch(actions.setitems(res.data.data));
      } catch (err) {
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
};
export default actions;
