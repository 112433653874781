import styled from "styled-components";
export const Noti = styled.div`
padding-top: 30px;
  .arrrownoti {

    padding-left: 50px;

  }
  .bellicon{
      width:25%;
      display: flex;
      justify-content: flex-end;
      margin-bottom:50px;
  }
  .notisection {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom:100px;
    .innersection {
      width: 70%;
      min-height: 600px;
      max-height: 630px;
      cursor: pointer;
      overflow-y: scroll;
      -ms-overflow-style: none;
      -webkit-scrollbar-width: none;
      -moz-scrollbar-width: none;
      -ms-scrollbar-width: none;
      scrollbar-width: none;
      display: flex;
      flex-direction: column;
      align-items: center;

      .smallsection {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
        gap:5px;
      
        margin-top: 20px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 6px;
        padding: 20px;
        .image {
          width: 100px;
          height: 100px;
          border-radius: 50%;
        

          img {
            width: 100%;
            width: 100%;
          }
        }
        .noticontent {
          display: flex;
          flex-direction: column;
          gap: 5px;
          align-items: center;
          justify-content: center;

          small {
            font-size: 16px;
            font-family: "dm_sansregular";
            text-align:justify;
          }
        }
      }
    }
  }
`;
