import styled from "styled-components";
export const Cart = styled.div`
padding-top:50px;
padding-bottom:50px;
  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 46vh;
    padding-bottom:50px;
    img {
      width: 400px;
      object-fit: cover;
    }
  }
`;
