import React from 'react'
import {Cart} from './style'
import cartempty from '../../../../Images/cartisempty.png'
function CartEmpty() {
    return (
        <Cart>
        <div className="image">
            <img src={cartempty} alt ='alt' />
        </div>
        </Cart>
    )
}

export default CartEmpty
