import styled from "styled-components";
export const Update = styled.div`

background-color:#f5f5f5;

.upperpar{
  display:flex;
  justify-content:center;
  align-items:center;
  .main{
    display:flex;
    // flex-direction:column;
      justify-content:center;
      align-items:center;
      
       width:50%;
       @media (max-width:710px){
         width:60%;
       }
       @media(max-width:540px){
         width:70%;
       }
      @media(max-width:390px){
        width:80%;
      }
    .section{
          width:100%;
          display:flex;
          flex-direction:column;
          justify-content:center;
          align-items:center;
    .name{
      display:flex;
      flex-direction:column;
       margin-top:50px;
      gap: 20px;
      background: #fafafa;
      align-items:center;
      justify-content:center;
      width:60%;
    
          input {
            border-radius: 3.5px;
            padding: 1vh;
            font-size: 1.7vh;
            border: 1px solid #e0e0e0;
            height: 5vh;
            background: white;
            box-shadow: inset 0 -1px 0 #006fb1;
            font-family: "montserratregular";
            font-size: 14px;
            width:100%;
          }
          @media(max-width:1210px){
            width:70%;
          }
          @media(max-width:980px){
            width:80%;
          }
    }
    .adrs{
      display:flex;
      justify-content:space-between;
      flex-direction:column;
      gap:20px;
      align-items:center;
      justify-content:center;
      margin-top:15px;
      width:60%;
      input {
        border-radius: 3.5px;
        padding: 1vh;
        font-size: 1.7vh;
        border: 1px solid #e0e0e0;
        height: 5vh;
        background: white;
        box-shadow: inset 0 -1px 0 #006fb1;
        font-family: "montserratregular";
        font-size: 14px;
        width:100%;
      }
      @media(max-width:1210px){
        width:70%;
      }
      @media(max-width:980px){
        width:80%;
      }
    }
    .adrs1{
      width:60%;
      display:flex;
      gap:20px;
      margin-top:15px;
      flex-direction:column;
      input{
        width:100%;
        border-radius: 3.5px;
     
        padding: 1vh;
        font-size: 1.7vh;
        border: 1px solid #e0e0e0;
        height: 5vh;
        background: white;
        box-shadow: inset 0 -1px 0 #006fb1;
        font-family: "montserratregular";
        font-size: 14px;
        width:100%;
  
      }
      @media(max-width:1210px){
        width:70%;
      }
      @media(max-width:980px){
        flex-direction:column;
        width:80%;
      }
    }
  }
    .btn{
      display:flex;
      justify-content:center;
      margin-top:20px;
      margin-bottom:40px;
      width:60%;
      button{
          width:100px;
          height:40px;
          background-color:#006fb1;
          border:none;
          border-radius:4px;
          color:white;
          &:active{
            background-color: black;
            color: white;
          }
      }
  
    }
  
  }
  }
  
  
  }



`;
