const initialState = {
  isLoading: true,
  Shopview: [],
  foodview: [],
  Addtocart: "",
  cartres: "",
  addtocartreset:"",
  search:"",
  rendercart:false,
  Rating:'',
};
export default initialState;
