const initialState = {
    isLoading: "true",
    name:"",
    phone_number:"",
    address:"",
    remarks:"",
    item_name:"",
    quantity:"",
  
  };
  export default initialState;