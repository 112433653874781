import { createStore, createHook, defaults } from "react-sweet-state";
import initialState from "./initialState";
import actions from "./actions";

defaults.devtools = true;
const Createevent = createStore({
  name: "Event",
  initialState,
  actions,
});

export const CreateEventuser = createHook(Createevent, {
  selector: (state) => state,
});
  