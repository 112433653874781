import React from "react";
import image from "../../../../Images/icon.png";
import { OurProfile } from "../../../UserProfile/store";
function Avatar() {
  const [{ user }, {getUser}] = OurProfile();






localStorage.setItem("gender",user.gender)
React.useLayoutEffect(() => {
  getUser();
  localStorage.getItem("gender");
}, []);

  return (
    <div>
      <img
        src={image}
        style={{ width: 40, height: 40, borderRadius: 20, marginRight: 15 }}
      ></img>
    </div>
  );
}

export default Avatar;
