import React, { useState, useEffect } from "react";
import { Carts } from "./style";
import { Link, useHistory } from "react-router-dom";

import BackArrow from "../BackArrow";
import { ArrowLeftOutlined } from "@ant-design/icons";

import Loader from "../common/widgets/Loader";
import { Getmycartsummury } from "./store";
import { firebase } from "../../common/Firebase/index";
function CartSummary({ match }) {
  let history = useHistory();
  const [idd, setidd] = useState(match.params.id);
  const [{ isloading, user, events }, { Getcartorderdetails }] =
    Getmycartsummury();

  const [token, setToken] = useState("");
  useEffect(() => {
    
    // import { firebase } from "../../common/Firebase/index";
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
    });
  }, []);
  useEffect(() => {
    Getcartorderdetails(idd);
  }, [token]);

  const Eventpagelink = (id) => {
    history.push(`/updateevent/${id}`);
  };
  const orderlist = () => {
    history.push(`/order`);
  };

  if (isloading) {
    return <Loader />;
  }
  return (
    <Carts>
      <div className="order">
        <div className="back" style={{ marginTop: "30px" }}>
          <BackArrow />
        </div>
      </div>

      <div className="main">
        <div className="first">
          <div className="left">
            <div className="order1">
              <h1>Order Summary</h1>
            </div>
            <div className="products">
              {user?.order_items?.map((res) => (
                <ul>
                  <li>
                    <img src={res?.food?.image} alt="cart"></img>
                  </li>{" "}
                  <li>
                    <h1>{res?.food?.name}</h1>
                  </li>
                  <li>
                    <h1>&#8377; {res?.food?.price}</h1>
                  </li>
                  <li>
                    <h1>
                      x {res?.quantity} {res?.unit?.name}
                    </h1>
                  </li>
                </ul>
              ))}
            </div>
            <div className="itemsdetails">
              <div className="service">
                <div className="itm">
                  <h1>items</h1>
                  <small>{user?.item_count}</small>
                </div>
                <div className="itm">
                  <h1>Service Charge</h1>
                  <small>₹ {user?.service_charge}</small>
                </div>
                <div className="itm">
                  <h1>GST:</h1>
                  <small>₹ {user?.gst_amount}</small>
                </div>
                <div className="itm">
                  <h1>Final Amount</h1>
                  <small>₹ {user?.cart_total}</small>
                </div>
              </div>
            </div>
            <div className="Advanced">
              <div className="serv">
                <div className="itm">
                  <h1>Advance Payment:</h1>
                  <small>₹{user?.advance_payment}</small>
                  <div className="delivery">
                    <span>+ delivery charges: ₹{user?.delivery_charge}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="second">
              <div className="sec">
                <div className="itm">
                  <h1>Second Payment</h1>
                  <small>₹ {user?.final_payment}</small>
                </div>
              </div>
            </div>
            <div className="btn" onClick={() => orderlist()}>
              <div className="Arrow" style={{ marginLeft: "10px" }}>
                <ArrowLeftOutlined />
              </div>

              <button>Back to order</button>
            </div>
          </div>
          <div className="right">
            <div className="shipping">
              <h1>Delivery Information</h1>
            </div>
            <div className="address">
              <div className="text">
                <div className="itm">
                  <label>Address</label>
                  <input value={events?.landmark}></input>
                </div>
                <div className="itm">
                  <label>Alternate contact</label>
                  <input value={events?.alternate_contact}></input>
                </div>
              </div>
            </div>
            <div className="address">
              <div className="text">
                <div className="itm">
                  <label>Delivery date</label>
                  <input value={events?.delivery_date}></input>
                </div>
                <div className="itm">
                  <label>Delivery time</label>
                  <input value={events?.delivery_time}></input>
                </div>
              </div>
            </div>
            <div className="event">
              <div className="text">
                <button onClick={() => Eventpagelink(events.id)}>
                  Change address for the event
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Carts>
  );
}

export default CartSummary;
