import {
  Getshopview,
  GetFoodview,
  AddToCart,
} from "../../../infrastructure/products";
import { notification } from "antd";
import { message, Button } from "antd";
import initialState from "./initialState";

const actions = {
  setLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  // setShopview:
  //   (Shopview) =>
  //   ({ setState }) => {
  //     setState({ Shopview });
  //   },
  // setRating:
  //   (Rating) =>
  //   ({ setState }) => {
  //     setState({ Rating });
  //   },
  // setFoodview:
  //   (foodview) =>
  //   ({ setState }) => {
  //     setState({ foodview });
  //   },
  setAddtocart:
    (Addtocart) =>
    ({ setState }) => {
      setState({ Addtocart });
    },
  setAddtocartreset:
    (Addtocartreset) =>
    ({ setState }) => {
      setState({ Addtocartreset });
    },

  setCartres:
    (cartres) =>
    ({ setState }) => {
      setState({ cartres });
    },
  setSearch:
    (search) =>
    ({ setState }) => {
      setState({ search });
    },
  setrendercart:
    (rendercart) =>
    ({ setState }) => {
      setState({ rendercart });
    },
  Getshopview:
    (id) =>
    async ({setState,dispatch }) => {
      dispatch(actions.setLoading(true));
      try {
        const res = await Getshopview(id);
        setState({Shopview:res.data.data})
        // dispatch(actions.setShopview(res.data.data));
      } catch (error) {
      } finally {
      }
    },
  GetFoodview:
    (id) =>
    async ({ setState,dispatch }) => {
      dispatch(actions.setLoading(true));
      try {
        const res = await GetFoodview(id);

        // dispatch(actions.setFoodview(res.data.data));
        setState({foodview:res.data.data})
        // dispatch(actions.setRating(res.data.data));
        setState({Rating:res.data.data})
      } catch (err) {
        dispatch(actions.setLoading(true));
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
  AddYouritem:
    (setIsModalVisible) =>
    async ({ dispatch, getState }) => {
      const { Addtocart } = getState();
      dispatch(actions.setLoading(true));
      try {
        const res = await AddToCart(Addtocart);
        if (res.status === 200) {
          message.success("Item added Succesfully");
        } else if (res.response.status === 599) {
       
          setIsModalVisible(true);
        }
        dispatch(actions.setLoading(false));
      } catch (err) {
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
};
export default actions;
