import styled from "styled-components";
import background from "../../Images/FOOTERBW.png";
import Colors from "../common/Colors/Colors"
export const Main = styled.div`
  padding-top: 30px;

  width: 100%;
  // position:fixed;
  bottom: 0px;

  ${
    "" /* background-repeat:no-repeat;
  background-size:cover;
  background:url(${background});
  background-blend-mode: multiply, multiply, normal; */
  }
  background: ${Colors.primary};
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "dm_sansregular";
  .footer-body {
    width: 80%;
    height: 250px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @media (max-width: 1124px) {
      height: unset;
    }
    @media (max-width: 980px) {
      width: 75%;
      height: 360px;
      gap: 20px;
    }
    @media (max-width: 742px) {
      width: 85%;
      height: 360px;
      gap: 20px;
    }
    @media (max-width: 648px) {
      width: 95%;
      height: 360px;
      gap: 10px;
    }
    @media (max-width: 572px) {
      width: 100%;
      height: 360px;
      gap: 10px;
    }
    @media (max-width: 568px) {
      margin-right: 10px;
    }
    @media (max-width: 528px) {
      width: 78%;
      height: 697px;
      align-items: center;
      justify-content: center;
      gap: unset;
    }
  }
  .items {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 250px;
    height: 200px;
    ul {
      list-style: none;
      gap: 15px;
      align-items: center;
      text-align: center;
      .default {
        font-family: "montserratregular";
        color: rgb(33, 73, 115);
        margin-bottom: 30px;
        font-size: 18px;
        font-weight: 600;
        :hover {
          color:rgb(150,172,195);
        }
        @media (max-width: 528px) {
          font-size: 17px;
          margin-bottom: 10px;
        }
        @media (max-width: 394px) {
          font-size: 15px;
        }
      }
      .main {
        margin-top: 10px;
        cursor: pointer;
        font-size: 14px;
        color: rgb(67,101,137);
        :hover {
          color:rgb(150,172,195);
        }
        @media (max-width: 528px) {
          font-size: 15px;
        }
        @media (max-width: 394px) {
          font-size: 14px;
        }
      }
    }
    @media (max-width: 528px) {
      height: unset;
    }
  }
  .app-link {
    display: flex;
    flex-direction: column;
    gap: 22px;
    align-items: center;
    img {
      width: 200px;
      height: 65px;
      @media (max-width: 1500px) {
        width: 160px;
        height: 50px;
      }
      @media (max-width: 1184px) {
        width: 150px;
        height: 60px;
      }

      @media (max-width: 1124px) {
        width: 150px;
        height: 44px;
      }
      @media (max-width: 980px) {
        width: 160px;
        height: 50px;
      }
      @media (max-width: 660px) {
        width: 150px;
        height: 50px;
      }
      @media (max-width: 648px) {
        width: 150px;
        height: 50px;
      }
    }

    .pic {
      transition: transform 0.1s;
      :hover {
        transform: scale(1.1);
        // width: 200px;
        // height: 70px;
        // @media (max-width: 1500px) {
        //   // height: 50px;
        //   // width: 160px;
        // }
        // @media (max-width: 1184px) {
        //   width: 150px;
        //   height: 60px;
        // }
        // @media (max-width: 1124px) {
        //   width: 150px;
        //   height: 50px;
        // }
        // @media (max-width: 980px) {
        //   width: 160px;
        //   height: 50px;
        // }
        // @media (max-width: 660px) {
        //   width: 150px;
        //   height: 50px;
        // }
        // @media (max-width: 648px) {
        //   width: 150px;
        //   height: 50px;
        // }
      }
    }
    .pic1 {
      transition: transform 0.1s;
      :hover {
        transform: scale(1.1);
        // width: 200px;
        // height: 70px;
        // @media (max-width: 1500px) {
        //   // height: 50px;
        //   // width: 160px;
        // }
        // @media (max-width: 1184px) {
        //   width: 150px;
        //   height: 60px;
        // }
        // @media (max-width: 1124px) {
        //   width: 150px;
        //   height: 44px;
        // }
        // @media (max-width: 980px) {
        //   width: 160px;
        //   height: 50px;
        // }
        // @media (max-width: 660px) {
        //   width: 150px;
        //   height: 50px;
        // }
        // @media (max-width: 648px) {
        //   width: 150px;
        //   height: 50px;
        // }
      }
    }
    @media (max-width: 1124px) {
      flex-direction: row;
      justify-content: center;
      width: 800px;
    }
    @media (max-width: 980px) {
      width: unset;
      flex-direction: column;
    }
    @media (max-width: 648px) {
      margin-right: 20px;
    }
    @media (max-width: 528px) {
      gap: 20px;
      margin-bottom: 30px;
      margin-left: 15px;
    }
    @media (max-width: 522px) {
      margin-right: unset;
    }
  }
  @media (max-width: 980px) {
    height: 500px;
  }
  @media (max-width: 528px) {
    height: 820px;
    padding-top: unset;
  }
  .up{
    img{
     max-width:100%;
      max-height:100%;
      opacity:0.8;
      // object-fit:fill;
    }
  }
`;
