import { MyfcmNotification } from "../../../infrastructure/fcm";

const actions = {
setPhone_Number:
    (phonenumber) =>
    ({ setState }) => {
      setState({ phonenumber });
    },
  setfcmtoken:
    (fcmtoken) =>
    ({ setState }) => {
      setState({ fcmtoken });
    },
    setLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  posttokens:
    () =>
    async ({ dispatch, getState }) => {
      dispatch(actions.setLoading(true));
      const { fcmtoken, phonenumber } = getState();

      var formData = new FormData();
      formData.append("fcm_token", fcmtoken);
      formData.append("phone_number", phonenumber);
      try {
        let res = await MyfcmNotification(formData);
        dispatch(actions.setitems(res.data.data));
      } catch (err) {
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
};
export default actions;
