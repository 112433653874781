import styled from "styled-components";
import Colors from "../common/Colors/Colors"
export const Navbardiv = styled.div`
  height: 100px;
  background-color:${Colors.primary};

  box-shadow: 0px 15px 10px -15px rgb(40 44 63 / 15%);
  display: flex;
  justify-content: center;

  width: 100%;
  @media (max-width: 1500px) {
    height:80px;

  }
  .wrapper {
    display: flex;
    justify-content: space-between;
    width: 95%;
    align-items: center;
  }
  .logo {
    img {
      cursor: pointer;
      width: 270px;
      border-radius: 0px;
      @media (max-width:640px){
        width:250px;
      }
      @media (max-width:440px){
        width:200px;
      }
    }
  }
  .link {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    .icon {
      display: none;
      @media (max-width: 980px) {
        display: block;
      }
    }
  }
  ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
    display: flex;

    gap: 40px;

    align-items: center;
    cursor: pointer;
    font-family: "Quicksand", sans-serif;

    font-size: 22px;
    letter-spacing: 1.5px;
    li {
    }

    .hoover {
      line-height: 35px;

        /* identical to box height */

        color: #ffffff;
        opacity:0.5;
        &:hover {
          line-height: 24px;
         opacity:unset;
        }
      .pic {
        img {
          width: 100px;
        }
      }
    }
    @media (max-width: 980px) {
      display: none;
    }
  }
  .search {
    display: flex;
    align-items: center;
  }
`;

export const Notify = styled.div`
  .main {
    display: flex !important;
    flex-direction: column;
    width:200px;
    small {
      width: 100%;
      margin-top: ;
      margin-bottom: 10px;
        padding:5px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      display-flex: flex
      align-items: center;
      align-items: center;
    }
  }
  .second{
    display:flex;
    justify-content:center;
    font-size:16px;
    cursor: pointer;
    h1{
      margin:0px;
    }
  }
`;
