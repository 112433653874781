import React, { useEffect, useState } from "react";
import { Popups } from "./style";
import { useHistory } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import { Modal, Button } from "antd";
import { Getgroceries } from "./store";
import Slider from "react-slick";
import soldout from "../../../Images/soldout.png";
// import Loader from "../../common/widgets/Loader";
function Popup() {
  const [{ isLoading, groceries }, { setisLoading, getgroceries }] =
    Getgroceries();
  useEffect(() => {
    getgroceries();
  }, []);
  console.log(groceries, "............grocerydata");
  const [showResults, setShowResults] = React.useState(false);
  console.log(showResults, "...................showresult");
  const onClick = () => setShowResults(true);

  const buttonClick = () => {
    setShowResults(false);
     setTimeout(function open(event) {
      document.querySelector(".popup").style.display = "block";
    }, 500);
  };
  // useEffect(() => {
  //   window.addEventListener("load", function () {
  //     setTimeout(function open(event) {
  //       document.querySelector(".popup").style.display = "block";
  //     }, 500);
  //   });
  // }, []);

  useEffect(() => {
    setTimeout(function open(event) {
      document.querySelector(".popup").style.display = "block";
      document.querySelector(".deals").style.cursor = "pointer";
    }, 500);
  }, []);

  let history = useHistory();
  const dealClick = () => {
    history.push(`/deals`);
  };

  return (
    <Popups>
      {showResults ? (
        <div className="popups">
          <button className="docker" onClick={buttonClick}>
            Today's deal
          </button>
        </div>
      ) : (
        <div>
          <div className="popup">
            <div>
              <CloseOutlined
                style={{
                  color: "#f5f5f5",
                  opacity: 0.6,
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "16px",
                  fontSize: "22px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                onClick={onClick}
              />
            </div>

            <div className="popupheader" onClick={dealClick}>
              <h1>Today's Deal</h1>
            </div>

            <div className="popupdeals">
              {groceries?.map((res, index) =>
                res.is_active === true ? (
                  <div className="dealscomplete">
                    <div className="dealname">
                      <h1>{res.item_name}</h1>
                    </div>
                    {res.is_active === true ? (
                      <div className="deals">
                        <img src={res.image} onClick={dealClick} />
                        {/* {res.is_active===true?(""):(
                
              <img src={soldout} onClick={dealClick} className="soldout" />
              )} */}
                      </div>
                    ) : (
                      <div className="dealss">
                        <img src={res.image} onClick={dealClick} />

                        <img
                          src={soldout}
                          onClick={dealClick}
                          className="soldout"
                        />
                      </div>
                    )}
                    <div className="price">
                      <h1>
                        FBP : ₹ {res.our_price}/ {res.quantity} {res.item_unit}
                      </h1>
                      {res.online_price === null ? (
                        <div>""</div>
                      ) : (
                        <h1>
                          AOP : ₹ {res.online_price}/ {res.quantity}{" "}
                          {res.item_unit}
                        </h1>
                      )}
                      {res.local_price === null ? (
                        ""
                      ) : (
                        <h1>
                          LMP :₹ {res.local_price}/ {res.quantity}{" "}
                          {res.item_unit}
                        </h1>
                      )}
                    </div>
                  </div>
                ) : null
              )}
            </div>
          </div>
        </div>
      )}
    </Popups>
  );
}

export default Popup;
