import React, { useState, useEffect } from "react";
import { User, Divmodal, Userprofile } from "./style";
import avatar from "../../Images/avatar.png";
import { Modal, notification } from "antd";
import BackArrow from "../BackArrow";
import { OurProfile } from "./store/index";
import { Skeleton } from "antd";
import { firebase } from "../../common/Firebase/index";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
function Profile() {
  const [
    {
      user,
      name,
      email,
      phone_number,
      address,
      pincode,
      gender,
      profile_photo,
      namee,
      emaile,
      phone_numbere,
      addresse,
      pincodee,
      gendere,
      gender_option,
      profile_photoe,
      isLoading,
    },
    {
      getUser,
      UpdateUser,
      setaddress,
      setemail,
      setphone_number,
      setprofile_photo,
      setgender,
      setname,
      setpincode,
      setaddressE,
      setemailE,
      setphone_numberE,
      setprofile_photoE,
      setgenderE,
      setnameE,
      setpincodeE,
      setgenderOPtion,
    },
  ] = OurProfile();

  const [preview, setpreview] = useState(null);
  const [token, setToken] = useState("");
  useEffect(() => {
    // import { firebase } from "../../common/Firebase/index";
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
      if (token === "") {
      } else {
        getUser();
      }
    });
  }, [token]);

  ///image preview
  const uploadSingleFile = (e) => {
    setprofile_photoE(e.target.files[0]);
    setpreview(URL.createObjectURL(e.target.files[0]));
  };
  let imgPreview;
  if (preview) {
    imgPreview = <img src={preview} alt="" />;
  }

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    UpdateUser(setIsModalVisible);
    setpreview(null);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [fileList, setFileList] = useState();
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  // if(isLoading){
  //   return <p>Loading......</p>
  // }

  return (
    <Userprofile>
      <div className="mybuttonback">
        <BackArrow />
      </div>

      {/* <div className="main">
        <div className="wrapper">
        {isLoading === true ? (
              <Skeleton active={isLoading} row={10} />
            ) : (
          <div className="section">
            <div className="left">
              <img src={profile_photoe} alt="avatar" />
            </div>
          
              <div className="right">
                <div className="heading">
                  <h1>Profile</h1>
                  <button onClick={showModal}>Edit Profile</button>
                </div>
                <input type="text" placeholder="Name" value={namee} />
                <input type="email" placeholder="Email" value={emaile} />
                <input type="text" placeholder="Address" value={addresse} />
                <input
                  type="phone"
                  placeholder="mobile_Number"
                  value={phone_numbere}
                />
                <input type="text" placeholder="Pincode" value={pincodee} />
                 <input type="text" placeholder="gender" value={gendere} /> 
               
              </div>
        
          </div>    )}
        </div>
        <Modal
          title="Update Profile"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Divmodal>
            <div className="modalprofile">
              <input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setname(e.target.value)}
              />
              <input
                type="email"
                placeholder="email"
                value={email}
                onChange={(e) => setemail(e.target.value)}
              />
              <input
                type="text"
                placeholder="address"
                value={address}
                onChange={(e) => setaddress(e.target.value)}
              />
              <input
                type="text"
                placeholder="phone_number"
                value={phone_number}
                onChange={(e) => setphone_number(e.target.value)}
              />
              <input
                type="text"
                placeholder="picode"
                value={pincode}
                onChange={(e) => setpincode(e.target.value)}
              />
         
               <select
                  value={gender_option}
                  onChange={(e) => setgenderOPtion (e.target.value)}
                >
                  <option value='male'>Male</option>
                  <option value='female'>female</option>
                  <option vlaue='other'>Other</option>
                  
                </select>
              <img src={profile_photo} alt="avatar" />
              <input
              className="pic"
                type="file"
                id="myFile"
                name="filename"
                onChange={(event) => {
                  console.log(event.target.files);
                  setprofile_photo(event.target.files[0]);
                }}
              />
            </div>
          </Divmodal>
        </Modal>
      </div> */}
      <div className="usermain">
        <div className="wrapper">
          <div className="usersection">
            <div className="img">
              <img src={profile_photo} alt="avatar" />
            </div>

            <div className="details">
              <div className="heading">
                <h1>Profile</h1>
              </div>
              <div className="label">
                <label> Name : {namee}</label>
                <label> Email : {emaile}</label>
                <label> Address: {addresse}</label>
                <label> Phone : {phone_numbere}</label>
                <label> Pincode : {pincodee}</label>
                <label> Gender : {gendere}</label>
              </div>
              <div className="updatebtn">
                <button onClick={showModal}>Update Profile</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Update Profile"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Divmodal>
          <div className="modalprofile">
            <input
              autocomplete="off"
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setname(e.target.value)}
            />

            <input
              autocomplete="off"
              type="email"
              placeholder="email"
              value={email}
              onChange={(e) => setemail(e.target.value)}
            />
            <input
              autocomplete="off"
              type="text"
              placeholder="address"
              value={address}
              onChange={(e) => setaddress(e.target.value)}
            />
            <input
              autocomplete="off"
              type="text"
              placeholder="phone_number"
              value={phone_number}
              onChange={(e) => setphone_number(e.target.value)}
            />
            <input
              autocomplete="off"
              type="text"
              placeholder="picode"
              maxlength="6"
              value={pincode}
              onChange={(e) => setpincode(e.target.value)}
            />

            <select
              value={gender_option}
              onChange={(e) => setgenderOPtion(e.target.value)}
            >
              <option value="" disabled selected>
                Please Select Your Gender
              </option>
              <option value="male">Male</option>
              <option value="female">female</option>
              <option vlaue="other">Other</option>
            </select>
            <img src={profile_photo} alt="avatar" />
            {/* <input

              className="pic"
                type="file"
                id="myFile"
                name="filename"
                onChange={(event) => {
                  console.log(event.target.files);
                  setprofile_photo(event.target.files[0]);
                }}
              /> */}

            <div
              class="upload-btn-wrapper"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <button class="btn">Upload profile</button>
              <input
                type="file"
                id="myFile"
                name="filename"
                onChange={uploadSingleFile}
              />
              <div>{imgPreview}</div>
             
            </div>
          </div>
        </Divmodal>
      </Modal>
    </Userprofile>
  );
}

export default Profile;
