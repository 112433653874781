import styled from "styled-components";
export const User = styled.div`
.mybuttonback{
  padding-top: 30px;
  padding-left: 50px;
  @media(max-width:540px){
    padding-left:unset;
  }
}
  .main{
      display: flex;
      justify-content: center;
      height: 100vh;
      align-items: center;
    .wrapper {
         width: 70%;
      .section {
          margin-bottom:40px;
          display: flex;
          justify-content: space-between;
    
        .left {
           width: 500px;
           display: flex;
           align-items: center;
           justify-content: center;
            img {
             width: 300px;
             height: 300px;
             border-radius: 12px;
             @media(max-width:1290px){
               width:283px;
               height:283px;
             }
             @media(max-width:1200px){
              width: 260px;
              height: 260px;
             }
             @media(max-width:870px){
               width:230px;
               height:230px;
             }
             @media(max-width:640px){
               width:210px;
               height:210px;
             }
             @media(max-width:490px){
              width:180px;
              height:180px;
            }
            @media(max-width:386px){
              width:170px;
              height:170px;
            }
            }
            @media(max-width:980px){
              width:420px;
            }
            @media (max-width: 498px) {
                width: unset;
            }
        }
        .right {
              box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
               display: flex;
               flex-direction: column;
               width: 500px;
               padding: 20px;
          .heading {
               display: flex;
               justify-content: space-between;
                h1 {
                 font-size: 25px;
                 color: gray;
                 @media(max-width:780px){
                   font-size:18px;
                 }
                }
                button {
                  background-color: #006fb1;
                  width: 100px;
                  height: 30px;
                  border: none;
                  border-radius: 4px;
                   cursor: pointer;
                   color:white;
                 
                     &:active {
                         color: black;
                     }
                  
                }
                 @media (max-width: 320px) {
                      flex-direction: column;
                }
              
           }
        input {
          margin-top: 15px;
          border-radius: 4px;
          height: 40px;
          margin-bottom: 20px;
          border-radius: 3.5px;
          padding: 1vh;
          font-size: 1.7vh;
          border: 1px solid #e0e0e0;
          height: 6vh;
          background: white;
          box-shadow: inset 0 -1px 0 #006fb1;
          font-family: "montserratregular";
          font-size: 14px;
         @media(max-width:980px){
           font-size:12px;
         }
        }
       
       @media(max-width:1290px){
         width:490px;
       }
       @media(max-width:1200px){
         width:480px;
       }
       @media(max-width:1080px){
         width:450px;
       }
       @media(max-width:980px){
         width:420px;
       }
       @media(max-width:796px){
         width:100%;
       }
        @media (max-width: 590px) {
          width: 400px;
        }
        @media (max-width: 470px) {
          width: 350px;
        }
        @media (max-width: 400px) {
          width: 320px;
        }
        @media (max-width: 350px) {
          width: 300px;
        }
        @media (max-width: 320px) {
          width: 200px;
        }
      }
      @media (max-width: 796px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 50px;
        margin-bottom: 25px;
        margin-top:20px;
      }
    
    }
    @media(max-width:1500px){
      width:80%;
    }
    @media(max-width:1290px){
      width:83%;
    }
    @media(max-width:1200px){
      width:86%;
    }
    @media(max-width:1080px){
      width:90%;
    }
   @media(max-width:780px){
     width:unset;
   }
  }
  @media (max-width: 796px) {
    height: unset;
  }
`;
export const Divmodal = styled.div`
  .modalprofile {
    display: flex;
    flex-direction: column;
  
    input {
      margin-top: 10px;
      border-radius: 4px;
      margin-bottom: 10px;
      border-radius: 3.5px;
      padding: 1vh;
      font-size: 1.7vh;
      border: 1px solid #e0e0e0;
      height: 5vh;
      background: white;
      box-shadow: inset 0 -1px 0 #006fb1;
      font-family: "montserratregular";
      font-size: 14px;
    }
    select{
      margin-top: 15px;
      border-radius: 4px;
      height: 40px;
      margin-bottom: 20px;
      border-radius: 3.5px;
      padding: 1vh;
      font-size: 1.7vh;
      border: 1px solid #e0e0e0;
      height: 6vh;
      background: white;
      box-shadow: inset 0 -1px 0 #006fb1;
      font-family: "montserratregular";
      font-size: 14px;
    }
    .pic{
      border:unset;
      box-shadow: unset;
    }
    img {
      width: 50px;
      height: 40px;
    }
    .upload-btn-wrapper {
      position: relative;
      overflow: hidden;
      display: inline-block;
      width:90%;
      margin-top:15px;
     
    }
    
    .btn {
      border: 2px solid gray;
      color: gray;
      background-color: white;
      width:130px;
      height:30px;
      margin-left:13px;
      border-radius: 8px;
      font-size: 10px;
      font-weight: bold;
      justify-content:start;

    }
    
    .upload-btn-wrapper input[type=file] {
      font-size: 100px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
  }
}
  @media (max-width: 980px) {
    height: 380px;
  }

  }
 
`;

export const Userprofile = styled.div`

.mybuttonback{
  padding-top: 30px;
  padding-left: 50px;
  @media(max-width:540px){
    padding-left:unset;
  }
}
  .usermain{
      display: flex;
      flex-direction:column;
      justify-content: center;
  
      align-items: center;
      .wrapper{
        width:70%;
        .usersection{
          display:flex;
          flex-direction:column;
          justify-content:center;
          align-items:center;
          .img{
            width: 250px;
            display: flex;
            align-items: center;
            justify-content: center;
             img {
              width: 100%;
              height: 100%;
              display:block;
              object-fit: cover;
              border-radius: 12px;
             }
             @media(max-width:1180px){
               width:230px;
             }
             @media(max-width:980px){
              width:200px;
            }
            @media(max-width:740px){
              width:170px;
            }
            @media(max-width:540px){
              width:155px;
            }
            @media(max-width:400px){
              width:130px;
            }
          }
          .details{
            display:flex;
            flex-direction:column;
         
            margin-top:30px;
            // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
         
          
            padding: 20px;
            margin-bottom:6%;
            .heading{
              h1{
                font-size:18px;
                font-weight:bold;
              }

            }
            .label{
              display:flex;
              flex-direction:column;
              justify-content:center;
              gap:10px;
              label{
                font-size:16px;
                @media(max-width:433px){
                  font-size:14px;
                }
              }
              @media(max-width:433px){
                width:200px;
              }
            }
            .updatebtn{
              display:flex;
           
              margin-top:25px;
              button{
                background-color:#006fb1;
                border-radius:4px;
                border:none;
                font-size:14px;
                height:30px;
                width:130px;
                font-weight:bold;
               color:white;
               @media (max-width:400px){
                 font-size:12px;
               }
              }
              @media(max-width:433px){
                font-size: 12px;
                height: 34px;
                width: 92px;
              }
            }
          }
        }
      }
  }
`