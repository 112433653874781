import React, { useEffect, useState } from "react";
import { Star } from "./style";
import { Rate } from "antd";
import { Ratingsresponse } from "./store";
import { useHistory } from "react-router-dom";
import Loader from "../common/widgets/Loader";
import BackArrow from "../BackArrow";
function Rating({ match }) {
  let history = useHistory();
  const [
    { isLoading, rating, hospitality, comment, iddd },
    {
      Getmyratingdata,
      setHospitality,
      setPrice,
      setTaste,
      setQuality,
      setQuantity,
      setPacking,
      setTiming,
      setWastemanagement,
      setComment,
      setiddd,
    },
  ] = Ratingsresponse();
  const [idd, setidd] = useState(match.params.id);

  const handleChangeH = (value) => {
    setHospitality(value);
  };
  const handleChangeT = (value) => {
    setTaste(value);
  };
  const handleChangeP = (value) => {
    setPrice(value);
  };
  const handleChangeQ = (value) => {
    setQuality(value);
  };
  const handleChangeq = (value) => {
    setQuantity(value);
  };
  const handleChangepc = (value) => {
    setPacking(value);
  };
  const handleChangeTi = (value) => {
    setTiming(value);
  };
  const handleChangeW = (value) => {
    setWastemanagement(value);
  };

  const Submitrating = () => {
    setiddd(idd);
    Getmyratingdata(history);
  };

  return (
    <Star>
      <div className="page">
      <div className="arrow" style={{width:'90%'}}>
        <BackArrow />
      </div>
      <div className="main">
        <div className="heading">
          <h1>Ratings</h1>
        </div>
        <div className="box1">
          <h1>Hospitality &#128519;</h1>
          <Rate count="10" onChange={handleChangeH} />
        </div>
        <div className="box1">
          <h1>Taste &#128523;</h1>
          <Rate count="10" onChange={handleChangeT} />
        </div>
        <div className="box1">
          <h1>Price &#129297;</h1>
          <Rate count="10" onChange={handleChangeP} />
        </div>
        <div className="box1">
          <h1>Quality &#129321;</h1>
          <Rate count="10" onChange={handleChangeQ} />
        </div>
        <div className="box1">
          <h1>Quantity &#128522;</h1>
          <Rate count="10" onChange={handleChangeq} />
        </div>
        <div className="box1">
          <h1>Packing &#128526;</h1>
          <Rate count="10" onChange={handleChangepc} />
        </div>
        <div className="box1">
          <h1>Timing &#128556;</h1>
          <Rate count="10" onChange={handleChangeTi} />
        </div>
        <div className="box1">
          <h1>Waste Management &#128527;</h1>
          <Rate count="10" onChange={handleChangeW} />
        </div>
        <div className="comment">
          <h1>Comment</h1>
          <textarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          ></textarea>
        </div>
        <button
          onClick={() => Submitrating()}
          className="buttons"
          style={{
            width: 150,
            height: 40,
            padding: "5px",
            borderRadius: 8,
            backgroundColor: "#006fb1",
            border: "none",
            color: "white",
            letterSpacing: 1,
          }}
        >
          Submit
        </button>
      </div>
   </div>
    </Star>
  );
}

export default Rating;
