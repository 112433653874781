import {
  Getcartdetails,
  RemoveCart,
  CheckoutCart,
  AddquantityCart,
} from "../../../infrastructure/Cartpage";
import { notification } from "antd";
import { message, Button } from "antd";
const actions = {
  setLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setcartitems:
    (mycart) =>
    ({ setState }) => {
      setState({ mycart });
    },
  setCarttotal:
    (Cartt) =>
    ({ setState }) => {
      setState({ Cartt });
    },
  setCartRemovedata:
    (Removedata) =>
    ({ setState }) => {
      setState({ Removedata });
    },
  setrerndercart:
    (rerendercart) =>
    ({ setState }) => {
      setState({ rerendercart });
    },
  setQuantity:
    (quantity) =>
    ({ setState }) => {
      setState({ quantity });
    },

  Getcartitems:
    () =>
    async ({ dispatch,setState }) => {
      try {
        dispatch(actions.setLoading(true));
        const res = await Getcartdetails();

        if (res.status === 200) {
          // setState({ mycart: res.data.data});
          // setState({ cartt: res.data.data});
          dispatch(actions.setcartitems(res.data.data));
          dispatch(actions.setCarttotal(res.data.data));
        } else if (res.response.status === 599) {
          window.location.replace(`/eventcreate`);
          notification["success"]({
            message: "success",
            description: "Please Create Event First and Make Default!....",
          });
        }
      } catch (err) {
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
  RemoveCartitem:
    () =>
    async ({ dispatch, getState }) => {
      const { Removedata } = getState();
      dispatch(actions.setLoading(true));
      try {
        await RemoveCart(Removedata);
        // notification["success"]({
        //   message: "success",
        //   description: "Item Removed Succesfully",
        // });
        message.success(" Item Removed Succesfully ");
        dispatch(actions.Getcartitems());
        dispatch(actions.setLoading(true));
      } catch (err) {
      } finally {
      }
    },
  Addquantity:
    () =>
    async ({ dispatch, getState }) => {
      const { quantity } = getState();
      dispatch(actions.setLoading(true));
      try {
        await AddquantityCart(quantity);
        dispatch(actions.Getcartitems());
      } catch (err) {
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
  Checkoutcartitem:
    (id, history) =>
    async ({ dispatch, getState }) => {
      dispatch(actions.setLoading(true));
      try {
        await CheckoutCart(id);
        history.push("/order");
        localStorage.setItem("cart", "");
        notification["success"]({
          message: "success",
          description: "Item Placed Succesfully",
        });
        dispatch(actions.Getcartitems());
      } catch (err) {
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
};
export default actions;
