import React from "react";
import { Middle } from "./style";
import { RightOutlined } from "@ant-design/icons";
import BackArrow from "../BackArrow";

function Aboutus() {
  return (
    <Middle>
      <div className="page" id="about">
        <div className="page-content">
          <h1>About Us</h1>
          <div className="arrow">
            <BackArrow />
          </div>
        </div>
      </div>
      <div className="page-section">
        <div className="pagewidth">
          <p>
            2020 has become defining year of the 2010- 20 decade with the
            outbreak of COVID 19. With lockdowns and social distancing to keep
            everyone safe, the number of individuals deemed safe to assemble in
            a single spot has reduced from thousands to hundreds, to ten and
            restricted the movement of people across the places. This brought
            huge changes in every aspect of the life of individuals, especially
            in shopping practices. From kids to elders are choosing online
            platforms to buy essential things. Online shopping is not a novelty
            anymore. Quadauq Technosys Pvt Ltd is proudly launching a Food
            Coordinating mobile app named - FOODBUOY with the vision of making
            food ordering for small functions to razmataz events easier during
            these pandemic times as well as helping small food makers to sell
            their items adapting to the new changes in the behaviour of
            customers. Quadauq Technosys is a rising Company with a developing
            brand in the area of Food and beverage items to online delivery that
            helps you to simplify your needs. We would like to serve our client
          </p>
          <h1>Our Vision</h1>
          <p>
            Our vision is to being the backbone of local hotels and restaurants
            and ensuring the safety and convenience of the customers. FOODBUOY
            can simply be a perfect partner and act as an online platform for
            food delivery. The vision is to convert all local vendors in India
            to a digital platform.
          </p>
          <h1>Why Choose Us</h1>
          <div style={{ display: "flex" }}>
            <small>
              <p>
                {/* <RightOutlined style={{ marginRight: 10 }} />
                We are the dedicated online food partner who operates in entire
                South India which helps you in making orders of a wide variety
                of dishes from different cuisines */}
                <strong> {'>'} </strong>
                We are the dedicated online food partner who operates in entire
                South India which helps you in making orders of a wide variety
                of dishes from different cuisines.
              </p>
              <p>
                <strong> {'>'} </strong>
                FOODBUOY is a brand new user-friendly mobile application for
                online delivery that can be operated on both Android and IOS
                Platforms.
              </p>
              <p>
                <strong> {'>'} </strong>
                With FOODBUOY you can check out the wide variety of items,
                compare their prices and make orders and it will be delivered to
                your premises in a hassle-free manner.
              </p>
              <p>
                <strong> {'>'} </strong>
                Anybody can download the app, free register with us and start
                buying with it and also one can track the whereabouts of their
                orders easily with FOODBUOY.
              </p>
              <p>
                <strong> {'>'} </strong>
                We support all food category and its business by making them
                joining as our vendors and providing an online platform to sell
                their products.
              </p>
            </small>{" "}
          </div>
        </div>
      </div>
    </Middle>
  );
}

export default Aboutus;
