import {
  Eventdel,
  Eventlist,
  Eventlistbyid,
  Eventmakedefault,
  Eventupdate,
} from "../../../infrastructure/Eventpage";
import { notification } from "antd";
const actions = {
    setLoading:
    (isloading) =>
    ({ setState }) => {
      setState({ isloading });
    },
  // setCustomer:
  //   (customer) =>
  //   ({ setState }) => {
  //     setState({ customer });
  //   },
  // setevent_name:
  //   (event_name) =>
  //   ({ setState }) => {
  //     setState({ event_name });
  //   },
  // setname:
  //   (name) =>
  //   ({ setState }) => {
  //     setState({ name });
  //   },
  // setdelivery_address:
  //   (delivery_address) =>
  //   ({ setState }) => {
  //     setState({ delivery_address });
  //   },
  // setpincode:
  //   (pincode) =>
  //   ({ setState }) => {
  //     setState({ pincode });
  //   },
  // setlandmark:
  //   (landmark) =>
  //   ({ setState }) => {
  //     setState({ landmark });
  //   },
  // setlatitude:
  //   (latitude) =>
  //   ({ setState }) => {
  //     setState({ latitude });
  //   },
  // setlongitude:
  //   (longitude) =>
  //   ({ setState }) => {
  //     setState({ longitude });
  //   },
  // setdelivery_date:
  //   (delivery_date) =>
  //   ({ setState }) => {
  //     setState({ delivery_date });
  //   },
  // setdelivery_time:
  //   (delivery_time) =>
  //   ({ setState }) => {
  //     setState({ delivery_time });
  //   },
  // setcaretaker_name:
  //   (caretaker_name) =>
  //   ({ setState }) => {
  //     setState({ caretaker_name });
  //   },
  // setcaretaker_contact:
  //   (caretaker_contact) =>
  //   ({ setState }) => {
  //     setState({ caretaker_contact });
  //   },
  // setalternate_contact:
  //   (alternate_contact) =>
  //   ({ setState }) => {
  //     setState({ alternate_contact });
  //   },
  // setgeo_location:
  //   (geo_location) =>
  //   ({ setState }) => {
  //     setState({ geo_location });
  //   },
  // setevent_nameU:
  //   (event_nameU) =>
  //   ({ setState }) => {
  //     setState({ event_nameU });
  //   },
  // setLoading:
  //   (isloading) =>
  //   ({ setState }) => {
  //     setState({ isloading });
  //   },
  // setupadteuser:
  //   (updteuser) =>
  //   ({ setState }) => {
  //     setState({ updteuser });
  //   },
  getcustomer:
    () =>
    async ({setState,dispatch }) => {
      dispatch(actions.setLoading(true));
      try {
        const res = await Eventlist();
        setState({ customer: res.data.data });
        // dispatch(actions.setCustomer(res.data.data));
      } catch (err) {
        dispatch(actions.setLoading(true));
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
  deleteCustomer:
    (id) =>
    async ({ dispatch, getState }) => {
      try {
        dispatch(actions.setLoading(true));
        await Eventdel(id);
        notification["success"]({
          message: "successs",
          description: "Event deleted Succesfully",
        });
        dispatch(actions.getcustomer());
      } catch (err) {
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
  UpdateCustomer:
    (id) =>
    async ({ setState,dispatch, getState }) => {
      try {
        dispatch(actions.setLoading(true));
        const res = await Eventlistbyid(id);
        dispatch(actions.setupadteuser(res.data.data));
        // dispatch(actions.setname(res.data.data.name));
        setState({ name: res.data.data });
        // dispatch(actions.setevent_name(res.data.data.event_name));
        setState({event_name: res.data.data });
        // dispatch(actions.setdelivery_address(res.data.data.delivery_address));
        setState({delivery_address: res.data.data });
        // dispatch(actions.setpincode(res.data.data.pincode));
        // dispatch(actions.setalternate_contact(res.data.data.alternate_contact));
        // dispatch(actions.setcaretaker_name(res.data.data.caretaker_name));
        // dispatch(actions.setcaretaker_contact(res.data.data.caretaker_contact));
        // dispatch(actions.setdelivery_date(res.data.data.delivery_date));
        // dispatch(actions.setlandmark(res.data.data.landmark));
        // dispatch(actions.setdelivery_time(res.data.data.delivery_time));
        // dispatch(actions.setpincode(res.data.data.pincode));
        // dispatch(actions.setLoading(false));
        setState({pincode: res.data.data });
        setState({alternate_contact: res.data.data });
        setState({caretaker_name: res.data.data });
        setState({caretaker_contact: res.data.data });
        setState({delivery_date: res.data.data });
        setState({landmark: res.data.data });
        setState({delivery_time: res.data.data });
      } catch (err) {
      } finally {
      }
    },
  Postupdate:
    (id,value) =>
    async ({ dispatch, getState }) => {
      try {
        dispatch(actions.setLoading(true));
        const {
          event_name,
          name,
          pincode,
          delivery_address,
          delivery_date,
          alternate_contact,
          landmark,
          delivery_time,
          caretaker_contact,
          caretaker_name,
          geo_location,
          longitude,
          latitude,
        } = value;

        const data = {
          event_name,
          name,
          delivery_address,
          delivery_date,
          alternate_contact,
          landmark,
          longitude,
          latitude,
          delivery_time,
          caretaker_contact,
          caretaker_name,
          geo_location,
          pincode,
        };
        const res = await Eventupdate(id, value);
      } catch (err) {
      } finally {
        dispatch(actions.getcustomer());
        dispatch(actions.setLoading(false));
      }
    },
  Makedefault:
    (id) =>
    async ({ dispatch, getState }) => {
      try {
        dispatch(actions.setLoading(true));
        const res = await Eventmakedefault(id);
        dispatch(actions.getcustomer());
        dispatch(actions.setLoading(false));
        console.log(res, "update user response");
      } catch (err) {
      } finally {
      }
    },
};
export default actions;
