import React from 'react'
import  foodgif from "../../../../Images/foodgif.gif"
import {GIF} from "./style"
function Foodbuoy() {
    return (
        <GIF >
            <img src={foodgif}></img>
        </GIF>
    )
}

export default Foodbuoy;

