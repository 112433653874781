import { Eventlistbyid, Eventupdate } from "../../../infrastructure/Eventpage";
import { notification } from "antd";

const actions = {
  setCustomer:
    (customer) =>
    ({ setState }) => {
      setState({ customer });
    },
  setevent_name:
    (event_name) =>
    ({ setState }) => {
      setState({ event_name });
    },
  setname:
    (name) =>
    ({ setState }) => {
      setState({ name });
    },
  setdelivery_address:
    (delivery_address) =>
    ({ setState }) => {
      setState({ delivery_address });
    },
  setpincode:
    (pincode) =>
    ({ setState }) => {
      setState({ pincode });
    },
  setlandmark:
    (landmark) =>
    ({ setState }) => {
      setState({ landmark });
    },
  setlatitude:
    (latitude) =>
    ({ setState }) => {
      setState({ latitude });
    },
  setlongitude:
    (longitude) =>
    ({ setState }) => {
      setState({ longitude });
    },
  setdelivery_date:
    (delivery_date) =>
    ({ setState }) => {
      setState({ delivery_date });
    },
  setdelivery_time:
    (delivery_time) =>
    ({ setState }) => {
      setState({ delivery_time });
    },
  setcaretaker_name:
    (caretaker_name) =>
    ({ setState }) => {
      setState({ caretaker_name });
    },
  setcaretaker_contact:
    (caretaker_contact) =>
    ({ setState }) => {
      setState({ caretaker_contact });
    },
  setalternate_contact:
    (alternate_contact) =>
    ({ setState }) => {
      setState({ alternate_contact });
    },
  setgeo_location:
    (geo_location) =>
    ({ setState }) => {
      setState({ geo_location });
    },
  setevent_nameU:
    (event_nameU) =>
    ({ setState }) => {
      setState({ event_nameU });
    },
  setLoading:
    (isloading) =>
    ({ setState }) => {
      setState({ isloading });
    },
  setupadteuser:
    (updteuser) =>
    ({ setState }) => {
      setState({ updteuser });
    },
  setAddress:
    (address) =>
    ({ setState }) => {
      setState({ address });
    },
  setcoordinates:
    (coordinates) =>
    ({ setState }) => {
      setState({ coordinates });
    },
  setlatitude:
    (latitude) =>
    ({ setState }) => {
      setState({ latitude });
    },
  setlongitude:
    (longitude) =>
    ({ setState }) => {
      setState({ longitude });
    },

  getcustomerCustomer:
    (id) =>
    async ({setState,dispatch }) => {
      try {
        dispatch(actions.setLoading(true));
        const res = await Eventlistbyid(id);
        dispatch(actions.setupadteuser(res.data.data));
        dispatch(actions.setname(res.data.data.name));
        dispatch(actions.setevent_name(res.data.data.event_name));
        dispatch(actions.setdelivery_address(res.data.data.delivery_address));
        dispatch(actions.setpincode(res.data.data.pincode));
        dispatch(actions.setalternate_contact(res.data.data.alternate_contact));
        dispatch(actions.setcaretaker_name(res.data.data.caretaker_name));
        dispatch(actions.setcaretaker_contact(res.data.data.caretaker_contact));
        dispatch(actions.setdelivery_date(res.data.data.delivery_date));
        dispatch(actions.setlandmark(res.data.data.landmark));
        dispatch(actions.setdelivery_time(res.data.data.delivery_time));
        dispatch(actions.setpincode(res.data.data.pincode));
        dispatch(actions.setlongitude(res.data.data.longitude));
        dispatch(actions.setlatitude(res.data.data.latitude));
       
        dispatch(actions.setLoading(false));
      } catch (err) {
      } finally {
      }
    },
  Postupdate:
    (id, history) =>
    async ({ dispatch, getState }) => {
      try {
        dispatch(actions.setLoading(true));
        const {
          event_name,
          name,
          pincode,
          delivery_address,
          delivery_date,
          alternate_contact,
          landmark,
          delivery_time,
          caretaker_contact,
          caretaker_name,
          geo_location,
          longitude,
          latitude,
        } = getState();

        const data = {
          event_name,
          name,
          delivery_address,
          delivery_date,
          alternate_contact,
          landmark,
          longitude,
          latitude,
          delivery_time,
          caretaker_contact,
          caretaker_name,
          geo_location,
          pincode,
        };
        await Eventupdate(id, data);
        history.push("/eventpage");
      } catch (err) {
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
};
export default actions;
