import React, { useState, useEffect } from "react";
import { Update } from "./style";
import { DatePicker, TimePicker } from "antd";
import moment from "moment";
import { Eventlistupdate } from "./store";
import { firebase } from "../../common/Firebase/index";
import Loader from "../common/widgets/Loader";
import { useHistory } from "react-router-dom";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import BackArrow from "../BackArrow";
function UpdateEvent({ match }) {
  let history = useHistory();

  const [idd, setidd] = useState(match.params.id);
  const [
    {
      isloading,
      name,
      alternate_contact,
      delivery_address,
      event_name,
      delivery_time,
      caretaker_contact,
      caretaker_name,
      landmark,
      delivery_date,
      pincode,
      address,
    },
    {
      setlatitude,
      setlongitude,
      setAddress,
      getcustomerCustomer,
      setname,
      setalternate_contact,
      setcaretaker_name,
      setdelivery_date,
      setdelivery_time,
      setdelivery_address,
      setlandmark,
      setpincode,
      setevent_name,
      setcaretaker_contact,
      setupadteuser,
      Postupdate,
    },
  ] = Eventlistupdate();
  const [token, setToken] = useState("");
  useEffect(() => {
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
      if (token === "") {
      } else {
        getcustomerCustomer(idd);
      }
    });
  }, [token]);
  //addres.............................................
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });
  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
  
    setAddress(value);
    setlatitude(latLng.lat);
    setlongitude(latLng.lng);
  };
  //google place loader validation.............................................................................
  const [gmapsLoaded, setGmapsLoaded] = useState(false);

  useEffect(() => {
    window.initMap = () => setGmapsLoaded(true);
    const gmapScriptEl = document.createElement(`script`);
    gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAlQHjy-Fl-K_IuHsajSnE8Baq9zfeFveQ&libraries=places&callback=initMap`;
    document
      .querySelector(`body`)
      .insertAdjacentElement(`beforeend`, gmapScriptEl);
  }, []);
  //.......................................................................................................
  //date format.....
  const format = "YYYY-MM-DD";
  const timeformat = "HH:mm";
  //disable date
  const today = moment();
  const disabledate = (current) => {
    return current.isBefore(today);
  };
  const handleOk = () => {
    Postupdate(idd, history);
    setAddress("");
  };

  function onChangedate(date, dateString) {
    const mydate = (date, dateString);
    setdelivery_date(mydate);
  }
  function onChangetime(time, timeString) {
    const mytime = (time, timeString);
    setdelivery_time(mytime);
  }
  if (isloading) {
    return <Loader />;
  }
  return (
    <Update>
      <div style={{ paddingTop: 50, paddingLeft: 50 }}>
        <BackArrow />
      </div>
      <div className="upperpar">
        <div className="main">
          <div className="section">
            <div className="name">
              <input
                type="text"
                placeholder=" Name "
                value={name}
                onChange={(e) => setname(e.target.value)}
              />
              <input
                type="text"
                placeholder="Event Name (eg: Marriage)"
                onChange={(e) => setevent_name(e.target.value)}
                value={event_name}
              />
            </div>
            <div className="adrs">
              <input
                type="text"
                placeholder="Alternate contact"
                value={alternate_contact}
                onChange={(e) => setalternate_contact(e.target.value)}
              />
              <input
                type="text"
                onChange={(e) => setdelivery_address(e.target.value)}
                placeholder="Delivery Address"
                value={delivery_address}
              />
              {gmapsLoaded && (
                <PlacesAutocomplete
                  value={address}
                  onChange={setAddress}
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div
                      className="adrs"
                      style={{ display: "flex", gap: "0px", width: "100%" }}
                    >
                      <input
                        {...getInputProps({
                          placeholder: "Change Location Address",
                        })}
                      />
                      <div>
                        {loading ? <div>...loading </div> : null}
                        {suggestions.map((suggestion) => {
                          const style = {
                            backgroundColor: suggestion.active
                              ? "#006fb1"
                              : "#fff",
                            color: "black",
                            cursor: "pointer",
                          };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, { style })}
                            >
                              {suggestion.description}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              )}
            </div>
            <div className="adrs">
              <input
                type="text"
                placeholder="Landmark"
                value={landmark}
                onChange={(e) => setlandmark(e.target.value)}
              />
              <input
                type="nmbr"
                placeholder="pincode"
                value={pincode}
                onChange={(e) => setpincode(e.target.value)}
              />
            </div>
            <div className="adrs1">
              <div>
                <DatePicker
                  defaultValue={moment(delivery_date, format)}
                  onChange={onChangedate}
                  disabledDate={disabledate}
                  format={format}
                  style={{
                    width: "100%",
                    borderRadius: 3.5,
                    height: "6vh",
                    backgroundColor: "white",
                    boxShadow: "inset 0 -1px 0 # #006fb1",
                    border: "1px solid #e0e0e0",
                  }}
                />
              </div>
              <div>
                <TimePicker
                  onChange={onChangetime}
                  defaultValue={moment(delivery_time, timeformat)}
                  format={timeformat}
                  style={{
                    width: "100%",
                    borderRadius: 3.5,
                    height: "6vh",
                    backgroundColor: "white",
                    boxShadow: "inset 0 -1px 0 #006fb1",
                    border: "1px solid #e0e0e0",
                  }}
                />
              </div>
            </div>
            <div className="adrs">
              <input
                type="text"
                placeholder="caretaker name (if any)"
                value={caretaker_name}
                onChange={(e) => setcaretaker_name(e.target.value)}
              />

              <input
                type="nmbr"
                placeholder="Caretaker contact (if any)"
                value={caretaker_contact}
                onChange={(e) => setcaretaker_contact(e.target.value)}
              />
            </div>
            <div className="btn">
              <button onClick={handleOk}>Update</button>
            </div>
          </div>
        </div>
      </div>
    </Update>
  );
}

export default UpdateEvent;
