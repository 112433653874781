import styled from "styled-components";
export const Back = styled.div`

 
   
width: 47px;
height: 46px;
background:white;
opacity:0.4;
border-radius: 30px;
padding: 8px 12px;
cursor: pointer;

}
    button {
      background:white;
      border-radius: 30px;
      border: none;
      // opacity: 0.4;
      color: black;
    }
  
`;
