import React, { useState, useEffect } from "react";

import { Top } from "./style";

import shadding from "../../Images/shading.png";
import { useHistory } from "react-router-dom";
import { FoodItems } from "./store";

import Loader from "../common/widgets/Loader";
import { firebase } from "../../common/Firebase/index";
import AOS from "aos";
import "aos/dist/aos.css";
// import { Image, Shimmer } from "react-shimmer";
function Fooditems() {
  const [{ items, isLoading }, { Getitems }] = FoodItems();
  let History = useHistory();

  const ImgLink = (id) => {
    History.push(`/foodtypes/${id}`);
  };

  const [token, setToken] = useState("");
  localStorage.setItem("token", token);

  useEffect(() => {
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          //  setphoneNumber(user.phoneNumber)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
      if(token === ""){

      }else{
        Getitems();
      }
      AOS.init({
        duration : 2000
      });
    });
   
  }, [token]);

 


  return (
    <Top>
      <>
        <div className="main">
          <div className="top">
            {/* <img src={arrows} alt="arrow"></img> */}
          </div>
          <div>
            <h1> Search for your food starts and ends here....</h1>
          </div>
          {/* <div>
            <h3>The Process of our service</h3>
          </div> */}
        </div>

        <div className="array">
          <div className="array2">
            {items.map((res) => (
              <div className="topimg" onClick={() => ImgLink(res.id)}>
                <img className="one" src={res?.web_image} alt="images"></img>
                {/* <Image
                  src={res?.web_image}
                  fallback={<Shimmer width={500} height={500} />}
                /> */}
                {/* <img className="two" src={shadding} alt="shadding"></img> */}
                <span></span>
                <h1  data-aos="flip-left" data-aos-duration="1500">{res.name}</h1>
              </div>
            ))}
          </div>
        </div>
      </>
    </Top>
  );
}

export default Fooditems;
