import { createStore, createHook, defaults } from "react-sweet-state";
import initialState from "./initialstate";
import actions from "./actions";

defaults.devtools = true;
const RequestForm = createStore({
  name: "requestform",
  initialState,
  actions,
});

export const RequestItemForm = createHook(RequestForm, {
  selector: (state) => state,
});