import React from "react";
import { Demobanner } from "./style";
import arrow from "../../Images/arrow.png";
import icon1 from "../../Images/icon-1.png";
import icon2 from "../../Images/icon-2.png";
import vendor from "../../Images/vendor.png";
import Advancepayment from "../../Images/Advancepayment.png";
import finalpayment from "../../Images/finalpayment.png";
import completeorder from "../../Images/order.png";
function Work() {
  return (
    <Demobanner>
      <div className="main">
        <div className="top">
          <div className="content">
            <div className="heading">
              <img src={arrow} alt="arrow"></img>
            </div>
            <div className="msg">
              <h1>How it work</h1>
            </div>
            <div className="para">
              <h1>The Process of our service</h1>
            </div>
            <div className="sub">
              <div className="wrapper">
                <div className="first">
                  <img src={icon1} alt="image"></img>
                  <small>Choose Restaurant/Chef/Caters</small>
                  <div className="sent">
                    <h2>
                      Choose the right restaurant as it can make your days
                      awesome!..
                    </h2>
                  </div>
                </div>
                <div className="first">
                  <img src={icon2} alt="image2"></img>
                  <small>Place an order</small>
                  <div className="sent">
                    <h2>Place an order and Wait for the vendor response</h2>
                  </div>
                </div>
                <div className="first">
                  <img src={vendor} alt="image3"></img>
                  <small>Wait for Vendor Response</small>
                  <div className="sent">
                    <h2>Vender accept Your request!</h2>
                  </div>
                </div>
                <div className="first">
                  <img src={Advancepayment} alt="image3"></img>
                  <small>Make Advance Payment</small>
                  <div className="sent">
                    <h2>Make Your advance payment first</h2>
                  </div>
                </div>
                <div className="first">
                  <img src={finalpayment} alt="image3"></img>
                  <small>Make Final Payment</small>
                  <div className="sent">
                    <h2>Complete your Payment procedures</h2>
                  </div>
                </div>
                <div className="first">
                  <img src={completeorder} alt="image3"></img>
                  <small>Complete Order</small>
                  <div className="sent">
                    <h2>
                      Finnaly your order has been Completed Succesfully!!!
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Demobanner>
  );
}

export default Work;
