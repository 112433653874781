import styled from "styled-components";
import bannerimg from '../../Images/activitybanner.png'
import smallimg from '../../Images/plate.png'
import Colors from "../common/Colors/Colors"
export const Banner = styled.div`
  .banner-section {
    background-image: url(${bannerimg});
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px;
    display: flex;
    justify-content: center;
    background-color: ${Colors.loginbanner};
    align-item: center;
    @media (max-width:648px){
      height:unset;
    }
  }
  .content-section1 {
    display: flex;
    justify-content: center;
  }
  .section {
    width: 90%;
    display: flex;
    justify-content: center;
    gap: 50px;
    @media (max-width:1350px){
      gap:30px;
    }
    @media (max-width:1300px){
      gap:15px;
      flex-wrap: wrap;
    }
   
  
  }
  .img {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    img {
      width: 300px;
      height: 300px;
      @media (max-width:1350px){
        height:unset;
        width:250px;
      }
     
    }
  }
  .details {
    position: absolute;
    h3 {
      font-family: "racing_sans_oneregular";
      font-weight: bold;
      letter-spacing: 4px;
      color: #78786a;
      font-size: 40px;
      opacity: 0.6;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    span {
      font-family: "dm_sansregular";
      font-size: 22px;
      font-weight: bold;
      letter-spacing:2px;
      opacity: 0.5;
      @media (max-width:1350px){
        font-size:18px;
      }
     
      
    }
  }
`;