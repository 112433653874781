import { get, post, del } from "../common/remote/base_api";
export const GetsOrderview = (data) => {
  return get(`user/orders/`, data);
};
export const Cancellorder = (id) => {
  return get(`user/cancel_order/${id}/`);
};
export const Paymentresponse = (data) => {
  return post(`user/make_advance_payment_web/`, data);
};

export const Finalrazerpaysettlement = (data) => {
  return post(`user/payment_success/`, data);
};
export const Makefinalpayment = (data) => {
  return post(`user/make_final_payment/`, data);
};

export const DeleteOrder = (id) => {
  return del(`user/delete_order/${id}/`);
};
