import React, { useState, useEffect } from "react";
import { firebase } from "../../common/Firebase/index";
import Logo from "../../Images/logo2.png";
import img from "../../Images/Foodbuoy.png";
import { Top, Notify } from "./style";
import {
  MenuOutlined,
  CloseOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import { Badge, Popconfirm, Popover, Drawer } from "antd";
import { useHistory } from "react-router";
import { auth } from "../../common/Firebase";
import { notification } from "antd";
import {
  SearchOutlined,
  BellOutlined,
  UserOutlined,
  ShoppingCartOutlined,
  PoweroffOutlined,
  AlignRightOutlined,
} from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import Navbar from "../Navbar";

import { OurProfile } from "../UserProfile/store/index";
import { Getmycartitems } from "../CartPage/store";
import { Notificationdata } from "./store";
import { GetAdvertisementsHome } from "../HomePage/store";
import { Shopsanditems } from "../Products/store";

function Header() {
  //usestate...
  const [ontop, setontop] = useState(true);

  const [{ profile_photo }, { getUser }] = OurProfile();
  const [{ rerendercart }, { setrerndercart }] = Getmycartitems();
  const [{ Notification }, { setNotification, Getnotifications }] =
    Notificationdata();
  const [{ iSauthenticated }, { setiSauthenticated }] = GetAdvertisementsHome();
  const [{ rendercart }, { setrendercart }] = Shopsanditems();
  //....
  let myuser = firebase.auth().currentUser;
  //useEffect...............
  useEffect(() => {
    getUser();
    // Getcartitems();
    Getnotifications();
  }, []);
  useEffect(() => {
    localStorage.getItem("cart");
  }, [rendercart,rerendercart]);
  const scrollbar = () => {
    if (window.scrollY > 0) {
      setontop(false);
    } else {
      setontop(true);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollbar);
    return () => window.removeEventListener("scroll", scrollbar);
  }, []);

  const location = useLocation();

  //routing

  let History = useHistory();
  const logoLink = () => {
    if (myuser === null ? History.push("/login") : History.push("/home"));
  };
  const HomeLink = () => {
    History.push("/home");
  };
  const ProfileLink = () => {
    History.push("/account");
  };
  const EventLink = () => {
    History.push("/eventpage");
  };
  const OrderLink = () => {
    History.push("/order");
  };
  const PowerLink = () => {
    firebase.auth().signOut();
    setiSauthenticated(null);
    window.location.replace(`/login`);
    notification["success"]({
      message: "success",
      description: "User Signout Successfully",
    });
  };
  const ShopLink = () => {
    History.push("/cartpage");
  };
  const UserLink = () => {
    History.push("/account");
  };
  const NotificationLink = () => {
    History.push("/notification");
  };
  //
  //drawerlink
  const logoLinkd = () => {
    if (myuser === null ? History.push("/") : History.push("/home"));
  };

  const HomeLinkd = () => {
    History.push("/home");
    onClose();
  };
  const EventLinkd = () => {
    History.push("/eventpage");
    onClose();
  };
  const OrderLinkd = () => {
    History.push("/order");
    onClose();
  };
  const PowerLinkd = () => {
    History.push("/");
    onClose();
  };
  const ShopLinkd = () => {
    History.push("/cartpage");
    onClose();
  };
  const UserLinkd = () => {
    History.push("/account");
    onClose();
  };

  //slicing an array................................................................

  let gain = [];
  let mainreversesort = Notification;
  var firstRun =
    mainreversesort.length === 0
      ? gain
      : mainreversesort.sort(function (a, b) {
          return a.id < b.id;
        });

  const slicedarray =
    firstRun.length === 0
      ? gain
      : firstRun.filter((month, idx) => idx < 5) || gain;
  //.......................................................................................
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  //
  let mycarta = localStorage.getItem("cart");
  let cart = "";

  const contentsofsignout = (
    <div>
      <small>Sign Out</small>
    </div>
  );
  let user = localStorage.getItem("userdata");

  //sign out

  return (
    <>
      {location.pathname === `/` ? (
        ""
      ) : (
        <Top id="arrow">
          {ontop ? (
            <div className="main">
              <div className="wrapper">
                {myuser === null ? (
                  <div className="logo">
                    <img src={Logo} alt="logo" onClick={() => logoLink()} />
                  </div>
                ) : (
                  <div className="logo">
                    <img src={Logo} alt="logo" onClick={() => logoLink()} />
                  </div>
                )}

                <div className="link">
                  {location.pathname === `/` ||
                  myuser === null ||
                  location.pathname === `/login` ? (
                    <ul className="item">
                      <li>Let's eat together....</li>
                    </ul>
                  ) : (
                    <ul>
                      <li onClick={() => HomeLink()} className="hoover">
                        Home
                      </li>

                      <li onClick={() => EventLink()} className="hoover">
                        Event
                      </li>

                      <li onClick={() => OrderLink()} className="hoover">
                        Order
                      </li>

                      <li className="image">
                        <img src={profile_photo} alt="img"></img>
                      </li>
                      <li>
                        <Popover
                          content={
                            <Notify>
                              {slicedarray?.map((res) => (
                                <div className="main">
                                  <small>{res?.body}</small>
                                </div>
                              ))}
                              <div className="second">
                                <h1>
                                  <NotificationOutlined
                                    onClick={() => NotificationLink()}
                                    style={{ fontSize: "26px", color: "black"  }}
                                   
                                  />
                                </h1>
                              </div>
                            </Notify>
                          }
                          placement="bottom"
                        >
                          <Badge
                            count={slicedarray.length}
                            style={{ backgroundColor: "#52c41a" }}
                          >
                            <BellOutlined
                              style={{ fontSize: "26px", color: "white",
                              opacity: ".5"}}
                            />
                          </Badge>
                        </Popover>
                        ,
                      </li>

                      <li>
                        <Badge
                          onClick={() => ShopLink()}
                          count={mycarta === "undefined" ? 0 : mycarta}
                          showZero
                        >
                          <ShoppingCartOutlined
                            onClick={() => ShopLink()}
                            style={{ fontSize: "26px",  color: "white",
                            opacity: ".5" }}
                          />
                        </Badge>
                      </li>
                      <li>
                        <UserOutlined
                          onClick={() => UserLink()}
                          style={{ fontSize: "26px",  color: "white",
                          opacity: ".5" }}
                        />
                      </li>
                      <li>
                        <Popover content={contentsofsignout} placement="right">
                          <Popconfirm
                            placement="bottom"
                            title="Are you sure"
                            onConfirm={() => PowerLink()}
                            okText="Yes"
                            cancelText="Cancel"
                          >
                            <PoweroffOutlined
                              style={{
                                fontSize: "36px",
                                color: "white",
                                opacity: ".5",
                              }}
                            />
                          </Popconfirm>
                        </Popover>
                      </li>
                    </ul>
                  )}
                  {location.pathname === "/login" ? (
                    ""
                  ) : (
                    <ul className="icondrawer">
                      <li style={{ color: "white" }}>
                        <MenuOutlined onClick={showDrawer} />
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="navbar">
              <Navbar />{" "}
            </div>
          )}

          <div>
            <Drawer
              placement="top"
              title="Basic Drawer"
              onClose={onClose}
              visible={visible}
            >
              <div
                className="icon"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "20px",
                  marginRight: "40px",
                }}
              >
                <img
                  src={img}
                  alt="logo"
                  li
                  onClick={() => logoLinkd()}
                  style={{ width: "136px", marginTop: "-10px" }}
                />
                <CloseOutlined onClick={onClose} />
              </div>
              <div
                className="links"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <ul
                  style={{
                    listStyle: "none",
                    fontSize: "18px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    cursor: "Pointer",
                    fontWeight: "bold",
                  }}
                >
                  <li onClick={() => HomeLinkd()}>Home</li>

                  <li onClick={() => EventLinkd()}>Event</li>

                  <li onClick={() => OrderLinkd()}>Order</li>
                </ul>
              </div>
              <div
                className="links"
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <ul style={{ gap: "20px", display: "flex", listStyle: "none" }}>
                  <li>
                    <Badge count={slicedarray.length}>
                      <BellOutlined
                        style={{
                          fontSize: "24px",
                          color: "black",
                          fontWeight: "bold",
                        }}
                      />
                    </Badge>
                  </li>
                  <li>
                    <Badge
                      //  count={mycart?.order_items?.length}
                      showZero
                      onClick={() => ShopLink()}
                    >
                      <ShoppingCartOutlined
                        onClick={() => ShopLinkd()}
                        style={{
                          fontSize: "24px",
                          color: "black",
                        }}
                      />
                    </Badge>
                  </li>
                  <li>
                    <UserOutlined
                      onClick={() => UserLinkd()}
                      style={{ fontSize: "24px" }}
                    />
                  </li>
                  <li>
                    <Popover content={contentsofsignout} placement="right">
                      <Popconfirm
                        placement="bottom"
                        title="Are you sure"
                        onConfirm={() => PowerLink()}
                        okText="Yes"
                        cancelText="Cancel"
                      >
                        <PoweroffOutlined
                          style={{ fontSize: "24px", fontWeight: "bold" }}
                        />
                      </Popconfirm>
                    </Popover>
                  </li>
                </ul>
              </div>
            </Drawer>
          </div>
        </Top>
      )}
    </>
  );
}

export default Header;
