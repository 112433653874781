import { SendContact } from "../../../infrastructure/Contact";
import {  message as msg } from "antd";
const actions = {
    setisLoading:
      (isLoading) =>
      ({ setState }) => {
        setState({ isLoading });
      },
      Postsendcontactmail:
    (data) =>
    async ({ dispatch, getState }) => {
      dispatch(actions.setisLoading(true));
      const { name, email, number, message, subject } = data;
     
      if (name==="") {
       msg.warning('Name field is empty');
        return;
      } else if ( email==="") {
       msg.warning('Email field is empty');
        return;
      }else if (number==="") {
       msg.warning('Number field is empty');
        return;
      }else if (message==="") {
       msg.warning('Message field is empty');
        return;
      }else if (subject==="") {
        msg.warning('Subject field is empty');
        return;
      }
      try {
        await SendContact(data);

        dispatch(actions.setisLoading(false));
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
    }
    export default actions;