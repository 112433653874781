import styled from "styled-components";
import banner from "../../Images/eventsb3.png";
import Colors from "../common/Colors/Colors";
export const Top = styled.div`

  .box{
    margin-top:100px;
    margin-bottom: 100px;
  
    .containerevent{
    
      .containerd{
       
        .borderevent{
           display: flex;
            flex-wrap:wrap;
            justify-content: center;
            gap:30px;
          .box1{
        padding:20px;
        border:2px dashed rgba(40, 41, 50, 0.2);
        border-radius:10px;
        width:400px;
        height:230px;
        &:hover{
          border:2px solid ${Colors.secondary};
        }

        h1{
           font-family: "open_sansitalic";
          color: #b0b3b8; 
          font-size: 22px;
          margin:0;
        
        }
        .name{
            font-family: 'pt_serifbold';
           
          font-size: 18px;
         font-weight: 700;

         padding-bottom: 15px;

        }
        .eventul{
          margin: 0;
          outline: 0 none;
          padding: 0;
          margin-top:5px;
          list-style: none;
          margin-bottom:20px;
          li{
              font-family: 'dm_sansregular';
               font-size:14px;
            small{
               font-family: "montserratregular";
               font-size:12px;
            }
          }
       
        }
        .btns{
            
             gap:20px;
             display:flex;
             button{
               background-color: ${Colors.secondary};
               padding:3px;
               width:40px;
               height:35px;
               border:none;
               color:black;
               font-size:13px;
               font-family: "montserratregular";
               border-radius:4px;
               cursor:pointer;
               color:white;
               &:active {
                 color:black;
               }
             }  
             .btns{
              background-color:${Colors.secondary};
               padding:5px;
               width:55px;
               height:35px;
               border:none;
               color:black;
               font-size:13px;
               font-family: "montserratregular";
               border-radius: 4px;
               display: flex;
               justify-content: center;
               align-items: center;
               cursor:pointer;
               color:white;
               &:active {
                 color:black;
               }
             }  
           
        }
        @media(max-width:474px){
          width: 380px;
          height: 211px;
         } 
         @media(max-width:440px){
          width: 345px;
          height: 193px;
         }
         @media(max-width:406px){
          width: 303px;
          height: 190px;
         }
         @media(max-width:370px){
           width:270px;
         }
         @media(max-width:344px){
          width: 250px;
         }
      }
      
        }
      }
    }
  }
  .main {
    background-image: url(${banner});
    background-repeat: no-repeat;
    width: 100%;
    height:800px;
    background-size: cover;

    .center {
      padding-top: 300px;
      .level {
        display: flex;
        justify-content: center;
        h1 {
         
          margin-top: 0;
          margin-bottom: 0.5 em;
          color: white;
        font-weight: bold;
        font-size: 40px;
        }
      }
      .link {
        display: flex;
        justify-content: center;
        align-items: center;
        .first {
 font-family: 'dm_sansregular';
          color: white;
          font-size: 20px;
         font-weight:bold;
        }
        .second {
           font-family: 'dm_sansregular';
          color: #e6e6e6;
          font-size: 20px;
          font-weight: 500;
        }
      }
     
    }
    .button {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      button {
        font-family: 'dm_sansregular';
        background: ${Colors.secondary};
        padding: 5px;
        width: 160px;
        height:60px;
        border: none;
        height: 35px;
        border-radius: 4px;
        font-size:17px;
        font-weight:bold;
        color: black;
        text-align: center;
        cursor: pointer;
        color:white;
        &:active {
          background-color:black;
          color: white;
      }
   }
  
   @media(max-width:744px){
    height: 700px;
   }
   @media(max-width:698px){
     height:600px;
   }
   
  }
  
`;

export const DIV = styled.div`
.name{
    display:flex;
     margin-top:10px;
  
    gap: 20px;
    background: #fafafa;
    align-items:center;
    justify-content:center;
        input {
          border-radius: 3.5px;
          padding: 1vh;
          font-size: 1.7vh;
          border: 1px solid #e0e0e0;
          height: 5vh;
          background: white;
          box-shadow: inset 0 -1px 0 ${Colors.secondary};
          font-family: "montserratregular";
          font-size: 14px;
          
        }
  }
  .adrs{
    display:flex;
    justify-content:space-between;
    gap:20px;
    align-items:center;
    justify-content:center;
    margin-top:15px;
    input {
      border-radius: 3.5px;
      padding: 1vh;
      font-size: 1.7vh;
      border: 1px solid #e0e0e0;
      height: 5vh;
      background: white;
      box-shadow: inset 0 -1px 0 ${Colors.secondary};
      font-family: "montserratregular";
      font-size: 14px;
      
    }
  }
}
`;
