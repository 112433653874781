import React from "react";
import { DIV } from "./style";
import mobile from "../../Images/mobile.png";
import playstore from "../../Images/playstore.png";
import appstore from "../../Images/Appstore.png";
import arrow from "../../Images/arrow.png";
function Footerpart() {
  return (
    <DIV>
      <div className="heading">
        <img src={arrow} alt="Arrow" />
        <h1>Download our app </h1>
        <h2> The Process of our service</h2>
      </div>
      <div className="main">
        <div className="mobile">
          <div className="image">
            <img src={mobile} alt="mobile"></img>
          </div>
          <div className="content">
            <div className="text">
              <h1>
                Foodbuoy Now <br /> In Your Hand
              </h1>
            </div>
            <div className="text2">
              <h1>Download! to get this app Faster way to order food</h1>
            </div>
            <div className="img2">
              <a
                href="https://play.google.com/store/apps/details?id=quadauq.foodbuoy.food_buoy_user"
                target="_blank"
              >
                {" "}
                <img src={playstore} alt="playstore"></img>
              </a>

              {/* <img src={appstore} alt="appstore"></img> */}
            </div>
          </div>
        </div>
      </div>
    </DIV>
  );
}

export default Footerpart;
