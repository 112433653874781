const initialState = {
  isloading: false,
  token: null,
  event_name: "",
  name: "",
  delivery_address: "",
  pincode: "",
  landmark: "",
  latitude: "",
  longitude: "",
  delivery_date: "",
  delivery_time: "",
  caretaker_name: "",
  caretaker_contact: "",
  address:"",
  alternate_contact: "",
  geo_location: "geolocation",
  eventfun:[],
};

export default initialState;
