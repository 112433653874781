import React from "react";
import { OurProfile } from "../../../UserProfile/store";

function UserAvatar() {
  const [{ user }, {}] = OurProfile();


  return (
    <div>
      <img
        src={user?.profile_photo}
        style={{ width: 40, height: 40, borderRadius: 20 }}
      ></img>
    </div>
  );
}

export default UserAvatar;
