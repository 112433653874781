import styled from "styled-components";
export const Deal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  .dealheader {
    width: 100%;
    height: 80px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  display:flex;
  align-items: center;

  }
  .dealslider {
    width: 100%;
    display: flex;
    justify-content: center;

    .dealssss {
      width: 90%;

      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      gap: 30px;
      align-items: center;

      .dealone {
        display: flex;
        gap: 30px;
        flex-direction: column;
        .dealscontent {
          width: 220px;
          height: 200px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          border-radius: 40px;
          position: relative;
          top: 0;
          left: 0;
     
          img {
            width: 100%;
            height: 100%;
            border-radius: 40px;
          }

          @media (max-width: 800px) {
            width: 180px;
            height: 160px;
          }
          @media (max-width: 667px) {
            width: 160px;
            height: 140px;
          }
          &:hover{
            transform: scale(2);
            z-index:1;
          }
        }
        .dealsscontent {
          width: 220px;
          height: 200px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          border-radius: 40px;
          position: relative;
          top: 0;
          left: 0;
          filter:blur(1px);
          border:1px solid grey;
          .backimage{
            filter:blur(1px);
          }
          img {
            width: 100%;
            height: 100%;
            border-radius: 40px;
            // filter:blur(1px);
          }
          .soldoutimg {
            position: absolute;
            top: 40%;
            padding-left: 50px;
            width: 180px;
            height: 60px;
            display: flex;
            justify-content: center;
            filter:blur(0px);
            .soldouts {
              width: 100%;
              height: 100%;
           
            }
          }
          //   .soldouts{
          //     position:absolute;
          // // top:40%;
          //     padding-left:50px;
          //     width:180px;
          //     height:60px;
          //     display:flex;
          //     justify-content:center;
          //     backdrop-filter: blur(1px);

          //   }
          @media (max-width: 800px) {
            width: 180px;
            height: 160px;
          }
          @media (max-width: 667px) {
            width: 160px;
            height: 140px;
          }
        }
        .headdeal {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;

          h1 {
            font-size: 18px;
          }
          h6 {
            color: grey;
          }
        }
      }
    }
  }
  .contactno {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    .wtsap {
      width: 80px;
      height: 80px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .nmbrdeal {
      h1 {
        margin: 0px;
        padding: 0px;
        font-size: 34px;
        font-family: "Ropa Sans", sans-serif;
        color: grey;
      }
    }
  }
`;
