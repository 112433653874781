import React, { useState, useEffect } from "react";
import { Top } from "./style";

import BackArrow from "../../presentation/BackArrow";
import { Rate } from "antd";
import { StarOutlined, StarFilled, StarTwoTone } from "@ant-design/icons";
import { Shopsanditems } from "./store";
import Loader from "../../presentation/common/widgets/Loader";
import { Modal, Button } from "antd";
import { useHistory } from "react-router-dom";
import { Getcartdetails } from "../../infrastructure/Cartpage";
import { Getmycartitems } from "../CartPage/store";
import Pizzaspinner from "../common/widgets/pizzaspinner";
import { firebase } from "../../common/Firebase/index";
import { notification } from "antd";
function Products({ match }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rerender, setrerender] = useState(false);

  let history = useHistory();

  const [
    {
      isLoading,
      Shopview,
      search,
      foodview,
      Addtocart,
      cartres,
      Addtocartreset,
      rendercart,
      Rating
    },
    {
      setShopview,
      Getshopview,
      GetFoodview,
      AddYouritem,
      setAddtocart,
      setAddtocartreset,
      setSearch,
      setrendercart,
      setLoading,
    },
  ] = Shopsanditems();
  const moreinfo = (id) => {
    history.push(`/shopinformation/${id}`);
  };
  const [{ mycart }, { Getcartitems }] = Getmycartitems();

  localStorage.setItem("cart", mycart?.order_items?.length);

  //search by food name ..........................

  let resetcart = Addtocartreset;
  const [idd, setidd] = useState(match.params.id);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    localStorage.removeItem("cart");
    setAddtocart(resetcart);
    AddYouritem();
    setSearch("");

    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  let mycartlength = "";
  const handleclick = (res) => {
    setAddtocart(res);
    AddYouritem(setIsModalVisible, mycart);
    setSearch("");
    setrendercart(!rendercart);
    setrerender(!rerender);
  };
  let num = Shopview.ratings;
  let rating = Math.round(num * 100) / 100;
  const [token, setToken] = useState("");
  localStorage.setItem("token",token);
  useEffect(() => {
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
      if (token === "") {
      } else {
        Getshopview(idd);
        Getcartitems();
        GetFoodview(idd);
      }
    });
  }, [token, rerender, rendercart]);

  useEffect(() => {
    localStorage.getItem("cart");
  }, [rendercart]);

  let dummyrating = 0.0;

  if (isLoading) {
    return <Pizzaspinner />;
  }

  return (
    <Top>
      <div className="main">
        <div className="back">
          <BackArrow />
        </div>
        <div className="wrappers">
          <div className="box">
            <div className="image">
              <img src={Shopview.image} alt="shop photos"></img>
              <div className="contact">
                <h1>{Shopview.name}</h1>
                <small>
                  {Shopview.address},{Shopview.landmark}
                </small>

                <div className="viewmore">
                  <button onClick={() => moreinfo(Shopview.id)}>
                    View more about {Shopview.name}{" "}
                  </button>
                </div>
                <div className="rating">
                  <StarOutlined />
                  <small>{rating}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wrapper2">
        <div className="search">
          <div className="serch">
            <input
              type="search"
              placeholder="Search your special food"
              onChange={(e) => setSearch(e.target.value)}
            ></input>
          </div>
        </div>
      </div>
      <div className="wrapper3">
        <div className="prdts">
          {foodview
            ?.filter((res) => {
              if (search === "") {
                return res;
              } else if (
                res.name.toLowerCase().includes(search.toLowerCase())
              ) {
                return res;
              }
            })
            .map((res) => (
              <div className="itm">
                <div className="imgL">
                  <h1> {res.name}</h1>
                  <h2>{res.description}</h2>
                  <h2>&#8377; {res.price} </h2>
                  <h2
                    style={{
                      display: "flex",
                      gap: "5px",
                      backgroundColor: "#96d707",
                      width: 40,
                      height: 20,
                      alignItems: "center",
                      borderRadius: 6,
                      color: "white",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <StarOutlined style={{ color: "white" }} />
                    <small>{res.rating.toFixed(1)}</small>
                  </h2>
                </div>

                <div className="detailsR">
                  <a href={res.image} target="blank">
                    <img src={res.image} alt="product"></img>
                  </a>

                  <button
                    className="btn"
                    type="button"
                    onClick={() => [
                      handleclick({
                        food_id: res.id,
                        quantity: 1,
                        action: "add",
                        force_add: false,
                      }),
                      setAddtocartreset({
                        food_id: res.id,
                        quantity: 1,
                        action: "add",
                        force_add: true,
                      }),
                    ]}
                  >
                    {" "}
                    Add to Cart
                  </button>
                </div>
              </div>
            ))}
        </div>
        <Modal
          title=""
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <p>Your cart already have items , Do you want to clear the cart ?</p>
        </Modal>
      </div>
    </Top>
  );
}

export default Products;
