import styled from "styled-components";
import backgroundimg from "../../Images/mainus.jpg";
import Colors from "../common/Colors/Colors"
export const Details = styled.div`
.page {
    background-image: url(${backgroundimg});
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    
  }
}
.page-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
       font-family: 'paytone_oneregular';
      color: white;
      font-size: 45px;
      margin: 0px;
      padding: 0px;
      margin-top:100px;
    }
    ul {
      list-style: none;
      display: flex;
      gap: 15px;
      font-size: 20px;
    }
  }
  .arrow{
    margin-bottom:100px;
  }
 .section{
   margin-top:50px;
   display: flex;
   justify-content: center;
   .top{

     width:70%;
     .contactus{
      margin-bottom:30px;
      display: flex;
      justify-content: center;
      h1{
       font-family: 'dm_sansregular';
        font-size:16px;
        text-align: center;
      }
    }
     .heading{
     
       display:flex;
       justify-content:center;
       h1{
          font-family: 'pt_serifbold';
          color:grey;
          font-weight:bold;
       }
     }
     .inputbox{
         font-family: 'dm_sansregular';
       display: flex;
       justify-content: center;
        gap:30px;
    
       .addres{
       
         display: flex;
         flex-direction: column;
         margin-bottom:10px;
         @media(max-width:980px){
           width:100%;
         }
         input{
             font-family: 'dm_sansregular';
           width:400px;
          border-radius: 3.5px;
          padding: 1vh;
          font-size: 1.7vh;
          border: 1px solid #e0e0e0;
          height: 7vh;
          background: white;
          box-shadow: inset 0 -1px 0 ${Colors.secondary};
          @media(max-width:980px){
            width:100%;
          }
         }
        
         }
         @media(max-width:734px){
          flex-direction:column;
          gap:unset
       }
     }
     .textarea{
         
       display: flex;
       justify-content: center;
      margin-bottom:40px;
   
    
       .main{
         flex-direction: column;
         display: flex;
         @media(max-width:980px){
           width:100%;
         }
       }
       input{
           font-family: 'dm_sansregular';
         width:822px;
          border-radius: 3.5px;
          padding: 1vh;
          font-size: 1.7vh;
          border: 1px solid #e0e0e0;
          height: 13vh;
          background: white;
          box-shadow: inset 0 -1px 0 ${Colors.secondary};
          @media(max-width:980px){
            width:100%;
          }
       }
       
     }
     .btn{
       margin-bottom:30px;
       display:flex;
       justify-content:center;
       .btn1{
         display: flex;
         justify-content: center;
         button{
             font-family: 'dm_sansregular';
           width: 300px;
          height: 50px;
          background:${Colors.secondary};
          border: none;
          border-radius: 8px;
          color:white;
          cursor: pointer;
          &:active {
            background-color: black;
            color: white;
         }
       @media(max-width:980px){
        width: 248px;
        height: 39px;
       }
       @media(max-width:500px){
       width: 199px;
       height: 33px;
       }
       @media(max-width:370px){
        width: 140px;
       }
   }
       }
     
     }
   }

 }
`;
