import React from "react";
import { GetAdvertisementsHome } from "../../../HomePage/store";
import { CloseButton } from "./Style";
import { CloseOutlined } from "@ant-design/icons";
function Closebutton() {
  const [{ showBot }, { settoggleBot }] = GetAdvertisementsHome();

  const showhide = () => {
    settoggleBot(!showBot);
  };

  return (
    <CloseButton>
      <div>
        <small>Conversation with foodbuoy</small>
      </div>
      <button onClick={() => showhide()}>
        <CloseOutlined />
      </button>
    </CloseButton>
  );
}

export default Closebutton;
