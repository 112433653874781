import React,{useState} from "react";
import BackArrow from "../BackArrow";
import { ContactSendEmail } from "./store";
import { Details } from "./style";
function Contactus() {
// const sendmail =()=>{
//   window.Email.send({
//     Host :"foodbuoy.host.com",
//     Username :"support@foodbuoy.com",
//     // Port :465,
//     Password :"4444@Dauq",
//     To : 'ginilmathew3@gmail.com',
//     From : "ginilmathew3@gmail.com",
//     Subject : "This is the subject",
//     Body : "And this is the body",
//     EnableSsl:true,
// }).then(
//   message => alert(message)
// );
// }
const [
  { isLoading, name, email, number, message, subject },
  {
    Postsendcontactmail,
   
  },
] = ContactSendEmail();


const [formData, setFormData] = useState({
  name: "",
  email: "",
  number: "",
  message: "",
  subject: "",
});
const  handlecontact = (value) => {
  Postsendcontactmail(value);

};
  return (
    <Details>
      <div className="page" id="contact">
        <div className="page-content">
          <h1>Contact Us</h1>
          <div className="arrow">
            <BackArrow />
          </div>
        </div>
      </div>
      <div className="section">
        <div className="top">
          <div className="heading">
            <h1>Get In Touch </h1>
          </div>
          <div className="contactus">
              <h1>
                39/2701, 1st Floor Pallickel Building,
                <br /> (75) South janatha road, Palarivattom, Ernakulam, Kochi,
                682025, Kerala, IN,04842998662
              </h1>
            </div>
          <div className="inputbox">
            <div className="addres">
           
              <input type='text' placeholder='Enter your name'
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
              ></input>
            </div>
            <div className="addres">
         
              <input type='email' placeholder='Enter your email'
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
              ></input>
            </div>
          </div>
          <div className="inputbox">
            <div className="addres">
           
              <input type='text' placeholder='Enter your phone number'
               onChange={(e) =>
                setFormData({ ...formData, number: e.target.value })
              }
              ></input>
            </div>
            <div className="addres">

              <input type='text' placeholder='Subject'
                 onChange={(e) =>
                  setFormData({ ...formData, subject: e.target.value })
                }
              ></input>
            </div>
          </div>
          <div className="textarea">
            <div className="main">
        
              <input type="text" placeholder="Start write here your message"
                onChange={(e) =>
                  setFormData({ ...formData, message: e.target.value })
                }
              ></input>
            </div>
          </div>
          <div className="btn">
           <div className="btn1">
             <button onClick={()=>handlecontact(formData)}>Submit</button>
           </div>

          </div>
        </div>
      </div>
    </Details>
  );
}

export default Contactus;
