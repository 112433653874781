import { Addresponse } from "../../../infrastructure/Advtesment_slider";
import { Getfoodtypes } from "../../../infrastructure/foodcategories";

const actions = {

  setLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  settokenres:
    (tokenres) =>
    ({ setState }) => {
      setState({ tokenres });
    },
    setiSauthenticated:
    (iSauthenticated) =>
    ({ setState }) => {
      setState({ iSauthenticated });
    },
    settoggleBot:
    (showBot) =>
    ({ setState }) => {
      setState({ showBot });
    },
   

  getadds:
    () =>
    async ({ setState, dispatch, getState }) => {
      dispatch(actions.setLoading(true));
      try {
        let res = await Addresponse();
        setState({adds:res.data.data})
        // dispatch(actions.setadds(res.data.data));
      } catch (err) {
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
  getitems:
    () =>
    async ({ setState, dispatch, getState }) => {
      dispatch(actions.setLoading(true));
      try {
        let res = await Getfoodtypes();
        // dispatch(actions.setitems(res.data.data));
        setState({items:res.data.data})
        dispatch(actions.setLoading(false));
      } catch (err) {
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
};

export default actions;
