import {
  Cancellorder,
  DeleteOrder,
  Finalrazerpaysettlement,
  GetsOrderview,
  Makefinalpayment,
  Paymentresponse,
} from "../../../infrastructure/OrderPlaced";
import initialState from "./initialState";
import { notification } from "antd";
import { message, Button } from "antd";
// const PaytmChecksum = require("../checksum/checksum");
import { PaytmChecksum } from "../checksum/checksum";
const https = require("https");
const actions = {
  setLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },

  setpaymentsitem:
    (paymentsitem) =>
    ({ setState }) => {
      setState({ paymentsitem });
    },
  setrazerpaykey:
    (razerpaykey) =>
    ({ setState }) => {
      setState({ razerpaykey });
    },
  setorderid:
    (order_id) =>
    ({ setState }) => {
      setState({ order_id });
    },
  setRazorpayorderid:
    (razorpay_order_id) =>
    ({ setState }) => {
      setState({ razorpay_order_id });
    },

  setRazorpaypaymentid:
    (razorpay_payment_id) =>
    ({ setState }) => {
      setState({ razorpay_payment_id });
    },
  setfinalpaymentitem:
    (finalpayment) =>
    ({ setState }) => {
      setState({ finalpayment });
    },
  getadds:
    () =>
    async ({ setState, dispatch, getState }) => {
      dispatch(actions.setLoading(true));
      try {
        let res = await GetsOrderview();
        setState({ order: res.data.data });
        // dispatch(actions.setOrder(res.data.data));
        console.log(res, "..........res");
      } catch (err) {
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
  Cancelmyorder:
    (id) =>
    async ({ setState, dispatch, getState }) => {
      dispatch(actions.setLoading(true));
      try {
        await Cancellorder(id);
        message.success("Your order has been canceled");
        dispatch(actions.getadds());
      } catch (err) {
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
  Mypaymentpost:
    () =>
    async ({ dispatch, getState }) => {
      dispatch(actions.setLoading(true));
      const { order_id} = getState();

      try {
        var formdat = new FormData();
        formdat.append("order_id", order_id);
        let res = await Paymentresponse(formdat);
 
        let orderId = res.data.data.paytm_order_id;
        let mid = res.data.data.paytm_mid; // Merchant ID
        let mkey = res.data.data.paytm_merchant_key;
        var paytmParams = {};

        paytmParams.body = {
          requestType: "Payment",
          mid: mid,
          websiteName: res.data.data.paytm_default_website,
          orderId: orderId,
          callbackUrl: res.data.data.paytm_callback_url,
          txnAmount: {
            value: res.data.data.amount,
            currency: "INR",
          },
          userInfo: {
            custId: res.data.data.user.id,
          },
        };

        PaytmChecksum.generateSignature(
          JSON.stringify(paytmParams.body),
          mkey
        ).then((checksum) => {
          paytmParams.head = {
            signature: checksum,
          };
          var post_data = JSON.stringify(paytmParams);
          var options = {
            /* for Staging */
            hostname: "securegw.paytm.in" /* for Production */,
            // hostname: 'securegw.paytm.in',
            port: 443,
            path: `/theia/api/v1/initiateTransaction?mid=${mid}&orderId=${orderId}`,
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Content-Length": post_data.length,
            },
          };
          var response = "";
          var post_req = https.request(options, function (post_res) {
            post_res.on("data", function (chunk) {
              response += chunk;
            });

            post_res.on("end", function () {
              dispatch(
                actions.setpaymentsitem({
                  token: JSON.parse(response).body.txnToken,
                  order: orderId,
                  mid: mid,
                  amount: res.data.data.amount,
                })
              );
            });
          });
          post_req.write(post_data);
          post_req.end();
        });

        dispatch(actions.getadds());
      } catch (err) {
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
  PostAdvancepayment:
    (value) =>
    async ({ dispatch, getState }) => {
      dispatch(actions.setLoading(true));
      const { order_id } = getState();
      var bodyFormdata = new FormData();
      bodyFormdata.append("order_id", order_id);
      bodyFormdata.append("paytm_order_id", value.paytm_order_id);
      bodyFormdata.append("paytm_txn_id", value.paytm_txn_id);
      try {
        await Finalrazerpaysettlement(bodyFormdata);
        dispatch(actions.setpaymentsitem(initialState.paymentsitem));
        dispatch(actions.getadds());
      } catch (err) {
        notification["warning"]({
          message: "warning",
          description: "Error occured",
        });
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
  Finalpaymentpost:
    (id) =>
    async ({ setState, dispatch, getState }) => {
      dispatch(actions.setLoading(true));
      try {
        var formdat = new FormData();
        formdat.append("order_id", id);
        let res = await Makefinalpayment(formdat);
     
        let orderId = res.data.data.paytm_order_id;
        let mid = res.data.data.paytm_mid; // Merchant ID
        let mkey = res.data.data.paytm_merchant_key;
        var paytmParams = {};

        paytmParams.body = {
          requestType: "Payment",
          mid: mid,
          websiteName: res.data.data.paytm_default_website,
          orderId: orderId,
          callbackUrl: res.data.data.paytm_callback_url,
          txnAmount: {
            value: res.data.data.amount,
            currency: "INR",
          },
          userInfo: {
            custId: res.data.data.user.id,
          },
        };

        PaytmChecksum.generateSignature(
          JSON.stringify(paytmParams.body),
          mkey
        ).then((checksum) => {
          paytmParams.head = {
            signature: checksum,
          };
          var post_data = JSON.stringify(paytmParams);
          var options = {
            /* for Staging */
            hostname: "securegw.paytm.in" /* for Production */,

            // hostname: 'securegw.paytm.in',

            port: 443,
            path: `/theia/api/v1/initiateTransaction?mid=${mid}&orderId=${orderId}`,
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Content-Length": post_data.length,
            },
          };
          var response = "";
          var post_req = https.request(options, function (post_res) {
            post_res.on("data", function (chunk) {
              response += chunk;
            });

            post_res.on("end", function () {
              dispatch(
                actions.setpaymentsitem({
                  token: JSON.parse(response).body.txnToken,
                  order: orderId,
                  mid: mid,
                  amount: res.data.data.amount,
                })
              );
            });
          });
          post_req.write(post_data);
          post_req.end();
        });

        dispatch(actions.getadds());
      } catch (err) {
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
  Deletemyorder:
    (id) =>
    async ({ setState, dispatch, getState }) => {
      dispatch(actions.setLoading(true));
      try {
        await DeleteOrder(id);
        dispatch(actions.getadds());
      } catch (err) {
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
};
export default actions;
