import styled from "styled-components";
export const Top = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  .main {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .top {
      margin-top: 30px;
      img {
      }
    }
    h1 {
      font-family: "Raleway";
      font-size: 28px;
      line-height: 32px;
      padding-top:90px;
      // color:#36454F;
      color:grey;
      width:600px;
      display:flex;
      justify-content:center;
      align-items:center;
      @media (max-width:650px){
        width:550px;
        font-size:26px;
      }
      @media (max-width:600px){
        width:500px;
        font-size:24px;
      }
      @media (max-width:530px){
        width:432px;
     text-align:center;
      }
      @media (max-width:470px){
        width:350px;
     
      }
      @media (max-width:470px){
        font-size:22px;
     
      }
      @media (max-width:360px){
        
      width:290px;
      }
    }
    h3 {
      font-family: "pt_serifbold";
      line-height: 32px;
      font-size: 18px;
    }
  }

  .array {
    margin-top: 100px;
   display:flex;
    justify-content: center;
    .array2 {
     
      width: 90%;
      display: flex;
      flex-wrap: wrap;
       gap:50px;
       justify-content: center;
      .topimg {
        display: flex;
        justify-content: space-between;
        position: relative;
        border-radius: 20px;
        width: 400px;
        height: 400px;
        overflow: hidden;
        cursor: pointer;
     
        span {
          position: absolute;
          // background: #efece3;
          background:white;
       
          opacity:0.1;
          width: 100%;
          bottom: 0;
          display:flex;
          height: 100px;
         
           
          @media (max-width:780px){
            height: 75px;
          }
        }
       
        .one {
          transition: transform 0.2s;
          position: relative;
          width: 100%;
          height: 100%;
          display: block;
          :hover {
            transform: scale(1.2);
          
          }
        }
        .two {
          width: 100%;
          height: 163px;
          position: absolute;
          top: 139px;
          left: 0px;
          @media (max-width: 1354px) {
            top: 121px;
          }
          @media (max-width: 1300px) {
            top: 108px;
          }
          @media (max-width: 1250px) {
            top: 90px;
          }
          @media (max-width: 1200px) {
            top: 78px;
          }

          @media (max-width: 1046px) {
            top: 48px;
          }
          @media (max-width: 980px) {
            top: 36px;
          }
          @media (max-width: 900px) {
            top: 17px;
          }
          @media (max-width: 842px) {
            top: 10px;
          }
          @media (max-width: 834px) {
            top: 8px;
          }
       
          
        }
        h1 {
          width: 100%;
          display: flex;
          justify-content: center;
          position: absolute;
          bottom: 0;
          color: black;
          // font-family: "Quicksand", sans-serif;
          font-family: "pt_serifbold";
          font-weight:bold;
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 30px;
          color:white;
          &:hover{
            color:black;
            opacity:0.4;
            transform: scale(1.5);

          }
        }
        @media (max-width: 1354px) {
          width: 383px;
          height: 383px;
        }
        @media (max-width: 1300px) {
          width: 370px;
          height: 370px;
        }
        @media (max-width: 1250px) {
          width: 350px;
          height: 350px;
        }
        @media (max-width: 1200px) {
          width: 330px;
          height: 338px;
        }
        @media (max-width: 1130px) {
          width: 300px;
          height: 319px;
        }
        @media (max-width: 1046px) {
          width: 282px;
          height: 307px;
        }
        @media (max-width: 980px) {
          width: 257px;
          height: 294px;
        }
        @media (max-width: 900px) {
          width: 239px;
          height: 272px;
        }
        @media (max-width: 842px) {
          width: 220px;
          height: 262px;
        }
        @media (max-width: 770px) {
          width: 210px;
          height: 243px;
        }
   
      }

      @media (max-width: 912px) {
        gap: 37px;
      }
      @media (max-width: 842px) {
        gap: 35px;
      }
      @media (max-width: 738px) {
        gap: 27px;
      }
      @media (max-width: 700px) {
        gap: 22px;
      }
  
    }
    }
  
   
  }
`;
