import styled from "styled-components";
import backgroundimg from "../../Images/orderpage.jpeg";
import Colors from "../common/Colors/Colors";
export const Order = styled.div`
  .page {
    background-image: url(${backgroundimg});
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px;
    display: flex;
    justify-content: center;
  
  }
  .page-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top:300px;
//  padding:20px;
    width:100%;


    h1 {
      color:white;
      opacity:0.8;
      font-size:50px;
      margin: 0px;
      padding: 0px;
      margin-top: 100px;
      text-align:center;

      // width:100%;
     
     
      // animation:move 18s linear infinite;
      @media(max-width:730px){
        font-size:44px;
     
      }
      @media(max-width:540px){
        font-size:40px;
     
      }
      @media(max-width:438px){
        font-size:36px;
     
      }
      @media(max-width:394px){
        font-size:30px;
     
      }
      // @keyframes move {
      //   -90%{
      //      transform:translate(0);
         
      //    }
      //    100%{
      //      transform:translate(500px);
        
             
      //    }
      //  }
     }
  
    
    ul {
      list-style: none;
      display: flex;
      gap: 15px;
      font-size: 20px;
    }
  }
  .arrow {
    margin-bottom: 50px;
    padding-top:20px;
  }
  .page-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    min-height:250px;
    .section {
      width: 80%;
      margin-top: 50px;
      margin-bottom: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .items {
        display: flex;
        ul {
          list-style: none;
          display: flex;
          gap: 20px;
          cursor: pointer;
          text-align: center;
          justify-content:center;
          align-items:center;
          flex-wrap:wrap;
          padding:0px;
          margin:0px;
          box-size:border-box;
          li {
            font-size: 17px;
            border: 3px dashed rgba(40, 41, 50, 0.2);
            border-radius:2px;
            font-family: "montserratregular";
            width: 200px;
            height:36px;
            display:flex;
            justify-content:center;
            align-items:center;
            padding:0px;
            margin:0px;
            // &:active {
            //   border: 3px dashed ${Colors.secondary};
            // }
          }
        }
       
      }
     


`;

export const Form1order = styled.div`
  .Box {
    width: 540px;
    display: flex;
    margin-top: 30px;
    min-height: 350px;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    border: 2px dashed rgba(40, 41, 50, 0.2);
    border-radius: 10px;
    justify-content: space-between;
    &:hover {
      border: 2px dashed ${Colors.secondary};
    }
    .box {
      display: flex;
      .left {
        h5 {
          font-size: 18px;
          margin-left: 20px;
          margin-top: 20px;
        }
        ul {
          list-style: none;
          li {
            display: flex;
            font-size: 16px;
            gap: 10px;
            @media (max-width: 496px) {
              font-size: 14px;
            }
            @media (max-width: 320px) {
              font-size: 12px;
            }
          }
        }
      }
    }

    .btn {
      display: flex;
      justify-content: center;
      gap: 30px;
      margin-bottom: 30px;
      flex-wrap: wrap;
      .btnsection {
        display: flex;
        gap: 30px;
        flex-wrap: wrap;
        .mailbtn {
          background-color: ${Colors.secondary};
          width: 40px;
          color: white;
          border-radius: 4px;
          border: none;
          align-items: center;
          display: flex;
          justify-content: center;

          &:active {
            color: white;
            background-color: black;
          }
        }
        .orderbtn {
          background-color: ${Colors.secondary};
          width: 120px;
          align-items: center;
          height: 30px;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          border: none;

          font-size: 14px;
          &:active {
            color: white;
            background-color: black;
          }
          @media (max-width: 550px) {
            width: 100px;
          }
          @media (max-width: 320px) {
            font-size: 12px;
          }
        }
        @media (max-width: 320px) {
          gap: 20px;
        }
      }
      .small {
        display: flex;
        gap: 30px;
        flex-wrap: wrap;
        justify-content: center;
        .orderbtn1 {
          width: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          background-color: ${Colors.secondary};
          border-radius: 4px;
          border: none;
          font-size: 14px;
          color: white;
          &:active {
            color: white;
            background-color: black;
          }
          @media (max-width: 320px) {
            font-size: 12px;
            width: 104px;
          }
        }
        @media (max-width: 906px) {
          gap: 10px;
        }
      }
      @media (max-width: 906px) {
        gap: 10px;
      }
      @media (max-width: 496px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
      }
    }
    @media (max-width: 906px) {
      width: 500px;
    }
    @media (max-width: 638px) {
      width: 475px;
    }
    @media (max-width: 550px) {
      width: 420px;
    }
    @media (max-width: 496px) {
      width: 380px;
    }
    @media (max-width: 442px) {
      width: 322px;
    }
    @media (max-width: 400px) {
      width: 288px;
    }
  }
`;
export const Form2order = styled.div`
  .Box {
    width: 540px;
    display: flex;
    margin-top: 30px;
    min-height: 350px;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    border: 2px dashed rgba(40, 41, 50, 0.2);
    border-radius: 10px;
    justify-content: space-between;
    &:hover {
      border: 2px dashed ${Colors.secondary};
    }
    .box {
      display: flex;
      .left {
        h5 {
          font-size: 18px;
          margin-left: 20px;
          margin-top: 20px;
        }
        ul {
          list-style: none;
          li {
            display: flex;
            font-size: 16px;
            gap: 10px;
            @media (max-width: 496px) {
              font-size: 14px;
            }
            @media (max-width: 320px) {
              font-size: 12px;
            }
          }
        }
      }
    }

    .btn {
      display: flex;
      justify-content: center;
      gap: 30px;
      margin-bottom: 30px;
      flex-wrap: wrap;
      .btnsection {
        display: flex;
        gap: 30px;
        flex-wrap: wrap;
        justify-content: center;
        .mailbtn {
          background-color: ${Colors.secondary};
          width: 40px;
          border-radius: 4px;
          border: none;
          align-items: center;
          color: white;
          display: flex;
          justify-content: center;

          &:active {
            color: white;
            background-color: black;
          }
        }
        .orderbtn {
          background-color: ${Colors.secondary};
          width: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          border-radius: 4px;
          border: none;
          font-size: 14px;
          color: white;
          &:active {
            color: white;
            background-color: black;
          }
          @media (max-width: 550px) {
            width: 100px;
          }
          @media (max-width: 320px) {
            font-size: 12px;
          }
        }
        @media (max-width: 320px) {
          gap: 20px;
        }
      }
      .small {
        display: flex;
        gap: 30px;
        .orderbtn1 {
          width: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          background-color: ${Colors.secondary};
          border-radius: 4px;
          border: none;
          font-size: 14px;
          color: white;
          &:active {
            color: white;
            background-color: black;
          }
          @media (max-width: 320px) {
            font-size: 12px;
            width: 104px;
          }
        }
        @media (max-width: 906px) {
          gap: 10px;
        }
      }
      @media (max-width: 906px) {
        gap: 10px;
      }
      @media (max-width: 496px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
      }
    }
    @media (max-width: 906px) {
      width: 500px;
    }
    @media (max-width: 638px) {
      width: 475px;
    }
    @media (max-width: 550px) {
      width: 420px;
    }
    @media (max-width: 496px) {
      width: 380px;
    }
    @media (max-width: 442px) {
      width: 322px;
    }
    @media (max-width: 400px) {
      width: 288px;
    }
  }
`;

export const Form3order = styled.div`
  .Box {
    width: 540px;
    display: flex;
    margin-top: 30px;
    min-height: 350px;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    border: 2px dashed rgba(40, 41, 50, 0.2);
    border-radius: 10px;
    justify-content: space-between;
    &:hover {
      border: 2px dashed ${Colors.secondary};
    }
    .box {
      display: flex;
      .left {
        h5 {
          font-size: 18px;
          margin-left: 20px;
          margin-top: 20px;
        }
        ul {
          list-style: none;
          li {
            display: flex;
            font-size: 16px;
            gap: 10px;
            @media (max-width: 496px) {
              font-size: 14px;
            }
            @media (max-width: 320px) {
              font-size: 12px;
            }
          }
        }
      }
    }

    .btn {
      display: flex;
      justify-content: center;
      gap: 30px;
      margin-bottom: 30px;
      flex-wrap: wrap;
      .btnsection {
        display: flex;
        gap: 30px;

        .mailbtn {
          color: white;
          background-color: ${Colors.secondary};
          width: 40px;
          border-radius: 4px;
          border: none;
          align-items: center;
          display: flex;
          justify-content: center;

          &:active {
            color: white;
            background-color: black;
          }
        }
        .orderbtn {
          background-color: ${Colors.secondary};
          width: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          border-radius: 4px;
          border: none;
          font-size: 14px;
          &:active {
            color: white;
            background-color: black;
          }
          @media (max-width: 550px) {
            width: 100px;
          }
          @media (max-width: 320px) {
            font-size: 12px;
          }
        }
        @media (max-width: 320px) {
          gap: 20px;
        }
      }
      .small {
        display: flex;
        gap: 30px;
        .orderbtn1 {
          width: 118px;
          background-color: ${Colors.secondary};
          border-radius: 4px;
          border: none;
          font-size: 14px;
          color: white;
          &:active {
            color: white;
            background-color: black;
          }
          @media (max-width: 320px) {
            font-size: 12px;
            width: 104px;
          }
        }
        @media (max-width: 906px) {
          gap: 10px;
        }
      }
      @media (max-width: 906px) {
        gap: 10px;
      }
      @media (max-width: 496px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
      }
    }
    @media (max-width: 906px) {
      width: 500px;
    }
    @media (max-width: 638px) {
      width: 475px;
    }
    @media (max-width: 550px) {
      width: 420px;
    }
    @media (max-width: 496px) {
      width: 380px;
    }
    @media (max-width: 442px) {
      width: 322px;
    }
    @media (max-width: 400px) {
      width: 288px;
    }
  }
`;
export const Form4order = styled.div`
  .Box {
    width: 540px;
    display: flex;
    margin-top: 30px;
    min-height: 350px;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    border: 2px dashed rgba(40, 41, 50, 0.2);
    border-radius: 10px;
    justify-content: space-between;
    &:hover {
      border: 2px dashed ${Colors.secondary};
    }
    .box {
      display: flex;
      .left {
        h5 {
          font-size: 18px;
          margin-left: 20px;
          margin-top: 20px;
        }
        ul {
          list-style: none;
          li {
            display: flex;
            font-size: 16px;
            gap: 10px;
            @media (max-width: 496px) {
              font-size: 14px;
            }
            @media (max-width: 320px) {
              font-size: 12px;
            }
          }
        }
      }
    }

    .btn {
      display: flex;
      justify-content: center;
      gap: 30px;
      margin-bottom: 30px;
      flex-wrap: wrap;
      .btnsection {
        display: flex;
        gap: 30px;

        .mailbtn {
          background-color: ${Colors.secondary};
          width: 40px;
          border-radius: 4px;
          border: none;
          align-items: center;
          display: flex;
          justify-content: center;

          &:active {
            color: white;
            background-color: black;
          }
        }
        .orderbtn {
          background-color: ${Colors.secondary};
          width: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          border-radius: 4px;
          border: none;
          font-size: 14px;
          height: 22px;
          color: white;
          &:active {
            color: white;
            background-color: black;
          }
          @media (max-width: 550px) {
            width: 100px;
          }
          @media (max-width: 320px) {
            font-size: 12px;
          }
        }
        @media (max-width: 320px) {
          gap: 20px;
        }
      }
      .small {
        display: flex;
        gap: 30px;
        flex-wrap: wrap;
        justify-content: center;
        .orderbtn1 {
          width: 118px;
          background-color: ${Colors.secondary};
          border-radius: 4px;
          border: none;
          font-size: 14px;
          color: white;
          &:active {
            color: white;
            background-color: black;
          }
          @media (max-width: 496px) {
            height: 25px;
          }
          @media (max-width: 320px) {
            font-size: 12px;
            width: 104px;
          }
        }
        @media (max-width: 906px) {
          gap: 10px;
        }
      }
      @media (max-width: 906px) {
        gap: 10px;
      }
      @media (max-width: 496px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
      }
    }
    @media (max-width: 906px) {
      width: 500px;
    }
    @media (max-width: 638px) {
      width: 475px;
    }
    @media (max-width: 550px) {
      width: 420px;
    }
    @media (max-width: 496px) {
      width: 380px;
    }
    @media (max-width: 442px) {
      width: 322px;
    }
    @media (max-width: 400px) {
      width: 288px;
    }
  }
`;
export const Form5order = styled.div`
  .Box {
    width: 540px;
    display: flex;
    margin-top: 30px;
    min-height: 350px;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    border: 2px dashed rgba(40, 41, 50, 0.2);
    border-radius: 10px;
    justify-content: space-between;
    &:hover {
      border: 2px dashed ${Colors.secondary};
    }
    .box {
      display: flex;
      .left {
        h5 {
          font-size: 18px;
          margin-left: 20px;
          margin-top: 20px;
        }
        ul {
          list-style: none;
          li {
            display: flex;
            font-size: 16px;
            gap: 10px;
          }
        }
      }
    }
    .btn {
      display: flex;
      justify-content: center;
      gap: 30px;
      margin-bottom: 30px;
      flex-wrap: wrap;
      .btnsection {
        display: flex;
        gap: 30px;

        .mailbtn {
          color: white;
          background-color: ${Colors.secondary};
          width: 40px;
          border-radius: 4px;
          border: none;
          &:active {
            color: white;
            background-color: black;
          }
        }
        .orderbtn {
          background-color: ${Colors.secondary};
          width: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          border-radius: 4px;
          border: none;
          font-size: 14px;

          color: white;
          &:active {
            color: white;
            background-color: black;
          }
          @media (max-width: 550px) {
            width: 100px;
          }
        }
      }
      .small {
        display: flex;
        gap: 30px;
        flex-wrap: wrap;
        .orderbtn1 {
          color: white;
          width: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          background-color: ${Colors.secondary};
          border-radius: 4px;
          border: none;
          font-size: 14px;
          &:active {
            color: white;
            background-color: black;
          }
        }
        @media (max-width: 906px) {
          gap: 10px;
        }
      }
      @media (max-width: 906px) {
        gap: 10px;
      }
    }
    @media (max-width: 906px) {
      width: 500px;
    }
    @media (max-width: 638px) {
      width: 475px;
    }
    @media (max-width: 550px) {
      width: 420px;
    }
  }
`;
export const Form6order = styled.div`
  .Box {
    width: 540px;
    display: flex;
    margin-top: 30px;
    min-height: 350px;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    border: 2px dashed rgba(40, 41, 50, 0.2);
    border-radius: 10px;
    justify-content: space-between;
    &:hover {
      border: 2px dashed ${Colors.secondary};
    }
    .box {
      display: flex;
      .left {
        h5 {
          font-size: 18px;
          margin-left: 20px;
          margin-top: 20px;
        }
        ul {
          list-style: none;
          li {
            display: flex;
            font-size: 16px;
            gap: 10px;
          }
        }
      }
    }
    .btn {
      display: flex;
      justify-content: center;
      gap: 30px;
      margin-bottom: 30px;
      flex-wrap: wrap;
      .btnsection {
        display: flex;
        gap: 30px;

        .mailbtn {
          color: white;
          background-color: ${Colors.secondary};
          width: 40px;
          border-radius: 4px;
          border: none;
          &:active {
            color: white;
            background-color: black;
          }
        }
        .orderbtn {
          background-color: ${Colors.secondary};
          width: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          border-radius: 4px;
          border: none;
          font-size: 14px;
          color: white;
          &:active {
            color: white;
            background-color: black;
          }
          @media (max-width: 550px) {
            width: 100px;
          }
        }
      }
      .small {
        display: flex;
        gap: 30px;
        flex-wrap: wrap;
        .orderbtn1 {
          width: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          background-color: ${Colors.secondary};
          border-radius: 4px;
          border: none;
          font-size: 14px;
          color: white;
          &:active {
            color: white;
            background-color: black;
          }
        }
        @media (max-width: 906px) {
          gap: 10px;
        }
      }
      @media (max-width: 906px) {
        gap: 10px;
      }
    }
    @media (max-width: 906px) {
      width: 500px;
    }
    @media (max-width: 638px) {
      width: 475px;
    }
    @media (max-width: 550px) {
      width: 420px;
    }
  }
`;
export const Formrejectorder = styled.div`
  .Box {
    width: 540px;
    display: flex;
    margin-top: 30px;
    min-height: 350px;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    border: 2px dashed rgba(40, 41, 50, 0.2);
    border-radius: 10px;
    justify-content: space-between;
    &:hover {
      border: 2px dashed ${Colors.secondary};
    }
    .box {
      display: flex;
      .left {
        h5 {
          font-size: 18px;
          margin-left: 20px;
          margin-top: 20px;
        }
        ul {
          list-style: none;
          li {
            display: flex;
            font-size: 16px;
            gap: 10px;
            @media (max-width: 496px) {
              font-size: 14px;
            }
            @media (max-width: 320px) {
              font-size: 12px;
            }
          }
        }
      }
    }

    .btn {
      display: flex;
      justify-content: center;
      gap: 30px;
      margin-bottom: 30px;
      flex-wrap: wrap;
      .btnsection {
        display: flex;
        gap: 30px;
        flex-wrap: wrap;
        justify-content: center;
        .mailbtn {
          background-color: ${Colors.secondary};
          width: 40px;
          border-radius: 4px;
          border: none;
          align-items: center;
          color: white;
          display: flex;
          justify-content: center;

          &:active {
            color: white;
            background-color: black;
          }
        }
        .orderbtn {
          background-color: ${Colors.secondary};
          width: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          border-radius: 4px;
          border: none;
          font-size: 14px;
          color: white;
          &:active {
            color: white;
            background-color: black;
          }
          @media (max-width: 550px) {
            width: 100px;
          }
          @media (max-width: 320px) {
            font-size: 12px;
          }
        }
        @media (max-width: 320px) {
          gap: 20px;
        }
      }
      .small {
        display: flex;
        gap: 30px;
        .orderbtn1 {
          width: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          background-color: ${Colors.secondary};
          border-radius: 4px;
          border: none;
          font-size: 14px;
          color: white;
          &:active {
            color: white;
            background-color: black;
          }
          @media (max-width: 320px) {
            font-size: 12px;
            width: 104px;
          }
        }
        @media (max-width: 906px) {
          gap: 10px;
        }
      }
      @media (max-width: 906px) {
        gap: 10px;
      }
      @media (max-width: 496px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
      }
    }
    @media (max-width: 906px) {
      width: 500px;
    }
    @media (max-width: 638px) {
      width: 475px;
    }
    @media (max-width: 550px) {
      width: 420px;
    }
    @media (max-width: 496px) {
      width: 380px;
    }
    @media (max-width: 442px) {
      width: 322px;
    }
    @media (max-width: 400px) {
      width: 288px;
    }
  }
`;
