import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Foodbuoy from "../widgets/Foodbuoygif";
import { useHistory } from "react-router";
import { notification } from "antd";
import { Getmyfcm } from "../../FCM/store";
import { firebase } from "../../../common/Firebase/index";
import goodmorning from '../../../Images/goodmorning.mpga'
import goodafternoon from '../../../Images/goodafternoon.mpga';
import goodevening from '../../../Images/good evening.mp3'
import { Popups } from "../../Groceries/Popup/style";
function Landing() {
  // const [loader, setLoader] = useState(false);
  const [token, setToken] = useState("");
  localStorage.setItem("token", token);
  const [
    { phonenumber, fcmtoken },
    { setfcmtoken, setPhone_Number, posttokens },
  ] = Getmyfcm();

  let fcm = localStorage.getItem("fcm_token");
  setfcmtoken(fcm);
  console.log(token, "fcmnum");
  // let History = useHistory();

  let time = new Date().getHours();
  let greet = "";
  let msg = "";
  if ((time >= 0) & (time < 12)) {
    greet = "Hey,Good Morning !";
    msg = (
      <iframe
        width="0"
        height="0"
        src={goodmorning}
        frameborder="0"
        allowfullscreen
      />
    );
  } else if ((time >= 12) & (time <= 17)) {
    greet = "Hey,Good Afternoon !";
    msg = (
      <iframe
        width="0"
        height="0"
        src={goodafternoon}
        frameborder="0"
        allowfullscreen
      />
    );
  } else {
    greet = "Hey,Good Evening !";
    msg = (
      <iframe
        width="0"
        height="0"
        src={goodevening}
        frameborder="0"
        allowfullscreen
      />
    );
  }
  const [loader, setLoader] = useState(false);
  let History = useHistory();
  const loading = () => {
    setTimeout(() => {
      History.push("/login");
    }, 3000);
  };

  let user = firebase.auth().currentUser;

  useEffect(() => {
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }

      loading();
      // posttokens();
    });
  }, []);

  return (
    <Wish
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <Foodbuoy /> */}
      <div className="body">
        <h2>
          <span>
            {greet}
            {msg}{" "}
          </span>
        </h2>
      </div>
     
    </Wish>
  );
}

export default Landing;

export const Wish = styled.div`
.body{
  display:flex;
  justify-content:center;
  align-items:center;
  width:100%;
  min-height:100vh;
  background:black;
}
h2{
  color:#000;
  font-size:3em;
  display:flex;
  @media(max-width:450px){
    font-size:2em;
  }
}
h2 span {
  animation:animate 4s linear infinite;
}
h2 span :nth-child(1){
  animation-delay:0s;
}
h2 span :nth-child(2){
  animation-delay:0.1s;
}
h2 span :nth-child(3){
  animation-delay:0.2s;
}
h2 span :nth-child(4){
  animation-delay:0.3s;
}
h2 span :nth-child(5){
  animation-delay:0.4s;
}
h2 span :nth-child(6){
  animation-delay:0.5s;
}
h2 span :nth-child(7){
  animation-delay:0.6s;
}
h2 span :nth-child(8){
  animation-delay:0.8s;
}
@keyframes animate{
  0%,100%
  {
    color:#fff;
    filter:blur(2px);
    text-shadow:0 0 10px #00b3ff;
                0 0 20px #00b3ff;
                0 0 30px #00b3ff;
                0 0 40px #00b3ff;
                0 0 80px #00b3ff;
                0 0 120px #00b3ff;
                0 0 200px #00b3ff;
                0 0 300px #00b3ff;
                0 0 400px #00b3ff;
  }
  25%,50%
  {
    color:#000;
    filter:blur(0px);
    text-shadow:none;
  }
}

`
