import { popupdata } from "../../../../infrastructure/Groceries/Popup";

const actions = {

    setisLoading:
      (isLoading) =>
      ({ setState }) => {
        setState({ isLoading });
      },
      setgroceries:
      (groceries) =>
      ({ setState }) => {
        setState({ groceries });
      },
      getgroceries:
      () =>
      async ({ setState, dispatch, getState }) => {
        dispatch(actions.setisLoading(true));
        try {
          let res = await popupdata();
      
          setState({groceries:res.data.data})
          dispatch(actions.setisLoading(false));
        } catch (err) {
        } finally {
          dispatch(actions.setisLoading(false));
        }
      },
    }
    export default actions;