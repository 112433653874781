import styled from "styled-components";
export const Star = styled.div`

.page{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    background-color:#f5f5f5;
    padding-bottom:50px;
}
.arrow{
    margin-top:30px;
    margin-left:30px;
}
 .main{
     display:flex;
     justify-content:center;
     flex-direction:column;
     align-items:center;
     width:50%;
     margin-top:50px;
    .heading{
        display:flex;
        width:100%;
    h1{
        font-family: "Quicksand", sans-serif;
        font-weight:bold;
        font-size:26px;
    }
   }
     .box1{
         height:60px;
         display:flex;
         justify-content:space-between;
         border:1px solid #f5f5f5;
         border-radius:8px;
         width:100%;

         margin-top:18px;
         align-items:center;
         background-color:white;
         @media(max-width:980px){
             height:unset;
         }
         h1{
             font-size:16px;
             width:205px;
             display:flex;
             align-items:center;
             padding:10px;
         }
      
     }
     .buttons{
        &:active{
            background-color: black;
            color: white;
          }
     }
     .comment{
         display:flex;
         flex-direction:column;
          padding-top:10px;
          margin-bottom:50px;
          width:100%;
          h1{ 
              font-size:18px;
          }
         textarea{
            font-family: "dm_sansregular";
            background-color: white;
            height: 100px;
            border: solid;
            border-radius: 15px;
            padding-left: 20px;
            font-size: 18px;
            outline: none;
            letter-spacing:1.5px;
            border-color: white;
         }

     }
    
 }
`