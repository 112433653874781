import React, { useEffect, useState } from "react";
import BackArrow from "../BackArrow";
import { ViewShop, MiddleContainer, Social } from "./style";
import ReactImageMagnify from "react-image-magnify";
import { Image } from "antd";
import {
  StarOutlined,
  GlobalOutlined,
  FacebookOutlined,
  InstagramOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import { GetMoredetails } from "./store";
import Moment from "react-moment";
function Shopinformation({ match }) {
  const [{ isLoading, views, shoplists, licences, Images }, { getviews }] =
    GetMoredetails();
  const [width, setWidth] = React.useState(window.innerWidth);
  const [idd, setidd] = useState(match.params.id);

  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
  };
  React.useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);

    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });


  let website = views?.website_link;
  let data = "https://";
  let web = `${data}${website}`;

  useEffect(() => {
    getviews(idd);
  }, [idd]);
  let dummyrating = 0.0;
  return (
    <ViewShop>
      <div className="banner">
        <div className="backarrow">
          <BackArrow />
        </div>
        <div className="contentmain">
          <div className="myimage">
            <div className="content">
              <h1>{views?.name}</h1>
              <p>{views?.description}</p>
              <div className="separate">
                <small>
                  {views?.ratings}
                  <StarOutlined />
                </small>
              </div>
            </div>
            <Image
              width={width > 540 ? 300 : 200}
              height={width > 540 ? 300 : 200}
              src={views?.image}
            />
            {/* <img src={views?.image} alt="shop view"></img> */}
          </div>
        </div>
      </div>

      <MiddleContainer>
        <div className="wrapper">
          <div className="top">
            <h1>More Information</h1>
            <small></small>
          </div>
        </div>

        <div className="list">
          <div className="group">
            <h1>Address</h1>
            <small>:</small>
            <p>{views?.address}</p>
          </div>
          <div className="group">
            <h1>LandMark</h1>
            <small>:</small>
            <p>{views?.landmark}</p>
          </div>
          <div className="group">
            <h1>Pincode</h1>
            <small>:</small>
            <p>{views?.pincode}</p>
          </div>
          <div className="group">
            <h1>Landline</h1>
            <small>:</small>
            <p>{views?.landline}</p>
          </div>
          <div className="group">
            <h1>Phone Num</h1>
            <small>:</small>
            <p>{views?.phone}</p>
          </div>
          <div className="group">
            <h1>Email</h1>
            <small>:</small>
            <p>{views?.email}</p>
          </div>
          <div className="group">
            <h1 style={{ color: "green" }}>Opens at</h1>
            <small>:</small>
            <p>{views?.opens_at}</p>
          </div>
          <div className="group">
            <h1 style={{ color: "red" }}>Close at</h1>
            <small>:</small>
            <p>{views?.close_at}</p>
          </div>
          <div className="group">
            <h1>Service Radius</h1>
            <small>:</small>
            <p>{views?.service_radius}</p>
          </div>
          <div className="group">
            <h1>Fssai Number</h1>
            <small>:</small>
            <p>{views?.fssai_number}</p>
          </div>
        </div>
      </MiddleContainer>

      <Social>
        <div className="wrapper">
          <div className="top">
            <h1>Shop Images</h1>
            <small></small>
          </div>
        </div>

        <div className="MainHeading">
          <div className="header">
            <Image.PreviewGroup>
              {views?.images.map((res) => (
                <Image width={200} height={200} src={res.file} />
              ))}
            </Image.PreviewGroup>
          </div>
        </div>
      </Social>
    </ViewShop>
  );
}

export default Shopinformation;
