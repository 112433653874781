const initialState = {
  isLoading: true,
  rating: "",
  hospitality: "",
  taste: "",
  price: "",
  quality: "",
  quantity: "",
  packing: "",
  timing: "",
  wastemanagement: "",
  comment: "",
  order_id:"",
};

export default initialState;
