import { Login, OTP } from "../../../infrastructure/Login";
import { notification } from "antd";
import { firebase } from "../../../common/Firebase/index";
const actions = {
  setNumber:
    (number) =>
    ({ setState }) => {
      setState({ number });
    },
  setOtp:
    (otp) =>
    ({ setState }) => {
      setState({ otp });
    },
  setsignres:
    (signres) =>
    ({ setState }) => {
      setState({ signres });
    },
  settoken:
    (token) =>
    ({ setState }) => {
      setState({ token });
    },
  setLoading:
    (isloading) =>
    ({ setState }) => {
      setState({ isloading });
    },
  setdefaultnum:
    (defaultnum) =>
    ({ setState }) => {
      setState({ defaultnum });
    },

  onSubmit:
    () =>
    async ({ setState, dispatch, getState }) => {
      dispatch(actions.setLoading(true));

      try {
        const { number } = getState();
        let temporary = "+91";
        let main = temporary + number;
        let int = parseInt(main);

        if (number.length < 10) {
          notification["warning"]({
            message: "warning",
            description: "Mobile number is not valid (+91 required)",
          });
          return false;
        } else {
          const signres = await Login(number);
          dispatch(actions.setsignres(signres));
        }
      } catch (err) {
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
  sentVarify:
    (history) =>
    async ({ setState, dispatch, getState }) => {
      dispatch(actions.setLoading(true));
      try {
        const { otp, signres } = getState();
        if (otp === "") {
          notification["warning"]({
            message: "warning",
            description: "OTP feild is empty ",
          });
          return false;
        } else {
          await OTP(otp, signres);
          const user = firebase.auth().currentUser;
          const mytoken = user && (await user.getIdToken());
 
          localStorage.setItem("token", mytoken);
        }
        notification["success"]({
          message: "success",
          description: "Welcome To Foodbuoy!...",
        });
     
      } catch (err) {
        notification["warning"]({
          message: "warning",
          description: "Invalid OTP Varification failed",
        });
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
};
export default actions;
