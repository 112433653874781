const initialState = {
isLoading: true,
order: [],
paymentsitem:"",
razerpaykey:"",
razorpay_order_id:"",
razorpay_payment_id:"",
 finalpayment:"",
 order_id:""


}
export default initialState;