import firebase from "firebase";
import "firebase/messaging";
// Scripts for firebase and firebase messaging

// const firebaseConfig = {
//   apiKey: "AIzaSyCsEmocox46B8QWITQR0oLcfR6qjTeIBrM",
//   authDomain: "food-buoy-app.firebaseapp.com",
//   projectId: "food-buoy-app",
//   storageBucket: "food-buoy-app.appspot.com",
//   messagingSenderId: "366360623729",
//   appId: "1:366360623729:web:d77b7abb44a27ae414f9d0",
//   measurementId: "G-PWNZCFR3W1",
// };
const firebaseConfig = {
  apiKey: "AIzaSyCsEmocox46B8QWITQR0oLcfR6qjTeIBrM",

  authDomain: "food-buoy-app.firebaseapp.com",

  projectId: "food-buoy-app",

  storageBucket: "food-buoy-app.appspot.com",

  messagingSenderId: "366360623729",

  appId: "1:366360623729:web:d77b7abb44a27ae414f9d0",

  measurementId: "G-PWNZCFR3W1",
};

firebase.initializeApp(firebaseConfig);
var auth = firebase.auth();

export { auth, firebase };

const messaging =  firebase.messaging.isSupported()
  ? firebase.messaging()
  : null;

const { REACT_APP_VAPID_KEY } ="BJulsNPObFUqyPi9YH19KrT5RUtVpbXezX9IPyAsIyy23qdH0ePwLjahGmJFbqrheWD7rJBJcSMdoXZTkdoHls0";
const publicKey = REACT_APP_VAPID_KEY;

export const getToken = async (setTokenFound) => {
  let currentToken = "";

  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });

    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
