import styled from "styled-components";
export const Carts = styled.div`
  .order {
    margin-bottom: 40px;
    display: flex;
    gap: 80px;
    .back {
      margin-left: 30px;
    }
    h1 {
      font-family: "dm_sansregular";
      font-size: 28px;
      font-weight: bold;
    }
  }
  .main {
    display: flex;
    justify-content: center;
    margin-bottom: 100px;

    .first {
      display: flex;
      justify-content: space-between;
      width: 80%;
      margin-top: 20px;
      margin-bottom: 20px;
      gap: 20px;
      .left {
        padding: 5px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        width: 600px;
        height: 650px;

        .order1 {
          display: flex;
          padding: 3px;
          justify-content: center;
          border: 1px solid #ececec;
          background-color: #006fb1;
          height: 60px;
          align-items: center;
          margin: 0px;
          h1 {
            color: white;
            margin: 0px;
            padding: 0px;
            font-family: "montserratregular";
            font-size: 22px;
            font-weight: bold;
            @media (max-width: 1128px) {
              font-size: 18px;
            }
            @media (max-width: 926px) {
              font-size: 18px;
            }
            @media (max-width: 676px) {
              font-size: 16px;
            }
            @media (max-width: 470px) {
              font-size: 14px;
            }
          }
          @media (max-width: 1128px) {
            height: 55px;
          }
          @media (max-width: 926px) {
            height: 55px;
          }
          @media (max-width: 926px) {
            height: 50px;
          }
          @media (max-width: 470px) {
            height: 40px;
          }
          @media (max-width: 320px) {
            height: 40px;
          }
        }
        .btn {
          margin-top: 50px;
          background-color: #006fb1;
          width: 200px;
          display: flex;
          align-items: center;
          &:active {
            background-color: black;
            color: white;
          }

          button {
            font-family: "montserratregular";
            background-color: #006fb1;
            padding: 5px;
            width: 200px;
            border: none;
            color: black;
            height: 35px;
            border-radius: 4px;
            font-weight: 400;
            color: white;
            text-align: center;
            cursor: pointer;
            border: none;
            font-size: 18px;
            &:active {
              background-color: black;
              color: white;
            }
            @media (max-width: 662px) {
              height: 28px;
              font-size: 16px;
            }
            @media (max-width: 604px) {
              height: 23px;
              font-size: 14px;
            }
            @media (max-width: 596px) {
              font-size: 14px;
            }
          }

          @media (max-width: 662px) {
            width: 175px;
            margin-top: 30px;
          }
          @media (max-width: 596px) {
            width: ;
          }
        }
        .products {
          min-height: 170px;
          max-height: 250px;
          width: 100%;
          overflow-y: scroll;
          -ms-overflow-style: none;
          scrollbar-width: none;
          display: flex;
          flex-direction: column;

          ul {
            list-style: none;
            display: flex;
            gap: 30px;
            height: 140px;
            align-items: center;
            padding: 10px;
            border-bottom: 1px solid #ececec;
            @media (max-width: 1300px) {
              gap: 11px;
            }

            li {
              img {
                width: 100px;
                border-radius: 6px;
                @media (max-width: 470px) {
                  width: 90px;
                }
              }

              h1 {
                padding: 0px;
                font-family: "montserratregular";
                margin: 0px;
                font-size: 14px;
                width: 120px;
                @media (max-width: 1128px) {
                  width: 97px;
                }
                @media (max-width: 980px) {
                  width: 120px;
                }
                @media (max-width: 554px) {
                  width: 95px;
                }
                @media (max-width: 470px) {
                  font-size: 14px;
                  text-align: center;
                }
              }
            }
            @media (max-width: 470px) {
              flex-direction: column;
              height: 169px;
            }
          }
          @media (max-width: 650px) {
            height: 206px;
          }
        }
        .itemsdetails {
          .service {
            padding: 3px;
            border: 1px solid #ececec;
            display: flex;
            flex-direction: column;
            .itm {
              width: 500px;
              display: flex;
              justify-content: space-between;
              h1 {
                margin: 0px;
                padding: 0px;
                font-family: "montserratregular";
                font-size: 16px;
                @media (max-width: 1300px) {
                  font-size: 14px;
                }
                @media (max-width: 650px) {
                  font-size: 14px;
                }
              }
              small {
                font-size: 16px;
                font-weight: bold;
                @media (max-width: 65px) {
                  font-size: 14px;
                }
              }
              @media (max-width: 1500px) {
                width: 467px;
              }
              @media (max-width: 1418px) {
                width: 460px;
              }
              @media (max-width: 1300px) {
                width: 397px;
              }
              @media (max-width: 1128px) {
                width: 350px;
              }
              @media (max-width: 980px) {
                width: 413px;
              }
              @media (max-width: 596px) {
                width: 450px;
              }
              @media (max-width: 604px) {
                width: 380px;
              }
              @media (max-width: 554px) {
                width: 350px;
              }
              @media (max-width: 470px) {
                width: 277px;
              }
              @media (max-width: 400px) {
                width: 234px;
              }
            }
          }
        }
        .Advanced {
          .serv {
            padding: 3px;
            border: 1px solid #ececec;
            .itm {
              width: 500px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: 50px;
              @media (max-width: 1445px) {
                width: 460px;
              }
              @media (max-width: 1300px) {
                width: 407px;
              }
              @media (max-width: 1128px) {
                width: 366px;
              }
              @media (max-width: 980px) {
                width: 427px;
              }

              h1 {
                margin: 0px;
                padding: 0px;
                font-family: "montserratregular";
                font-size: 16px;
                @media (max-width: 1350px) {
                  font-size: 14px;
                }
                @media (max-width: 650px) {
                  font-size: 14px;
                }
              }
              small {
                font-size: 16px;
                font-weight: bold;
                @media (max-width: 650px) {
                  font-size: 14px;
                }
              }
              .delivery {
                span {
                  font-size: 16px;
                  color: grey;
                  cursor: pointer;
                  @media (max-width: 650px) {
                    font-size: 14px;
                  }
                  @media (max-width: 400px) {
                    font-size: 12px;
                  }
                }
              }

              @media (max-width: 662px) {
                width: 413px;
              }
              @media (max-width: 604px) {
                width: 400px;
              }
              @media (max-width: 596px) {
                width: 384px;
              }
              @media (max-width: 554px) {
                width: 360px;
              }
              @media (max-width: 470px) {
                width: 289px;
              }
              @media (max-width: 400px) {
                flex-direction: column;
              }
            }
            @media (max-width: 400px) {
              padding: 11px;
              display: flex;
              justify-content: center;
            }
          }
        }
        .second {
          .sec {
            padding: 3px;
            border: 1px solid #ececec;

            .itm {
              width: 500px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: 60px;
              h1 {
                font-family: "montserratregular";
                font-size: 16px;
                @media (max-width: 1350px) {
                  font-size: 14px;
                }
                @media (max-width: 1300px) {
                  font-size: 14px;
                }
                @media (max-width: 650px) {
                  font-size: 14px;
                }
              }
              small {
                font-size: 16px;
                font-weight: bold;
                @media (max-width: 744px) {
                  font-size: 14px;
                  @media (max-width: 650px) {
                    font-size: 14px;
                  }
                }
              }
              @media (max-width: 1500px) {
                width: 467px;
              }
              @media (max-width: 1418px) {
                width: 460px;
              }
              @media (max-width: 1300px) {
                width: 397px;
              }
              @media (max-width: 1128px) {
                width: 350px;
              }
              @media (max-width: 980px) {
                width: 413px;
              }

              @media (max-width: 604px) {
                width: 380px;
              }
              @media (max-width: 554px) {
                width: 350px;
              }
              @media (max-width: 470px) {
                width: 277px;
              }
              @media (max-width: 400px) {
                width: 234px;
              }
            }
          }
        }
        @media (max-width: 1500px) {
          width: 528px;
        }
        @media (max-width: 1418px) {
          width: 510px;
        }
        @media (max-width: 1350px) {
          width: 490px;
        }
        @media (max-width: 1300px) {
          width: 440px;
        }
        @media (max-width: 1180px) {
          width: 425px;
        }
        @media (max-width: 1128px) {
          width: 384px;
          height: 607px;
        }
        @media (max-width: 1026px) {
          width: 371px;
        }

        @media (max-width: 980px) {
          width: 480px;
          height: 600px;
        }

        @media (max-width: 662px) {
          height: 577px;
        }
        @media (max-width: 604px) {
          width: 425px;
        }
        @media (max-width: 554px) {
          width: 380px;
        }
        @media (max-width: 470px) {
          width: 306px;
        }
        @media (max-width: 400px) {
          width: 270px;
        }
      }

      .right {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        width: 600px;
        height: 650px;
        .shipping {
          display: flex;
          padding: 3px;
          justify-content: center;
          border: 1px solid #ececec;
          background-color: #006fb1;
          height: 60px;
          align-items: center;
          h1 {
            color: white;
            margin: 0px;
            padding: 0px;
            font-family: "montserratregular";
            font-size: 22px;
            font-weight: bold;
            @media (max-width: 1128px) {
              font-size: 18px;
            }
            @media (max-width: 470px) {
              font-size: 16px;
            }
            @media (max-width: 330px) {
              font-size: 14px;
            }
          }
          @media (max-width: 1128px) {
            height: 55px;
          }
          @media (max-width: 470px) {
            height: 50px;
          }
          @media (max-width: 330px) {
            height: 45px;
          }
        }
        .address {
          margin-top: 30px;
          .text {
            padding-left: 10px;
            padding-right: 10px;
            display: flex;
            justify-content: space-between;
            .itm {
              display: flex;
              flex-direction: column;
              label {
                font-size: 16px;
                @media (max-width: 470px) {
                  font-size: 14px;
                }
                @media (max-width: 320px) {
                  font-size: 13px;
                }
              }
              input {
                width: 285px;
                border-radius: 3.5px;
                padding: 1vh;
                font-size: 14px;
                border: 1px solid #e0e0e0;
                height: 6vh;
                box-shadow: inset 0 -1px 0 #006fb1;
                font-family: "montserratregular";
                font-size: 14px;

                @media (max-width: 1500px) {
                  width: 240px;
                }
                @media (max-width: 1350px) {
                  width: 214px;
                }
                @media (max-width: 1300px) {
                  width: 197px;
                }
                @media (max-width: 1128px) {
                  width: 169px;
                }
                @media (max-width: 980px) {
                  width: 216px;
                }
                @media (max-width: 604px) {
                  width: 193px;
                }
                @media (max-width: 554px) {
                  width: 169px;
                }
                @media (max-width: 470px) {
                  width: 100%;
                  font-size: 12px;
                }
              }
            }
            @media (max-width: 470px) {
              flex-direction: column;
              gap: 15px;
            }
          }
          @media (max-width: 470px) {
            margin-top: 10px;
          }
        }
        .event {
          margin-top: 20px;
          padding-bottom: 25px;
          .text {
            padding-left: 10px;
            button {
              font-family: "montserratregular";
              cursor: pointer;
              font-size: 14px;
              font-weight: bold;
              color: #0066ff;
              displaty: flex;
              background: transparent;
              @media (max-width: 320px) {
                font-size: 13px;
              }
            }
          }
        }
        .button {
          margin-top: 240px;
          justify-content: center;
          display: flex;
          padding: 5px;
        }
        button {
          background-color: #006fb1;
          font-family: "montserratregular";
          width: 100%;
          border: none;
          height: 35px;
          border-radius: 4px;
          font-weight: 400;
          color: #212529;
          text-align: center;
          cursor: pointer;
          border: none;
          &:active {
            background: black;
            color: white;
          }
        }
        @media (max-width: 1500px) {
          width: 528px;
        }
        @media (max-width: 1418px) {
          width: 510px;
        }
        @media (max-width: 1350px) {
          width: 490px;
        }
        @media (max-width: 1300px) {
          width: 440px;
        }
        @media (max-width: 1180px) {
          width: 425px;
        }
        @media (max-width: 1128px) {
          width: 384px;
          height: 607px;
        }
        @media (max-width: 1026px) {
          width: 371px;
        }
        @media (max-width: 980px) {
          width: 480px;
          height: 600px;
        }
        @media (max-width: 662px) {
          height: 577px;
        }
        @media (max-width: 604px) {
          width: 425px;
        }
        @media (max-width: 554px) {
          width: 380px;
        }
        @media (max-width: 470px) {
          width: 306px;
          height: 450px;
        }
        @media (max-width: 400px) {
          width: 270px;
        }
        @media (max-width: 330px) {
          height: 420px;
        }
      }

      @media (max-width: 980px) {
        flex-direction: column;
        gap: 30px;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;
