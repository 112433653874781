import styled from "styled-components";
import Colors from "../common/Colors/Colors";
export const Event = styled.div`
  .mybuttonback {
    padding-top: 30px;
    padding-left: 50px;
    @media (max-width: 540px) {
      padding-left: unset;
    }
  }
  .arrow {
    display: flex;
  }
  .main {
    display: flex;
    justify-content: center;

    .first {
      margin-bottom: 100px;
      width: 80%;
      display: flex;
      justify-content: space-around;
      .pic {
        display: flex;
        align-items: center;
        @media (max-width: 980px) {
          flex-direction: column;
          margin-top: 40px;
        }
        img {
          width: 600px;
          height: 600px;
          @media (max-width: 1338px) {
            width: 485px;
            height: 485px;
          }
          @media (max-width: 980px) {
            height: 500px;
          }
          @media (max-width: 678px) {
            height: 400px;
            width: 500px;
          }
          @media (max-width: 580px) {
            height: 300px;
            width: 400px;
          }
          @media (max-width: 420px) {
            height: 250px;
            width: 300px;
          }
          @media (max-width: 320px) {
            height: 200px;
            width: 200px;
          }
        }
      }
      .input {
        margin-top: 100px;
        width: 500px;
        padding: 20px;

        box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
          rgba(17, 17, 26, 0.1) 0px 0px 8px;

        .heading {
          h1 {
            font-family: "Quicksand", sans-serif;
            font-weight: bold;
            font-size: 27px;
            @media (max-width: 580px) {
              font-size: 20px;
            }
          }
        }
        .adrs {
          display: flex;
          flex-direction: column;
          gap: 20px;
          background: #fafafa;
          input {
            border-radius: 3.5px;
            padding: 1vh;
            font-size: 1.7vh;
            border: 1px solid #e0e0e0;
            height: 6vh;
            background: white;
            box-shadow: inset 0 -1px 0 ${Colors.secondary};
            font-family: "montserratregular";
            font-size: 14px;
          }

          .adrs2 {
            .inp2 {
              display: flex;
              gap: 20px;
              input {
                width: 300px;
                border-radius: 3.5px;
                padding: 1vh;
                font-size: 1.7vh;
                border: 1px solid #e0e0e0;
                height: 6vh;
                background: white;
                box-shadow: inset 0 -1px 0 ${Colors.secondary};
                font-family: "montserratregular";
                font-size: 14px;
                border-color: #e0e0e0;
                @media (max-width: 580px) {
                  width: 100%;
                }
              }
              input::placeholder {
                color: black;
              }

              input:textarea {
                color: white;
              }
              @media (max-width: 580px) {
                flex-direction: column;
              }
            }
          }
          .adrs3 {
            display: flex;
            flex-direction: column;
            gap: 20px;
            justify-content: space-between;
            @media (max-width: 580px) {
              flex-direction: column;
              width: 100%;
            }
          }
        }
        .btn {
          margin-top: 20px;
          button {
            font-size: 18px;
            font-family: "dm_sansregular";
            width: 200px;
            height: 50px;
            background-color: ${Colors.secondary};
            border: none;
            color: black;
            border-radius: 4px;
            cursor: pointer;
            color: white;

            &:active {
              background-color: black;
              color: white;
            }
            @media (max-width: 1500px) {
              width: 150px;
              height: 40px;
            }
            @media (max-width: 640px) {
              width: 150px;
              height: 37px;
            }
            @media (max-width: 440px) {
              width: 114px;
            }
          }
          @media (max-width: 600px) {
            width: 50px;
          }
          @media (max-width: 580px) {
            width: unset;
          }
        }
        @media (max-width: 980px) {
          margin-top: 50px;
        }
        @media (max-width: 678px) {
          margin-top: 45px;
        }
        @media (max-width: 580px) {
          width: 450px;
        }
        @media (max-width: 530px) {
          width: 350px;
        }
        @media (max-width: 420px) {
          width: 280px;
        }
      }
      @media (max-width: 980px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      @media (max-width: 678px) {
        justify-content: center;
        align-items: center;
      }
      @media (max-width: 644px) {
        width: 100%;
      }
      @media (max-width: 580px) {
        width: 100%;
      }
    }
  }
`;
