import React from "react";
import { Main } from "./style";
import playstore from "../../Images/playstore.png";
import appstore from "../../Images/Appstore.png";
import uparrow from '../../Images/uparrow.png'
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { BackTop } from "antd";
import {ArrowUpOutlined,UpOutlined } from '@ant-design/icons';
function Footer() {
  const location = useLocation();
  console.log(location, "location");

  // routing

  let History = useHistory();
  const AboutLink = () => {
    History.push("/about");
  };
  const ContactLink = () => {
    History.push("/contact");
  };
  const PrivacyLink = () => {
    History.push("/privacypolicy");
  };
  const Returnpolicy = () => {
    History.push("/returnPolicy");
  };
  const TermsAndConditions = () => {
    History.push("/TermsAndConditions");
  };
  ///returnPolicy
  const style = {
    height: 40,
    width: 40,
    // lineHeight: '40px',
    borderRadius: 8,
    // backgroundColor:'#fff',
    color: '#fff',
    // textAlign: 'center',
    fontSize: 14,
    marginLeft:6,
    display:'flex',
    justifyContent:'center',
    opacity:0.6,
 
 
  };
  
  return (
    <>
      {location.pathname === `/foodbuoy` ? (
        ""
      ) : (
        <Main>
         
          <div className="footer-body">
            <div className="items">
              <ul>
             
                <li className="default">COMPANY</li>
                <a href="#about" style={{ textDecoration: "none" }}>
                  <li onClick={() => AboutLink()} className="main">
                    About us
                  </li>
                </a>
                <a href="#contact" style={{ textDecoration: "none" }}>
                  <li onClick={() => ContactLink()} className="main">
                    Contact us
                  </li>
                </a>
              </ul>
            </div>
            <div className="items">
              <ul>
                <li className="default">CONTACT</li>

                <li className="main">
                  39/2701, 1st Floor Pallickel Building, (75) South janatha
                  road, Palarivattom,Ernakulam, Kochi, 682025, Kerala
                </li>
              </ul>
            </div>
            <div className="items">
              <ul>
                <li className="default">LEGAL</li>
                <a
                  href="#TermsAndConditions"
                  style={{ textDecoration: "none" }}
                >
                  <li className="main" onClick={() => TermsAndConditions()}>
                    Terms And Conditions
                  </li>
                </a>
                <a href="#ReturnPolicy" style={{ textDecoration: "none" }}>
                  <li className="main" onClick={() => Returnpolicy()}>
                    Refund And Cancellation
                  </li>
                </a>
                <a href="#privacypolicy" style={{ textDecoration: "none" }}>
                  <li onClick={() => PrivacyLink()} className="main">
                    Privacy Policy
                  </li>
                </a>
              </ul>
            </div>
            {location.pathname === `/` ? (
              ""
            ) : (
              <div className="app-link">
                <a
                  href="https://play.google.com/store/apps/details?id=quadauq.foodbuoy.food_buoy_user"
                  target="blank"
                >
                  <img className="pic" src={playstore} alt="" />
                </a>
                <a href="#">
                  <img className="pic1" src={appstore} alt="" />
                </a>
              </div>
            )}
          </div>
       
        </Main>
      )}
        <BackTop style={{position:'bottom-right'}}>
            {/* <div style={style}><ArrowUpOutlined  style={{fontWeight:'bold',fontSize:'250%',marginTop:'8px'}} /></div> */}
            <div className='up' style={style}>
               <img src={uparrow} alt=''/>
            </div>
           
          </BackTop>
    </>
  );
}

export default Footer;
