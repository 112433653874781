const initialState = {
  restaurant: [],
  cheif: [],
  caters: [],
  shopall: [],
  isLoading: true,
  iserrormodal:false,
  reserror:null,
  search:"",
};

export default initialState;
