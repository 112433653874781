import styled from "styled-components";
export const DIV = styled.div`
  background:#f0e6e642;;

  .citem{
    img{
      width:100%;
      height:95vh;
      @media(max-width:640px){
        height:70vh;
      }
      @media(max-width:540px){
        height:53vh;
      }
    }
  }
  .videodiv {
    width: 100%;
    display: flex;
    justify-content: center;
    .wrapperdiv {
      overflow: hidden;

      video {
      }
    }
  }

  .main {
    @media (max-width: 640px) {
      padding-bottom: unset;
    }
  }
  .color {
    .search {
      display: flex;
      justify-content: center;
      margin-top: 50px;

      input {
        font-family: "dm_sansregular";
        background-color: white;
        height: 80px;
        border: solid;
        border-radius: 15px;
        width: 60%;
        padding-left: 15px;
        font-size: 18px;
        outline: none;
        letter-spacing: 1.5px;
        border-color: white;
        @media (max-width: 540px) {
          min-width: 80%;
          height: 60px;
        }
      }
      input::placeholder {
        color: black;
      }

      input:textarea {
        color: white;
      }
    }
    .category {
      // height: 850px;
    }
  }
  .uparrow {
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    padding-bottom: 30px;
  
    @media (max-width: 980px) {
      margin-right: 55px;
    }
    @media (max-width: 800px) {
      margin-right: 50px;
    }
    @media (max-width: 540px) {
      margin-right: 45px;
    }
  }
`;
