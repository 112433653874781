const initialState = {
  customer: [],
  isLoading: "",
  rerender: false,
  event_name: "",
  name: "",
  delivery_address: "",
  pincode: "",
  landmark: "",
  latitude: "10.8888",
  longitude: "10.5588",
  delivery_date: "",
  delivery_time: "",
  caretaker_name: "",
  caretaker_contact: "",
  alternate_contact: "",
  geo_location: "geolocation",
};

export default initialState;
