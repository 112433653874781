import React, { useState, useEffect } from "react";
import { Search } from "./style";
import { StarOutlined } from "@ant-design/icons";
import { ShopsearchType } from "./store";
import { useHistory } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { firebase } from "../../common/Firebase/index";
import AOS from "aos";
import "aos/dist/aos.css";
function Shopsearch() {
  AOS.init();
  let history = useHistory();
  const [{ search, shopdata }, { setSearch, Postrequestsearch }] =
    ShopsearchType();

  const Productlink = (id) => {
    history.push(`/products/${id}`);
  };
  const Homelink = () => {
    history.push(`/home`);
  };

  const Mysearchpostreq = (value) => {
    setSearch(value);
    setTimeout(() => {
      Postrequestsearch();
    }, 1000);
  };
  const [token, setToken] = useState("");

  useEffect(() => {
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
      if (token === "") {
      } else {
        Postrequestsearch();
      }
    });
  }, [token]);

  return (
    <Search>
      <div className="arrow">
        <button onClick={() => Homelink()}>
          <ArrowLeftOutlined style={{ fontSize: 22 }} />
        </button>
      </div>
      <div className="background">
        <div className="wrapper">
          <div className="searchbar">
            <input
              type="text"
              placeholder="search...."
              value={search}
              onChange={(e) => Mysearchpostreq(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="heading">
        <div className="wrapper2">
          <div className="categories">
            <h1>Menu Results</h1>
          </div>
          <div className="border"></div>
          <div className="item">
            {shopdata?.menu_results?.map((res) => (
              <div data-aos="zoom-in" className="zoom">
                <div
                  className="prods"
                  onClick={() => Productlink(res.vendor_id)}
                >
                  <div className="image">
                    <img alt="img" src={res.image}></img>
                  </div>
                  <div className="heading"></div>
                </div>
                <div
                  className="shopnamess"
                  onClick={() => Productlink(res.vendor_id)}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h2>{res.name}</h2>
                    <small>
                      {res.ratings.toFixed(1)} <StarOutlined />
                    </small>
                  </div>

                  <div className="small">
                    <h1>{res.address}</h1>
                  </div>
                  <div className="small">
                    <h1>{res.landmark}</h1>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="pagin"></div>
        </div>
      </div>
      <div className="heading">
        <div className="wrapper2">
          <div className="categories">
            <h1>Restaurants</h1>
          </div>
          <div className="border"></div>
          <div className="item">
            {shopdata?.vendor_results?.map((res) => (
              <div data-aos="zoom-in">
                <div
                  className="prods"
                  onClick={() => Productlink(res.vendor_id)}
                >
                  <div className="image">
                    <img alt="img" src={res.image}></img>
                  </div>
                  <div className="heading"></div>
                </div>
                <div
                  className="shopnamess"
                  onClick={() => Productlink(res.vendor_id)}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h2>{res.name}</h2>
                    <small>
                      {res.ratings.toFixed(1)} <StarOutlined />
                    </small>
                  </div>

                  <div className="small">
                    <h1>{res.address}</h1>
                  </div>
                  <div className="small">
                    <h1>{res.landmark}</h1>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="pagin"></div>
        </div>
      </div>
    </Search>
  );
}

export default Shopsearch;
