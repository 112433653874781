import React, { useEffect, useState } from "react";
import { Top, DIV } from "./style";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { Popconfirm, Modal, DatePicker, TimePicker } from "antd";
import moment from "moment";
import BackArrow from "../BackArrow/index";
import { Eventlistpage } from "./store/index";
import { Skeleton } from "antd";
import { Spin } from "antd";
import Loader from "../common/widgets/Loader";
import { firebase } from "../../common/Firebase/index";
function EventPage() {
  //date format.....
  const format = "YYYY-MM-DD";
  const timeformat = "HH:mm";
  //disable date
  const today = moment();
  const disabledate = (current) => {
    return current.isBefore(today);
  };
  //
  const [
    {
      customer,
      isloading,
      name,
      alternate_contact,
      delivery_address,
      event_name,
      delivery_time,
      caretaker_contact,
      caretaker_name,
      landmark,
      delivery_date,
      pincode,
      updteuser,
      latitude,
      longitude,
    },
    {
      getcustomer,
      setname,
      deleteCustomer,
      UpdateCustomer,
      setalternate_contact,
      setcaretaker_name,
      setdelivery_date,
      setdelivery_time,
      setdelivery_address,
      setlandmark,
      setpincode,
      setevent_name,
      setcaretaker_contact,
      setupadteuser,
      Postupdate,
      Makedefault,
    },
  ] = Eventlistpage();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [idd, setidd] = useState("");

  const showModal = (id) => {
    setidd(id);
    UpdateCustomer(id);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    Postupdate(idd);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  let History = useHistory();
  const EventLink = () => {
    History.push("/eventcreate");
  };
  const edit = (id) => {
    History.push(`/updateevent/${id}`);
  };


  //modal
  const [token, setToken] = useState("");
  useEffect(() => {
    // import { firebase } from "../../common/Firebase/index";
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
    });
  }, []);
  //

  useEffect(() => {
    getcustomer();
    UpdateCustomer();
  }, [idd, token]);

  function onChangedate(date, dateString) {
    const mydate = (date, dateString);
    setdelivery_date(mydate);
  }
  function onChangetime(time, timeString) {
    console.log(time, timeString);
    const mytime = (time, timeString);
    setdelivery_time(mytime);
  }

  if (isloading) {
    return <Loader />;
  }
  return (
    <Top>
      <div className="main">
        <div className="center">
          <div className="level">
            <h1>Events</h1>
          </div>
          {/* <div className="link">
            <h1 className="first">Home {">"}</h1>
            <h1 className="second">Events</h1>
          </div> */}
        </div>
        <div className="button">
          <button onClick={() => EventLink()}>Add new event</button>
        </div>
      </div>
      <div className="box">
        <div>
          <div className="containerevent">
            <div className="containerd">
              <div className="borderevent">
                {customer?.map((res) => (
                  <div className="box1">
                    {res.is_default === true ? <h1>Default event</h1> : ""}

                    <small className="name">{res.name}</small>
                    <ul className="eventul">
                      <li>
                        Address :
                        <small>
                          {res.delivery_address},{res.pincode}
                        </small>
                      </li>
                      <li>
                        Event Name:<span>{res.event_name}</span>{" "}
                      </li>
                    </ul>
                    {res.can_update === false && res.can_delete === false ? (
                      " "
                    ) : (
                      <div className="btns">
                        {/* <button onClick={() => showModal(res.id)}>
                          <EditFilled />
                        </button> */}
                        <button onClick={() => edit(res.id)}>Edit</button>

                        <Popconfirm
                          placement={"rightBottom"}
                          title="Are you sure？"
                          onConfirm={() => deleteCustomer(res.id)}
                        >
                          {res.is_default === true ? (
                            ""
                          ) : (
                            <button
                              variant="contained"
                              color="default"
                              className="btns"
                            >
                              Delete
                            </button>
                          )}
                        </Popconfirm>
                        {res.is_default === true ? (
                          ""
                        ) : (
                          <button
                            style={{ width: 100 }}
                            className="btnsmake"
                            onClick={() => Makedefault(res.id)}
                          >
                            Makedefault
                          </button>
                        )}
                      </div>
                    )}
                    {res.can_update === false && res.can_delete === false ? (
                      <span style={{ fontSize: 12, color: "grey" }}>
                        There is an Active Order You Cant Delete or Update
                      </span>
                    ) : (
                      " "
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Modal
        title="Update event"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <DIV>
          <div className="name">
            <input
              type="text"
              placeholder=" Name "
              value={name}
              onChange={(e) => setname(e.target.value)}
            />
            <input
              type="text"
              placeholder="Event Name (eg: Marriage)"
              onChange={(e) => setevent_name(e.target.value)}
              value={event_name}
            />
          </div>
          <div className="adrs">
            <input
              type="text"
              placeholder="Alternate contact"
              value={alternate_contact}
              onChange={(e) => setalternate_contact(e.target.value)}
            />
            <input
              type="text"
              placeholder="Delivery Address"
              value={delivery_address}
              onChange={(e) => setdelivery_address(e.target.value)}
            />
          </div>
          <div className="adrs">
            <input
              type="text"
              placeholder="Landmark"
              value={landmark}
              onChange={(e) => setlandmark(e.target.value)}
            />
            <input
              type="nmbr"
              placeholder="pincode"
              value={pincode}
              onChange={(e) => setpincode(e.target.value)}
            />
          </div>
          <div className="adrs">
            <div>
              <DatePicker
                defaultValue={moment(delivery_date, format)}
                onChange={onChangedate}
                disabledDate={disabledate}
                format={format}
                style={{
                  width: "195px",
                  borderRadius: 3.5,
                  height: "6vh",
                  backgroundColor: "white",
                  boxShadow: "inset 0 -1px 0 # #ddff00",
                  border: "1px solid #e0e0e0",
                }}
              />
            </div>
            <div>
              <TimePicker
                onChange={onChangetime}
                defaultValue={moment(delivery_time, timeformat)}
                format={timeformat}
                style={{
                  width: "195px",
                  borderRadius: 3.5,
                  height: "6vh",
                  backgroundColor: "white",
                  boxShadow: "inset 0 -1px 0 #ddff00",
                  border: "1px solid #e0e0e0",
                }}
              />
            </div>
          </div>
          <div className="adrs">
            <input
              type="text"
              placeholder="caretaker name (if any)"
              value={caretaker_name}
              onChange={(e) => setcaretaker_name(e.target.value)}
            />

            <input
              type="nmbr"
              placeholder="Caretaker contact (if any)"
              value={caretaker_contact}
              onChange={(e) => setcaretaker_contact(e.target.value)}
            />
          </div>
        </DIV>
      </Modal> */}
    </Top>
  );
}

export default EventPage;
