import { MygetNotification } from "../../../infrastructure/Header";

const actions = {
  setLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },

  Getnotifications:
    () =>
    async ({ setState,dispatch }) => {
      dispatch(actions.setLoading(true));

      try {
        const res = await MygetNotification();
        setState({Notification :res.data.data})
        // dispatch(actions.setNotification(res.data.data));
      } catch (err) {
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
};

export default actions;
