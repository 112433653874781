import { CartSummuryid } from "../../../infrastructure/Cartsummury";

const actions = {
  setLoading:
    (isloading) =>
    ({ setState }) => {
      setState({ isloading });
    },

  Getcartorderdetails:
    (id) =>
    async ({setState,dispatch }) => {
      dispatch(actions.setLoading(true));
      try {
        const res = await CartSummuryid(id);
        setState({ user: res.data.data });
        setState({ events: res.data.data.event});
        // dispatch(actions.setUser(res.data.data));
        // dispatch(actions.setEvents(res.data.data.event));
      } catch (err) {
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
};
export default actions;
