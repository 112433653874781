import { Updateuser, UserProfiles } from "../../../infrastructure/Userprofile";
import { notification } from "antd";

const actions = {
  setUser:
    (user) =>
    ({ setState }) => {
      setState({ user });
    },
  setname:
    (name) =>
    ({ setState }) => {
      setState({ name });
    },
  setemail:
    (email) =>
    ({ setState }) => {
      setState({ email });
    },
  setphone_number:
    (phone_number) =>
    ({ setState }) => {
      setState({ phone_number });
    },
  setaddress:
    (address) =>
    ({ setState }) => {
      setState({ address });
    },
  setpincode:
    (pincode) =>
    ({ setState }) => {
      setState({ pincode });
    },
  setgender:
    (gender) =>
    ({ setState }) => {
      setState({ gender });
    },
  setprofile_photo:
    (profile_photo) =>
    ({ setState }) => {
      setState({ profile_photo });
    },
  setnameE:
    (namee) =>
    ({ setState }) => {
      setState({ namee });
    },
  setemailE:
    (emaile) =>
    ({ setState }) => {
      setState({ emaile });
    },
  setphone_numberE:
    (phone_numbere) =>
    ({ setState }) => {
      setState({ phone_numbere });
    },
  setaddressE:
    (addresse) =>
    ({ setState }) => {
      setState({ addresse });
    },
  setpincodeE:
    (pincodee) =>
    ({ setState }) => {
      setState({ pincodee });
    },
  setgenderE:
    (gendere) =>
    ({ setState }) => {
      setState({ gendere });
    },
  setgenderOPtion:
    (gender_option) =>
    ({ setState }) => {
      setState({ gender_option });
    },
  setprofile_photoE:
    (profile_photoe) =>
    ({ setState }) => {
      setState({ profile_photoe });
    },
  setLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  // setLoading:
  // (params) =>
  // ({ setState }) => {
  //   setState({ isLoading:params, });
  // },
  getUser:
    () =>
    async ({ setState, dispatch, getState }) => {
      dispatch(actions.setLoading(true));

      try {
        const res = await UserProfiles();
        dispatch(actions.setUser(res.data.data));

        dispatch(actions.setname(res.data.data.name));
        dispatch(actions.setemail(res.data.data.email));
        dispatch(actions.setphone_number(res.data.data.phone_number));
        dispatch(actions.setaddress(res.data.data.address));
        dispatch(actions.setpincode(res.data.data.pincode));
        dispatch(actions.setgender(res.data.data.gender));
        dispatch(actions.setprofile_photo(res.data.data.profile_photo));
        dispatch(actions.setnameE(res.data.data.name));
        dispatch(actions.setemailE(res.data.data.email));
        dispatch(actions.setphone_numberE(res.data.data.phone_number));
        dispatch(actions.setaddressE(res.data.data.address));
        dispatch(actions.setpincodeE(res.data.data.pincode));
        dispatch(actions.setgenderE(res.data.data.gender));
      } catch (err) {
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
  UpdateUser:
    (setIsModalVisible) =>
    async ({ dispatch, getState }) => {
      dispatch(actions.setLoading(true));

      try {
        const {
          name,
          email,
          phone_number,
          address,
          pincode,
          gender,
          profile_photo,
          profile_photoe,
          gender_option,
        } = getState();
        if (name === "") {
          notification["warning"]({
            message: "warning",
            description: "name field is empty",
          });
          return;
        } else if (email === "") {
          notification["warning"]({
            message: "warning",
            description: "email field is empty",
          });
          return;
        } else if (phone_number === "") {
          notification["warning"]({
            message: "warning",
            description: "phone numberfeild is empty",
          });
          return;
        } else if (address === "") {
          notification["warning"]({
            message: "warning",
            description: "address feild is empty",
          });
          return;
        } else if (pincode === "") {
          notification["warning"]({
            message: "warning",
            description: "pincode feild is empty",
          });
          return;
        } else if (gender === "") {
          notification["warning"]({
            message: "warning",
            description: "gender field is empty",
          });
          return;
        } else if (profile_photo === "") {
          notification["warning"]({
            message: "warning",
            description: "please upload profile photo",
          });
          return;
        }
        var bodyFormdata = new FormData();
        bodyFormdata.append("name", name);
        bodyFormdata.append("email", email);
        bodyFormdata.append("address", address);
        bodyFormdata.append("phone_number", phone_number);
        bodyFormdata.append("pincode", pincode);
        bodyFormdata.append(
          "gender",
          gender_option === "" ? gender : gender_option
        );
        bodyFormdata.append("profile_photo", profile_photoe);

        await Updateuser(bodyFormdata);

        notification["success"]({
          message: "success",
          description: "updated succesfully",
        });
        dispatch(actions.getUser());
        setIsModalVisible(false);
      } catch (err) {
        notification["warning"]({
          message: "warning",
          description: "something went wrong",
        });
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
};
export default actions;
