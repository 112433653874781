import React from "react";
import pagenotfound from "../../Images/FOOD-NOT-FOUND-2.png";
import styled from "styled-components";
function Pagenotfound() {
  return (
    <Error>
      <div className="picture">
        <img src={pagenotfound} alt="img" />
      </div>
    </Error>
  );
}

export default Pagenotfound;

export const Error = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .picture {
    width: 553px;
    padding-top: 30px;
    padding-bottom: 30px;
    @media (min-width: 1500px) {
      width: 880px;
    }
    img {
      width: 100%;
    }
  }
`;
