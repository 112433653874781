import { Shoplistget } from "../../../infrastructure/ShopTypes";
import { useHistory as history } from "react-router-dom";
import { notification } from "antd";
const actions = {
  setLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setcheif:
    (cheif) =>
    ({ setState }) => {
      setState({ cheif });
    },
  setcheif:
    (cheif) =>
    ({ setState }) => {
      setState({ cheif });
    },
  setcaters:
    (caters) =>
    ({ setState }) => {
      setState({ caters });
    },
  // setshopAll:
  //   (shopall) =>
  //   ({ setState }) => {
  //     setState({ shopall });
  //   },
  setIserrormodal:
    (iserrormodal) =>
    ({ setState }) => {
      setState({ iserrormodal });
    },
  seterrorres:
    (reserror) =>
    ({ setState }) => {
      setState({ reserror });
    },
  setSearch:
    (search) =>
    ({ setState }) => {
      setState({ search });
    },
  Getallshoplist:
    (idd, opt, options, History) =>
    async ({ dispatch, setState }) => {
      dispatch(actions.setLoading(true));

      try {
        const res = await Shoplistget(idd, opt, options);
        if (res.status === 200) {
          // dispatch(actions.setshopAll(res.data.data));
          setState({shopall:res.data.data})
        } else if (res.response.status === 599) {
          window.location.replace(`/eventcreate`);
          notification["success"]({
            message: "success",
            description: "Please Create Event First and Make Default!....",
          });
        }
      } catch (err) {
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
};
export default actions;
