import styled from "styled-components";

export const Popups = styled.div`
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  .popups{
    position:fixed;
    top:120px;
    z-index:100;
    button{
      width:120px;
      height:50px;
      border:none;
      font-size:18px;
      color:white;
      background-color:Red;
      border-radius:0px 8px 8px 0px;
      font-size:bold;
    }
  }
  .popup {
    background-color:#0C2D48;
    width:700px;
    height:350px;
    position:fixed;
    top: 30%;
    left: 65%;
    transform: translate(-50%, -50%);
    border-radius: 25px;
    z-index: 100;
    display: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    flex-wrap: wrap;
    @media (max-width:680px){
      width:580px;
    }
    @media (max-width:610px){
      width:500px;
    }
    @media (max-width:550px){
      width:440px;
      height:320px;
    }
    @media (max-width:490px){
      width:400px;
    }
    @media (max-width:440px){
    height:unset;
    width:280px;
    }
    .popupheader{
      width:100%;
      height:40px;
      background-color:#E58300;
      display:flex;
      justify-content:center;
      align-items:center;
      position:relative;
      cursor:pointer;
      h1{
        color:#e5e5e5;
        opacity:1;
        font-size:22px;
        margin:0px;
        padding:0px;
      }
      
    }
    .popupdeals{
      display: flex;
      margin-top: 10px;
       padding: 20px;
      justify-content: center;
      align-items:center;
     

     .dealscomplete{
      width: 200px;
      display: flex;
      flex-direction: column;
      

      justify-content: center;
      align-items: center;
      gap: 7px;

       .dealname{
         display:flex;
         justify-content:center;
         align-items:center;
        
        h1{
          font-size:16px;
          color:white;
          opacity:0.8;
          @media (max-width:680px){
           font-size:14px;
          }
          @media (max-width:588px){
            font-size:12px;
           }
        }
       }
      
      .deals{
        width:100px;
        height:100px;
        border-radius:10px;
        background:#e5e5e5;
        cursor:pointer;
        transition:1s;
        &:hover{
         transform:scale(3.8);
          cursor:zoom in;
          z-index:1;
        }
        img{
            width:100%;
            height:100%;
            border-radius:10px;
            object-fit:cover;
         
           
        }
       
        @media (max-width:550px){
          width:80px;
          height:80px;
        }
      }
      .dealss{
        width:100px;
        height:100px;
        border-radius:10px;
        background:#e5e5e5;
        img{
            width:100%;
            height:100%;
            border-radius:10px;
            object-fit:cover;
            filter: blur(0.7px);
           
        }
        .soldout{
          position:absolute;
          top: 55%;
          padding-left:10px;
          width:100px;
          height:20px;
          display:flex;
          justify-content:center;
          @media (max-width:550px){
            width:80px;
      
          }
          @media (max-width:440px){
            width:70px;
            top:35%;
      
          }
        
        }
        @media (max-width:550px){
          width:80px;
          height:80px;
        }
      }
      @media (max-width:550px){
        width:120px;
   
      }
     }
     @media (max-width:550px){
    padding:10px;
    }
    @media (max-width:490px){
      padding:none;
      }
      @media (max-width:440px){
        flex-wrap:wrap;
      }
    }
    .showPopup {
      opacity: 1;
      transform: translateY(0);
      pointer-events: all;
  }

   .price{
     padding:7px;
     h1{
       font-size:14px;
       color:white;
       font-family: 'Ropa Sans', sans-serif;
       @media (max-width:610px){
        font-size:12px;
       }
       @media (max-width:490px){
        font-size:11px;
        }
     }
   }
 
  }
  }
`;
