import { get,post } from "../common/remote/base_api";

export const Getcartdetails = (data) => {
  return get(`user/cart_details/`, data);
};
export const RemoveCart = (data) => {
  return post(`user/update_cart/`, data);
};
export const CheckoutCart = (id) => {
  return get(`user/checkout/${id}`);
};
export const AddquantityCart = (data) => {
  return post(`user/update_cart/`,data);
};
