import styled from "styled-components";
import Colors from "../common/Colors/Colors";
export const Type = styled.div`
  .btns {
    padding-left: 20px;
    padding-top: 20px;
   
    background: ${Colors.banner};
  }
  .background {
    background: ${Colors.banner};
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .wrapper {
      width: 80%;

      img {
      }
      .separate {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
        margin-bottom: 50px;
        @media(max-width:980px){
          flex-direction: column;
          justify-content:center;
          align-items:center;
          gap:20px;
        }
      }
      .left {
        h2 {
          font-size: 30px;
          color: gray;
          font-weight: bold;
          @media(max-width:980px){
            font-size:28px;
          }
          @media(max-width:900px){
            font-size:26px;
          }
          @media(max-width:800px){
            font-size:28px;
          }
          @media(max-width:480px){
            font-size:26px;
          }
          @media(max-width:440px){
            font-size:22px;
          }
          @media(max-width:380px){
            font-size:18px;
          }
        }
      }
      .right {
        display: flex;
        gap: 20px;
        align-items:center;
        @media(max-width:480px){
          flex-direction:column;
        }
        span{
          font-size:18px;
          font-weight:bold;
          font-family: "dm_sansregular";
          letter-spacing:1px;
          color:#001729;
          @media(max-width:980px){
            font-size:16px;
          }
          @media(max-width:840px){
            font-size:14px;
          }
          @media(max-width:400px){
            font-size:12px;
          }

        }
        .select {
          select {
            border-radius: 4px;
            background: #fafafa;;
            color: black;
            text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
            height: 40px;
            border: none;
            width: 150px;
            padding-left: 4px;
            cursor:pointer;
            option {
              font-family: "dm_sansregular";
              background: #fafafa;;
              color: black;
              font-size:16px;
              @media(max-width:980px){
                font-size:14px;
              }
            }
            @media(max-width:980px){
              width:135px;
              height:35px;
              font-size:14px;
            }
            @media(max-width:900px){
              width:105px;
            }
            @media(max-width:400px){
              width:95px;
            }
            @media(max-width:338px){
              height:30px;
              width:82px;
              font-size:12px;
            }
          }
        }
        button {
          background-color: #ddff00;
          height: 40px;
          width: 50px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
        }
      }
      .search {
        margin-bottom: 150px;
        input {
          font-family: "dm_sansregular";
          background-color: white;
          height: 80px;
          border: solid;
          border-radius: 15px;
          width: 100%;
          padding-left: 20px;
          font-size: 18px;
          outline: none;
          border-color: white;
          letter-spacing: 3px;
          input::placeholder {
            color: black;
           
          }

          input:textarea {
            color: white;
          }
          @media(max-width:980px){
            height:70px;
          }
          @media(max-width:900px){
            height:65px;
          }
          @media(max-width:600px){
            height:60px;
            font-size:16px;
            letter-spacing:unset;
          }
          @media(max-width:430px){
            height:55px;
            font-size:14px;
          }
        }
        @media(max-width:430px){
          margin-bottom:100px;
        }
  
      }
          }
        }
      }
    } //wrapper
  }
  .heading{
   display: flex;
   margin-top: 30px;
   justify-content:center;
   min-height:200px;
    .wrapper2{
      width:80%;
   
    
      .categories{
        gap:10px;
        display: flex;
       
        align-items:center;
       
        h1{
          font-family: "Quicksand", sans-serif;
          font-weight:bold;
           font-size:28px;
           font-weight:bold
           @media(max-width:980px){
              font-size:26px;
          }
          @media(max-width:500px){
            font-size:24px;
          }
          @media(max-width:400px){
            font-size:22px;
          }
        }
       
        }
        .border{
           margin-bottom:50px;
          border-bottom:2px solid #e6e6e6;
        }
      
      
      
    }
    .item{

      .shopnamess{
        display: flex;
        justify-content:space-between;
        margin-top:10px;
        flex-direction: column;
        h2{
          font-size:18px;
          font-weight:bold;
          font-family: "dm_sansregular";
          @media (max-width:500px){
            font-size:16px;
          }
          @media (max-width:400px){
            font-size:14px;
          }

        }
        small{
          width:50px;
          height:20px;
          background-color:#96d707;
          border-radius:4px;
          display:flex;
          display: flex;
color: white;
justify-content: space-around;
align-items: center;
        }
      }



      display: flex;
      gap:30px;
      flex-wrap: wrap;
      padding-bottom:50px;
      justify-content:center;
      .prods{
        width:355px;
        height:300px;
       
        cursor: pointer;
        border-radius:12px;
        &:hover{
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }
        .image{
          display: flex;
          justify-content:center;
           width:355px;
          height:300px;
          border-radius:12px;
         overflow: hidden;
          img{
            object-fit:cover;
              transition: transform 0.1s;
          :hover {
            transform: scale(1.1);
   
            }
            
            border-radius:8px;
           width:100%;
           height:100%;
          
          }
          @media(max-width:980px){
            width:300px;
            height:250px;
          }
          @media(max-width:500px){
            width:250px;
            height:200px;
          }
          @media(max-width:400px){
            width:200px;
            height:150px;
          }
        }
        .heading{
     
          display:flex;
          flex-direction: column;
         position: relative;
         top:-174px;
         margin-left:10px;
         margin-right:10px;
       
     
         justify-content:center;
         height:50px;
         display: flex;
         align-items: center;
         padding:0;
         margin:0;
        .section{
        
           width:85%;
           border-radius:8px;
           display:flex;
           flex-direction: column;
           justify-content:center;
           align-items:center;
           gap:8px;
         .name{
           
           height:10px;
         }
         h2{
            font-family: "dm_sansregular";
            font-size:16px;
            margin: 0px;
            letter-spacing:1px;
            padding: 0px;
            font-weight:bold;
            color:black;
           
          }
        }
        .sml{
         height:10px;
        }
          span{
             font-family: "dm_sansregular";
             font-size:14px;
             color:gray;
          }
        }
        @media(max-width:980px){
          width:300px;
          height:250px;
        }
        @media(max-width:500px){
          width:250px;
          height:200px;
        }
        @media(max-width:400px){
          width:200px;
          height:150px;
        }
        }
      
          
        }
       
       
      }
    }
  }
`;
