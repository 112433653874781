import styled from "styled-components";
export const CartItem = styled.div`

  .top {
    padding-top: 30px;
    width: 80%;
    display: flex;
    gap: 80px;
    margin-bottom: 60px;
      h1 {
        font-family: "pt_serifbold";
        font-size: 27px;
      }
  }
  .content {
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
    .border {
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      width: 60%;
      padding: 10px;
     
      .tables table.official {
        width: 100%;
        border: 1px solid #dddddd;
        background: #ffffff;
        box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
        border-radius: 0px 4px 0px 0px;
      }
      tr.head-row-off {
        border-bottom: 1px solid #dddddd;
      }
      th.head-off {
        font-style: normal;
        font-weight: 6 00;
        font-size: 14px;
        line-height: 20px;
        height: 40px;

        text-align: left;
        font-family: FSMedium;
        font-style: normal;
        font-weight: 500px !important;
        font-size: 16px;
        line-height: 150%;
        color: #000000;
        width: 200px;
        h1 {
          font-size: 18px;
          @media(max-width:1060px){
            font-size:16px;
          }
          @media(max-width:920px){
            font-size:14px;
          }
        }
      }
      th.head-off1 {
        font-style: normal;
        font-weight: 6 00;
        font-size: 14px;
        line-height: 20px;
        height: 40px;

        text-align: left;
        font-family: FSMedium;
        font-style: normal;
        font-weight: 500px !important;
        font-size: 16px;
        line-height: 150%;
        color: #000000;
        width: 200px;
        h1 {
          font-size: 18px;
          @media(max-width:1060px){
            font-size:16px;
          }
          @media(max-width:920px){
            font-size:14px;
          }
       
        }
      
      }
      tr.body-row-off {
        margin-top: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid #dddddd;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        margin-left: 10px;
        height: 90px;
        text-align-center;
        font-family: FSMedium;
        font-style: normal;
        font-weight: 500px !important;
        font-size: 16px;
        line-height: 150%;
      
        color: #000000;
        
       
        .qty {
          display: flex;
      
          align-items:center;
          gap: 15px;
        }
        img {
          width: 110px;
          border-radius: 6px;
          height: 70px;
          object-fit: cover;;
        }
        h1 {
          font-size: 16px;
          margin:0px;
          padding:0px;
          @media(max-width:1060px){
            font-size:13px;
          }
        }
        h2 {
          font-size: 16px;
          @media(max-width:1060px){
            font-size:13px;
          }
        }
      }
      tr.body-row-off:hover {
        background: linear-gradient(
            0deg,
            rgba(96, 36, 57, 0.08),
            rgba(96, 36, 57, 0.08)
          ),
          #ffffff;
      }
      @media(max-width:780px){
        overflow-x:scroll;
        width:650px;
      }
    }
  }
  .footercart {
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    .containercart {
      display: flex;
      justify-content: space-between;
      background-color: #fafafa;
      ${"" /* height: 200px; */}
    
      width: 60%;
      @media(max-width:852px){
        flex-direction:column;
      }
      .rowcart {
        height: 50px;
        width: 100%;
        display: flex;

        align-items: center;
        @media(max-width:852px){
           justify-content:center;
        }
        .arrowcart {
          background-color: #006fb1;
          width: 200px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          height: 35px;
         
          @media(max-width:436px){
            width:180px;
          }
          @media(max-width:390px){
            width:150px;
           
          }
          @media(max-width:324px){
            margin-right:10px;
          }
         
          &:active {
            background-color: black;
            color: black;
          }
          &:active {
            color: white;
          }
          button {
            font-family: "montserratregular";
            background-color: #006fb1;
            padding: 5px;
            width: 200px;
            height: 50px;
            border: none;
            color: black;
            height: 35px;
            border-radius: 4px;
            font-weight: bold;
            color: #212529;
            color:white;
            text-align: center;
            cursor: pointer;
            border: none;
            font-size:15px;
            &:active {
              background-color: black;
              color: white;
            }
            @media(max-width:682px){
              font-size:14px;
            }
            @media(max-width:436px){
              font-size:13px;
            }
            @media(max-width:390px){
              font-size:10px;
            }
          
          }
          
        }
      }
      .column {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .check {
          width: 300px;
          height: 165px;
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          padding: 5px;
          margin-bottom: 10px;
            @media(max-width:546px){
              width:270px;
            }
            @media(max-width:514px){
              width:260px;
            }
            @media(max-width:480px){
              width:220px;
            }
            @media(max-width:400px){
              width:180px;
            }
            @media(max-width:300px){
              width:150px;
            }
          .tag {
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 5px;
            h1 {
              font-family: "montserratregular";
              margin: 0px;
              padding:0px;
              font-size: 14px;
              font-weight: bold;
              @media (min-width: 980px) {
                font-size:13px;
              }
            }

            h2 {
              min-width: 55px;
              max-width:100px;
              font-family: "montserratregular";
              margin: 0px;
              padding:0px;
              font-size: 14px;
              font-weight: bold;
              @media (min-width: 980px) {
                font-size:13px;
              }
            }
          
          }
        }
      }
      .addressevent{
        margin-top:10px;
        margin-bottom: 10px;
        button{
          background: transparent;
          border: none;
          color:#0369f6;
          font-size:16px;
          font-family: "dm_sansregular";
        }

      }
      .btn {
        button {
          background: #006fb1;
          font-size: 16px;
          padding: 5px;
          width: 295px;
          border: none;
          height: 40px;
          border-radius: 8px;
          font-weight: 400;
          color: black;
          text-align: center;
          cursor: pointer;
          color:white;
          &:active {
            background-color: black;
            color: white;
          }
          @media (min-width: 980px) {
            font-size:14px;
          }
          @media(max-width:852px){
            width:200px;
           
         }
      
         @media(max-width:480px){
           width:150px;
           height:30px;
           font-size:14px;
         }
        }
      }
    }
  }
`;
