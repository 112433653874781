const initialState = {
  signres: null,
  isLoading: "",
  number:"+91",
  otp:"",
  token:null,
  defaultnum:"+91"
};

export default initialState;
