import styled from "styled-components";
import Colors from "../common/Colors/Colors";
export const ViewShop = styled.div`
  .banner {
    height: 480px;
    background-color: ${Colors.banner} @media (max-width: 768px) {
      height: unset;
      padding-bottom: 50px;
    }
    .backarrow {
      padding-left: 50px;
      padding-top: 50px;
    }
    .contentmain {
      display: flex;
      justify-content: center;
      align-items: center;

      .myimage {
        width: 70%;
        display: flex;
        justify-content: space-between;
        @media (max-width: 768px) {
          padding-top: 30px;
          flex-direction: column-reverse;
          width: unset;
          align-items: center;
          justify-content: center;
        }
        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          width: 400px;
          border-radius: 18px;
          @media (max-width: 540px) {
            width: unset;
          }
          h1 {
            font-family: "dm_sansregular";
            font-size: 36px;
            font-weight: bold;
            color: white;
            @media (max-width: 540px) {
              font-size: 26px;
            }
          }
          p {
            font-family: "dm_sansregular";
            font-size: 22px;
            color: grey;
          }
          .separate {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            small {
              background: #96d707;
              font-family: "dm_sansregular";
              font-size: 14px;
              display: flex;
              width: 50px;
              justify-content: center;
              align-items: center;
              gap: 5px;
              border-radius: 10px;
              color: white;
            }
          }
        }
        img {
          box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
          width: 300px;
          height: 300px;
          object-fit: cover;
          border-radius: 15px;
          @media (max-width: 540px) {
            width: 250px;
            height: 250px;
          }
        }
      }
    }
  }
`;
export const MiddleContainer = styled.div`
  .wrapper {
    padding-top: 50px;
    display: flex;
    justify-content: center;
    .top {
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      h1 {
        width: 300px;
        font-family: "dm_sansregular";
        font-size: 26px;
        @media (max-width: 540px) {
          font-size: 20px;
        }
      }
      small {
        width: 100%;
        border: 1px solid rgb(240, 240, 240);
        height: 2px;
      }
    }
  }

  .list {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .group {
      width: 50%;
      display: flex;
      gap: 10px;
      h1 {
        font-family: "dm_sansregular";
        font-size: 18px;
        font-weight: bold;
      }
      p {
        font-family: "dm_sansregular";
        font-size: 18px;
        color: grey;
      }
    }
  }
`;
export const Social = styled.div`
  background-color: #f5f5f5;
  .wrapper {
    padding-top: 50px;
    display: flex;
    justify-content: center;
    .top {
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: center;

      h1 {
        width: 300px;
        font-family: "dm_sansregular";
        font-size: 26px;
        @media (max-width: 540px) {
          font-size: 20px;
        }
      }
      small {
        width: 100%;
        border: 1px solid rgb(128, 128, 128);
        height: 2px;
      }
    }
  }
  .MainHeading {
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
    .header {
      width: 50%;
      @media (max-width: 540px) {
        width: 50%;
      }
      img {
      }
    }
  }
`;
