import styled from "styled-components";

export const Request = styled.div`
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  .popupss {
    position: fixed;
    top: 180px;
    z-index: 100;
    button {
      width: 120px;
      height: 50px;
      border: none;
      font-size: 18px;
      color: white;
      background-color: Red;
      border-radius: 0px 8px 8px 0px;
      font-size: bold;
    }
  }
  .requestform {
    background-color: #0c2d48;
    width: 500px;

    position: fixed;
    top: 60%;
    left: 20%;

    transform: translate(-50%, -50%);
    border-radius: 25px;
    z-index: 100;
    display: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    flex-wrap: wrap;
    // @media (max-width: 680px) {
    //   width: 580px;
    // }
    // @media (max-width: 610px) {
    //   width: 500px;
    // }
    // @media (max-width: 550px) {
    //   width: 440px;
    //   height: 320px;
    // }
    // @media (max-width: 490px) {
    //   width: 400px;
    // }
    // @media (max-width: 440px) {
    //   height: unset;
    //   width: 280px;
    // }
    .popupheaders {
      width: 100%;
      height: 40px;
      background-color: #e58300;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;
      h1 {
        color: #e5e5e5;
        opacity: 1;
        font-size: 20px;
        margin: 0px;
        padding: 0px;
      }
    }
    .popupdeals {
      display: flex;
      margin-top: 10px;
      padding: 20px;
      flex-direction: column;
      gap:16px;
      justify-content: center;
      align-items: center;
      .inputsection{
          width:100%;
          display:flex;
          justify-content: center;
          gap:10px;
          input{
              width:100%;
              height:6vh;
              border-radius:8px;
              border:none;
              padding:8px;
          }
          textarea{
              width:100%;
              border-radius:8px;
              border:none;
              padding:8px;
              height:8vh;
          }
          button{
              width:100px;
              height:30px;
              border-radius:8px;
              border:none;
              background-color: #e58300;
              color:white;
          }
      }
    }
  }
`;
