class ActionProvider {
  constructor(createChatBotMessage, setStateFunc) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
  }

  greet = () => {
    const message = this.createChatBotMessage(
      "Contact our Customer Care 04842998662"
    );
    this.addMessageToState(message);
  };
  greetang = () => {
    const message = this.createChatBotMessage(
      "Contact our Customer Care 04842998662"
    );
    this.addMessageToState(message);
  };
  Handlecustomers = () => {
    const message = this.createChatBotMessage(
      "Contact our Customer Care 04842998662"
    );
    this.addMessageToState(message);
  };

  greeting = () => {
    const message = this.createChatBotMessage(`H i ! Are you looking for `, {
      widget: "options",
    });

    this.addMessageToState(message);
  };
  handleJavascriptQuiz = () => {
    const message = this.createChatBotMessage(
      "Contact our Customer Care 04842998662"
    );

    this.addMessageToState(message);
  };

  addMessageToState = (message) => {
    this.setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, message],
    }));
  };
}

export default ActionProvider;
