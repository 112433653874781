/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import BackArrow from "../BackArrow";
import {
  Order,
  Form1order,
  Form2order,
  Form3order,
  Form4order,
  Form5order,
  Form6order,
} from "./style";
import { MailOutlined } from "@ant-design/icons";
import { OrderListview } from "./store";
import { useHistory } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

import { Notificationdata } from "../Header/store";
import { firebase } from "../../common/Firebase/index";
import { Modal, Button, Popconfirm } from "antd";
import { notification } from "antd";
import { Tag, Divider } from "antd";
import Moment from "react-moment";

// const PaytmChecksums = require("./checksum/checksum");
function Orders() {
  AOS.init();
  let history = useHistory();
  const [
    {
      isLoading,
      order,
      paymentsitem,
      razerpaykey,
      razorpay_order_id,
      razorpay_payment_id,
      finalpayment,
    },
    {
      setOrder,
      getadds,
      Cancelmyorder,
      setorderid,
      Mypaymentpost,
      setRazorpaypaymentid,
      setRazorpayorderid,
      PostAdvancepayment,
      Finalpaymentpost,
      setLoading,
      Deletemyorder,
    },
  ] = OrderListview();

  const [{ Notification }, { setNotification, Getnotifications }] =
    Notificationdata();

  const [page, setpage] = useState("all");

  const placed = order.filter(function (s) {
    return s.order_status === "placed";
  });
  const unpaid = order.filter(function (s) {
    return s.order_status === "unpaid";
  });
  const Cancelled = order.filter(function (s) {
    return s.order_status === "cancelled";
  });
  const upcomingres = order.filter(function (s) {
    return s.order_status === "up-coming";
  });
  const Completedres = order.filter(function (s) {
    return s.order_status === "completed";
  });
  const Deliveredres = order.filter(function (s) {
    return s.order_status === "delivered";
  });
  const Rejectedres = order.filter(function (s) {
    return s.order_status === "rejected";
  });

  const Allfun = () => {
    setpage("all");
  };
  const placedfun = () => {
    setpage("placed");
  };
  const Cancelledform = () => {
    setpage("Cancelled");
  };
  const Rejectedform = () => {
    setpage("rejected");
  };
  const upaidfun = () => {
    setpage("unpaid");
  };
  const Upcomingfun = () => {
    setpage("upcoming");
  };
  const Completedfun = () => {
    setpage("completed");
  };
  const Deliverdfun = () => {
    setpage("delivered");
  };

  const Orderviewpage = (id) => {
    history.push(`/cartsummary/${id}`);
  };

  const Cancelmyorders = (id) => {
    Cancelmyorder(id);
  };

  const Rating = (id) => {
    history.push(`/rating/${id}`);
  };

  //final post request funtion razorpay for database connections.................................................................


  //payment functions ................................................
  const Paymentsclick = (id) => {
    setorderid(id);
    Mypaymentpost(id);
    setLoading(!isLoading);
  };

  //final payment...................................................
  const Finalpaymentpostreq = (id) => {
    setorderid(id);
    Finalpaymentpost(id);
  };
  const DeleteOrder = (value) => {
    Deletemyorder(value);
  };

  //razerpay intergiration...........................................................................

  const makePayment = () => {
    setLoading(true);
    var config = {
      root: "",
      style: {
        bodyBackgroundColor: "#fafafb",
        bodyColor: "",
        themeBackgroundColor: "#0FB8C9",
        themeColor: "#ffffff",
        headerBackgroundColor: "#284055",
        headerColor: "#ffffff",
        errorColor: "",
        successColor: "",
        card: {
          padding: "",
          backgroundColor: "",
        },
      },
      data: {
        orderId: paymentsitem.order,
        token: paymentsitem.token,
        tokenType: "TXN_TOKEN",
        amount: paymentsitem.amount /* update amount */,
      },
      payMode: {
        labels: {},
        filter: {
          exclude: [],
        },
        order: ["CC", "DC", "NB", "UPI", "PPBL", "PPI", "BALANCE"],
      },
      website: "WEBSTAGING",
      flow: "DEFAULT",
      merchant: {
        mid: paymentsitem.mid,
        redirect: false,
      },
      handler: {
        transactionStatus: function transactionStatus(paymentStatus, self) {
          if (paymentStatus.RESPMSG === "Txn Success") {
            PostAdvancepayment({
              paytm_order_id: paymentStatus?.ORDERID,
              paytm_txn_id: paymentStatus?.TXNID,
            });
            notification["success"]({
              message: "success",
              description: "payment has been successfully processed",
            });
           window.Paytm.CheckoutJS.close();
          } else {
            notification["warning"]({
              message: "warning",
              description: "Error occured",
            });
          }

          setLoading(false);
        },
        notifyMerchant: function notifyMerchant(eventName, data) {
          // console.log("notifyMerchant handler function called");
          // console.log("eventName => ", eventName);
          // console.log("data => ", data);

          // console.log("Closed");
          setLoading(false);
        },
      },
    };
    if (window.Paytm && window.Paytm.CheckoutJS) {
      // initialze configuration using init method
      console.log("javascript open '''''''''''''''''''''''''''''''''''''''''''''''''")
      window.Paytm.CheckoutJS.init(config)
        .then(function onSuccess() {
          // console.log("Before JS Checkout invoke");
          // after successfully update configuration invoke checkoutjs
          window.Paytm.CheckoutJS.invoke();
        })
        .catch(function onError(error) {
          // console.log("Error => ", error);
        });
    }
  };

  const confirm = () => {
    makePayment();
  };

  const [token, setToken] = useState("");
  React.useLayoutEffect(() => {
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
      if (token === "") {
      } else {
        getadds();
        Getnotifications();
      }
    });
  }, [token]);

  // if (isLoading) {
  //   return <Loader />;
  // }
  return (
    <Order>
      <div className="page">
        <div className="page-content">
          <h1>Let's order happiness..... </h1>

          <div className="arrow">
            <BackArrow />
          </div>
        </div>
      </div>
      <div className="page-section">
        <div className="section">
          <div className="items">
            <ul>
              <li
                onClick={() => Allfun()}
                style={{
                  border:
                    page === "all"
                      ? "3px dashed #006fb1"
                      : "3px dashed rgba(40,41,50,0.2)",
                  color: page === "all" ? "#4c4c4c" : "#cccccc",
                }}
              >
                All
              </li>
              <li
                onClick={() => placedfun()}
                style={{
                  border:
                    page === "placed"
                      ? "3px dashed #006fb1"
                      : "3px dashed rgba(40,41,50,0.2)",
                  color: page === "placed" ? "#4c4c4c" : "#cccccc",
                }}
              >
                Placed
              </li>
              <li
                onClick={() => Rejectedform()}
                style={{
                  border:
                    page === "rejected"
                      ? "3px dashed #006fb1"
                      : "3px dashed rgba(40,41,50,0.2)",
                  color: page === "rejected" ? "#4c4c4c" : "#cccccc",
                }}
              >
                Rejected
              </li>
              <li
                onClick={() => Cancelledform()}
                style={{
                  border:
                    page === "Cancelled"
                      ? "3px dashed #006fb1"
                      : "3px dashed rgba(40,41,50,0.2)",
                  color: page === "Cancelled" ? "#4c4c4c" : "#cccccc",
                }}
              >
                Cancelled
              </li>
              <li
                onClick={() => upaidfun()}
                style={{
                  border:
                    page === "unpaid"
                      ? "3px dashed #006fb1"
                      : "3px dashed rgba(40,41,50,0.2)",
                  color: page === "unpaid" ? "#4c4c4c" : "#cccccc",
                }}
              >
                Unpaid
              </li>
              <li
                onClick={() => Upcomingfun()}
                style={{
                  border:
                    page === "upcoming"
                      ? "3px dashed #006fb1"
                      : "3px dashed rgba(40,41,50,0.2)",
                  color: page === "upcoming" ? "#4c4c4c" : "#cccccc",
                }}
              >
                Up-Coming
              </li>
              <li
                onClick={() => Completedfun()}
                style={{
                  border:
                    page === "completed"
                      ? "3px dashed #006fb1"
                      : "3px dashed rgba(40,41,50,0.2)",
                  color: page === "completed" ? "#4c4c4c" : "#cccccc",
                }}
              >
                Payment-Completed
              </li>
              <li
                onClick={() => Deliverdfun()}
                style={{
                  border:
                    page === "delivered"
                      ? "3px dashed #006fb1"
                      : "3px dashed rgba(40,41,50,0.2)",
                  color: page === "delivered" ? "#4c4c4c" : "#cccccc",
                }}
              >
                Delivered
              </li>
            </ul>
          </div>
          {page === "all" && (
            <FormAll
              DeleteOrder={DeleteOrder}
              setOrder={setOrder}
              order={order}
              Orderviewpage={Orderviewpage}
              Cancelmyorders={Cancelmyorders}
              Paymentsclick={Paymentsclick}
              Moment={Moment}
              Rating={Rating}
              isLoading={isLoading}
              confirm={confirm}
              Finalpaymentpostreq={Finalpaymentpostreq}
            />
          )}
          {page === "placed" && (
            <Formplaced
              placed={placed}
              Orderviewpage={Orderviewpage}
              Cancelmyorders={Cancelmyorders}
              Moment={Moment}
              Rating={Rating}
              isLoading={isLoading}
              confirm={confirm}
            />
          )}
          {page === "Cancelled" && (
            <FormCancelled
              DeleteOrder={DeleteOrder}
              Cancelled={Cancelled}
              Orderviewpage={Orderviewpage}
              Cancelmyorders={Cancelmyorders}
              Moment={Moment}
              Rating={Rating}
              isLoading={isLoading}
              confirm={confirm}
            />
          )}
          {page === "unpaid" && (
            <FormUnpaid
              unpaid={unpaid}
              Orderviewpage={Orderviewpage}
              Cancelmyorders={Cancelmyorders}
              Paymentsclick={Paymentsclick}
              Moment={Moment}
              Rating={Rating}
              isLoading={isLoading}
              confirm={confirm}
            />
          )}
          {page === "rejected" && (
            <FormRejected
              Rejected={Rejectedres}
              Orderviewpage={Orderviewpage}
              Cancelmyorders={Cancelmyorders}
              Paymentsclick={Paymentsclick}
              Moment={Moment}
              Rating={Rating}
              isLoading={isLoading}
              confirm={confirm}
            />
          )}
          {page === "upcoming" && (
            <FormUpcoming
              Finalpaymentpostreq={Finalpaymentpostreq}
              upcomingres={upcomingres}
              Orderviewpage={Orderviewpage}
              Cancelmyorders={Cancelmyorders}
              Moment={Moment}
              Rating={Rating}
              isLoading={isLoading}
              confirm={confirm}
            />
          )}
          {page === "completed" && (
            <FormCompleted
              Completedres={Completedres}
              Orderviewpage={Orderviewpage}
              Cancelmyorders={Cancelmyorders}
              Moment={Moment}
              Rating={Rating}
              isLoading={isLoading}
              confirm={confirm}
            />
          )}
          {page === "delivered" && (
            <FormDelivered
              Deliveredres={Deliveredres}
              Orderviewpage={Orderviewpage}
              Cancelmyorders={Cancelmyorders}
              Moment={Moment}
              Rating={Rating}
              isLoading={isLoading}
              confirm={confirm}
            />
          )}
        </div>
      </div>
    </Order>
  );
}

export default Orders;

//form all...................................................................................................................................
function FormAll({
  order,
  Orderviewpage,
  Cancelmyorders,
  Paymentsclick,
  Moment,
  Rating,
  isLoading,
  confirm,
  DeleteOrder,
  Finalpaymentpostreq,
}) {
  //  if(isLoading) {
  //    return <pl>loading.........</pl>
  //  }
  let green = "green";
  return (
    <Form1order>
      {order?.map((res) => (
        <div className="Box" data-aos="fade-left">
          <div className="box">
            <div className="left">
              <h5>{res.id}</h5>
              <ul>
                <li>
                  Order date :{" "}
                  <b>
                    <Moment format="D MMM YYYY HH:mm">{res.ordered_at}</Moment>
                  </b>
                </li>
                <li>
                  Order status :
                  {res.order_status === "up-coming" ? (
                    <Tag color="magenta">{res.order_status}</Tag>
                  ) : res.order_status === "cancelled" ? (
                    <Tag color="red">{res.order_status}</Tag>
                  ) : res.order_status === "delivered" ? (
                    <Tag color="purple">{res.order_status}</Tag>
                  ) : res.order_status === "completed" ? (
                    <Tag color="green">{res.order_status}</Tag>
                  ) : res.order_status === "placed" ? (
                    <Tag color="cyan">{res.order_status}</Tag>
                  ) : res.order_status === "unpaid" ? (
                    <Tag color="volcano">{res.order_status}</Tag>
                  ) : res.order_status === "rejected" ? (
                    <Tag color="volcano">{res.order_status}</Tag>
                  ) : (
                    ""
                  )}
                </li>
                <li>
                  Event Name: <b>{res.event_name}</b>
                </li>
                <li>
                  Due amount :<b>₹ {res.due_amount}</b>
                </li>
                <li>
                  Order Total :<b>₹ {res.total_amount}</b>
                </li>
                <li>
                  <b>Ordered from : {res.shop_name}</b>
                </li>

                <li>
                  <b>Last date For advance : {res.advance_due_date}</b>
                </li>
                <li>
                  <b>
                    Verification code :{" "}
                    {res.verification_code === null ? (
                      <Tag color="red">Null</Tag>
                    ) : (
                      <Tag color="#2db7f5">{res.verification_code}</Tag>
                    )}{" "}
                  </b>
                </li>
              </ul>
            </div>
          </div>

          <div className="btn">
            <div className="btnsection">
              {res.order_status === "cancelled" ||
              res.order_status === "upcoming" ||
              res.order_status === "delivered" ? (
                <button
                  className="orderbtn"
                  type="button"
                  onClick={() => DeleteOrder(res.id)}
                  style={{ color: "white" }}
                >
                  Delete
                </button>
              ) : (
                <button
                  className="orderbtn"
                  type="button"
                  onClick={() => Cancelmyorders(res.id)}
                  style={{ color: "white" }}
                >
                  Cancel Order
                </button>
              )}
            </div>
            {res.order_status === "cancelled" ? (
              ""
            ) : (
              <div className="small">
                <button
                  className=" orderbtn1"
                  type="button"
                  onClick={() => Rating(res.id)}
                >
                  Rate your Order
                </button>

                <button
                  className=" orderbtn1"
                  type="button"
                  onClick={() => Orderviewpage(res.id)}
                >
                  View more
                </button>
                {res.order_status === "unpaid" ? (
                  <Popconfirm
                    title="Confirm your payment"
                    onConfirm={confirm}
                    okText="Yes"
                    cancelText="No"
                  >
                    {" "}
                    <button
                      onClick={() => Paymentsclick(res.id)}
                      type="button"
                      style={{
                        width: 150,
                        backgroundColor: "#4BB543",
                        border: "none",
                        borderRadius: 6,
                        color: "white",
                        padding: 3,
                      }}
                    >
                      Advance Payment
                    </button>
                  </Popconfirm>
                ) : (
                  ""
                )}
                {res.order_status === "up-coming" ? (
                  <Popconfirm
                    title="Confirm your payment"
                    onConfirm={confirm}
                    okText="Yes"
                    cancelText="No"
                  >
                    <button
                      style={{
                        width: 150,
                        backgroundColor: "#4BB543",
                        border: "none",
                        borderRadius: 6,
                        color: "white",
                        padding: 3,
                      }}
                      type="button"
                      onClick={() => Finalpaymentpostreq(res.id)}
                    >
                      Final Payment
                    </button>
                  </Popconfirm>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        </div>
      ))}
    </Form1order>
  );
}

//Form placed.................................................................................................................................
function Formplaced({ placed, Orderviewpage, Cancelmyorders, Moment, Rating }) {
  // if(isLoading) {
  //   return <pl>loading.........</pl>
  // }

  return (
    <Form2order>
      {placed?.map((res) => (
        <div className="Box" data-aos="fade-left">
          <div className="box">
            <div className="left">
              <h5>{res.id}</h5>
              <ul>
                <li>
                  Order date :{" "}
                  <b>
                    <Moment format="D MMM YYYY HH:mm">{res.ordered_at}</Moment>
                  </b>
                </li>
                <li>
                  Order status : Order status :
                  {res.order_status === "up-coming" ? (
                    <Tag color="magenta">{res.order_status}</Tag>
                  ) : res.order_status === "cancelled" ? (
                    <Tag color="red">{res.order_status}</Tag>
                  ) : res.order_status === "delivered" ? (
                    <Tag color="purple">{res.order_status}</Tag>
                  ) : res.order_status === "completed" ? (
                    <Tag color="green">{res.order_status}</Tag>
                  ) : res.order_status === "placed" ? (
                    <Tag color="cyan">{res.order_status}</Tag>
                  ) : res.order_status === "unpaid" ? (
                    <Tag color="volcano">{res.order_status}</Tag>
                  ) : (
                    ""
                  )}
                </li>
                <li>
                  Event Name: <b>{res.event_name}</b>
                </li>
                <li>
                  Due amount :<b>₹ {res.due_amount}</b>
                </li>
                <li>
                  Order Total :<b>₹ {res.total_amount}</b>
                </li>
                <li>
                  <b>Ordered from : {res.shop_name}</b>
                </li>

                <li>
                  <b>Last date For advance : {res.advance_due_date}</b>
                </li>
                <li>
                  <b>
                    Verification code :{" "}
                    {res.verification_code === null ? (
                      <Tag color="red">Null</Tag>
                    ) : (
                      <Tag color="#2db7f5">{res.verification_code}</Tag>
                    )}{" "}
                  </b>
                </li>
              </ul>
            </div>
            {/* <div className="right">
              <h3>Items</h3>
              <ul>
                <li>Shawarma : X 100 plate</li>
              </ul>
            </div> */}
          </div>
          <div className="btn">
            <div className="btnsection">
              {/* <button className="mailbtn">
                <MailOutlined />
              </button> */}
              {res.order_status === "upcoming" ? (
                ""
              ) : (
                <button
                  className="orderbtn"
                  type="button"
                  onClick={() => Cancelmyorders(res.id)}
                >
                  Cancel Order
                </button>
              )}
              <div className="small">
                <button
                  className=" orderbtn1"
                  type="button"
                  onClick={() => Rating(res.id)}
                >
                  Rate your Order
                </button>
                <button
                  className=" orderbtn1"
                  type="button"
                  onClick={() => Orderviewpage(res.id)}
                >
                  View more
                </button>
                {res.order_status === "unpaid" ? (
                  <button
                    type="button"
                    style={{
                      width: 150,
                      backgroundColor: "#4BB543",
                      border: "none",
                      borderRadius: 6,
                      color: "white",
                      padding: 3,
                    }}
                  >
                    Advance Payment
                  </button>
                ) : (
                  ""
                )}
                {res.order_status === "upcoming" ? (
                  <button
                    style={{
                      width: 150,
                      backgroundColor: "#4BB543",
                      border: "none",
                      borderRadius: 6,
                      color: "white",
                      padding: 3,
                    }}
                    type="button"
                  >
                    Final Payment
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </Form2order>
  );
}
//form Rejected....................................................................................................................
function FormRejected({
  Rejected,
  Orderviewpage,
  Cancelmyorders,
  Moment,
  Rating,
  isLoading,
  DeleteOrder,
}) {
  // if(isLoading) {
  //   return <pl>loading.........</pl>
  // }
  return (
    <Form3order>
      {Rejected?.map((res) => (
        <div className="Box" data-aos="fade-left">
          <div className="box">
            <div className="left">
              <h5>{res.id}</h5>
              <ul>
                <li>
                  Order date :{" "}
                  <b>
                    <Moment format="D MMM YYYY HH:mm">{res.ordered_at}</Moment>
                  </b>
                </li>
                <li>
                  Order status :
                  {res.order_status === "up-coming" ? (
                    <Tag color="magenta">{res.order_status}</Tag>
                  ) : res.order_status === "cancelled" ? (
                    <Tag color="red">{res.order_status}</Tag>
                  ) : res.order_status === "delivered" ? (
                    <Tag color="purple">{res.order_status}</Tag>
                  ) : res.order_status === "completed" ? (
                    <Tag color="green">{res.order_status}</Tag>
                  ) : res.order_status === "placed" ? (
                    <Tag color="cyan">{res.order_status}</Tag>
                  ) : res.order_status === "unpaid" ? (
                    <Tag color="volcano">{res.order_status}</Tag>
                  ) : res.order_status === "rejected" ? (
                    <Tag color="volcano">{res.order_status}</Tag>
                  ) : (
                    ""
                  )}
                </li>
                <li>
                  Event Name : <b>{res.event_name}</b>
                </li>
                <li>
                  Due amount :<b>₹ {res.due_amount}</b>
                </li>
                <li>
                  Order Total :<b>₹ {res.total_amount}</b>
                </li>
                <li>
                  <b>Ordered from : {res.shop_name}</b>
                </li>

                <li>
                  <b>Last date For advance : {res.advance_due_date}</b>
                </li>
                <li>
                  <b>
                    Verification code :{" "}
                    {res.verification_code === null ? (
                      <Tag color="red">Null</Tag>
                    ) : (
                      <Tag color="#2db7f5">{res.verification_code}</Tag>
                    )}{" "}
                  </b>
                </li>
              </ul>
            </div>
          </div>
          <div className="btn">
            <div className="btnsection">
              <div className="small">
                <button
                  className=" orderbtn1"
                  type="button"
                  style={{ height: "30px" }}
                  onClick={() => DeleteOrder(res.id)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Form3order>
  );
}

//form cancell....................................................................................................................
function FormCancelled({
  Cancelled,
  Orderviewpage,
  Cancelmyorders,
  Moment,
  Rating,
  isLoading,
  DeleteOrder,
}) {
  // if(isLoading) {
  //   return <pl>loading.........</pl>
  // }
  return (
    <Form3order>
      {Cancelled?.map((res) => (
        <div className="Box" data-aos="fade-left">
          <div className="box">
            <div className="left">
              <h5>{res.id}</h5>
              <ul>
                <li>
                  Order date :{" "}
                  <b>
                    <Moment format="D MMM YYYY HH:mm">{res.ordered_at}</Moment>
                  </b>
                </li>
                <li>
                  Order status :
                  {res.order_status === "up-coming" ? (
                    <Tag color="magenta">{res.order_status}</Tag>
                  ) : res.order_status === "cancelled" ? (
                    <Tag color="red">{res.order_status}</Tag>
                  ) : res.order_status === "delivered" ? (
                    <Tag color="purple">{res.order_status}</Tag>
                  ) : res.order_status === "completed" ? (
                    <Tag color="green">{res.order_status}</Tag>
                  ) : res.order_status === "placed" ? (
                    <Tag color="cyan">{res.order_status}</Tag>
                  ) : res.order_status === "unpaid" ? (
                    <Tag color="volcano">{res.order_status}</Tag>
                  ) : (
                    ""
                  )}
                </li>
                <li>
                  Event Name : <b>{res.event_name}</b>
                </li>
                <li>
                  Due amount :<b>₹ {res.due_amount}</b>
                </li>
                <li>
                  Order Total :<b>₹ {res.total_amount}</b>
                </li>
                <li>
                  <b>Ordered from : {res.shop_name}</b>
                </li>

                <li>
                  <b>Last date For advance : {res.advance_due_date}</b>
                </li>
                <li>
                  <b>
                    Verification code :{" "}
                    {res.verification_code === null ? (
                      <Tag color="red">Null</Tag>
                    ) : (
                      <Tag color="#2db7f5">{res.verification_code}</Tag>
                    )}{" "}
                  </b>
                </li>
              </ul>
            </div>
          </div>
          <div className="btn">
            <div className="btnsection">
              <div className="small">
                <button
                  className=" orderbtn1"
                  type="button"
                  style={{ height: "30px" }}
                  onClick={() => DeleteOrder(res.id)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Form3order>
  );
}

//form Unpaid ......................................................................................................................

function FormUnpaid({
  unpaid,
  Orderviewpage,
  Cancelmyorders,
  Paymentsclick,
  Moment,
  Rating,
  isLoading,
  confirm,
}) {
  // if(isLoading) {
  //   return <pl>loading.........</pl>
  // }
  return (
    <Form4order>
      {unpaid?.map((res) => (
        <div className="Box" data-aos="fade-left">
          <div className="box">
            <div className="left">
              <h5>{res.id}</h5>
              <ul>
                <li>
                  Order date :{" "}
                  <b>
                    <Moment format="D MMM YYYY HH:mm">{res.ordered_at}</Moment>
                  </b>
                </li>
                <li>
                  Order status :
                  {res.order_status === "up-coming" ? (
                    <Tag color="magenta">{res.order_status}</Tag>
                  ) : res.order_status === "cancelled" ? (
                    <Tag color="red">{res.order_status}</Tag>
                  ) : res.order_status === "delivered" ? (
                    <Tag color="purple">{res.order_status}</Tag>
                  ) : res.order_status === "completed" ? (
                    <Tag color="green">{res.order_status}</Tag>
                  ) : res.order_status === "placed" ? (
                    <Tag color="cyan">{res.order_status}</Tag>
                  ) : res.order_status === "unpaid" ? (
                    <Tag color="volcano">{res.order_status}</Tag>
                  ) : (
                    ""
                  )}
                </li>
                <li>
                  Event Name: <b>{res.event_name}</b>
                </li>
                <li>
                  Due amount :<b>₹ {res.due_amount}</b>
                </li>
                <li>
                  Order Total :<b>₹ {res.total_amount}</b>
                </li>
                <li>
                  <b>Ordered from : {res.shop_name}</b>
                </li>

                <li>
                  <b>Last date For advance : {res.advance_due_date}</b>
                </li>
                <li>
                  <b>
                    Verification code :{" "}
                    {res.verification_code === null ? (
                      <Tag color="red">Null</Tag>
                    ) : (
                      <Tag color="#2db7f5">{res.verification_code}</Tag>
                    )}{" "}
                  </b>
                </li>
              </ul>
            </div>
          </div>
          <div className="btn">
            <div className="btnsection">
              {/* <button className="mailbtn">
                <MailOutlined />
              </button> */}

              <div className="small">
                {res.order_status === "upcoming" ? (
                  ""
                ) : (
                  <button
                    className="orderbtn"
                    type="button"
                    onClick={() => Cancelmyorders(res.id)}
                  >
                    Cancel Order
                  </button>
                )}
                <button
                  className=" orderbtn1"
                  type="button"
                  onClick={() => Rating(res.id)}
                >
                  Rate your Order
                </button>
                <button
                  className=" orderbtn1"
                  type="button"
                  onClick={() => Orderviewpage(res.id)}
                >
                  View more
                </button>
                {res.order_status === "unpaid" ? (
                  <Popconfirm
                    title="Confirm your payment"
                    onConfirm={confirm}
                    okText="Yes"
                    cancelText="No"
                  >
                    {" "}
                    <button
                      onClick={() => Paymentsclick(res.id)}
                      type="button"
                      style={{
                        width: 150,
                        backgroundColor: "#4BB543",
                        border: "none",
                        borderRadius: 6,
                        color: "white",
                        padding: 3,
                      }}
                    >
                      Advance Payment
                    </button>
                  </Popconfirm>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </Form4order>
  );
}

//Form  Upcoming.......................................................................................................
function FormUpcoming({
  upcomingres,
  Orderviewpage,
  Cancelmyorders,
  Moment,
  Finalpaymentpostreq,
  Rating,
  isLoading,
  confirm,
}) {
  // if(isLoading){
  //   return <p>Loading...........</p>;
  // }
  return (
    <Form5order>
      {upcomingres?.map((res) => (
        <div className="Box" data-aos="fade-left">
          <div className="box">
            <div className="left">
              <h5>{res.id}</h5>
              <ul>
                <li>
                  Order date :{" "}
                  <b>
                    <Moment format="D MMM YYYY HH:mm">{res.ordered_at}</Moment>
                  </b>
                </li>
                <li>
                  {" "}
                  Order status :
                  {res.order_status === "up-coming" ? (
                    <Tag color="magenta">{res.order_status}</Tag>
                  ) : res.order_status === "cancelled" ? (
                    <Tag color="red">{res.order_status}</Tag>
                  ) : res.order_status === "delivered" ? (
                    <Tag color="purple">{res.order_status}</Tag>
                  ) : res.order_status === "completed" ? (
                    <Tag color="green">{res.order_status}</Tag>
                  ) : res.order_status === "placed" ? (
                    <Tag color="cyan">{res.order_status}</Tag>
                  ) : res.order_status === "unpaid" ? (
                    <Tag color="volcano">{res.order_status}</Tag>
                  ) : (
                    ""
                  )}
                </li>
                <li>
                  Event Name : <b>{res.event_name}</b>
                </li>
                <li>
                  Due amount :<b>₹ {res.due_amount}</b>
                </li>
                <li>
                  Order Total :<b>₹ {res.total_amount}</b>
                </li>
                <li>
                  <b>Ordered from : {res.shop_name}</b>
                </li>

                <li>
                  <b>Last date For advance : {res.advance_due_date}</b>
                </li>
                <li>
                  <b>
                    Verification code :{" "}
                    {res.verification_code === null ? (
                      <Tag color="red">Null</Tag>
                    ) : (
                      <Tag color="#2db7f5">{res.verification_code}</Tag>
                    )}{" "}
                  </b>
                </li>
              </ul>
            </div>
          </div>
          <div className="btn">
            <div className="btnsection">
              {/* <button className="mailbtn">
                <MailOutlined />
              </button> */}
              {res.order_status === "upcoming" ? (
                ""
              ) : (
                <button
                  className="orderbtn"
                  type="button"
                  onClick={() => Cancelmyorders(res.id)}
                >
                  Cancel Order
                </button>
              )}
              <div className="small">
                <button
                  className=" orderbtn1"
                  type="button"
                  onClick={() => Rating(res.id)}
                >
                  Rate your Order
                </button>
                <button
                  className=" orderbtn1"
                  type="button"
                  onClick={() => Orderviewpage(res.id)}
                >
                  View more
                </button>
                {res.order_status === "up-coming" ? (
                  <Popconfirm
                    title="Confirm your payment"
                    onConfirm={confirm}
                    okText="Yes"
                    cancelText="No"
                  >
                    <button
                      style={{
                        width: 150,
                        backgroundColor: "#4BB543",
                        border: "none",
                        borderRadius: 6,
                        color: "white",
                        padding: 3,
                      }}
                      type="button"
                      onClick={() => Finalpaymentpostreq(res.id)}
                    >
                      Final Payment
                    </button>
                  </Popconfirm>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </Form5order>
  );
}
//Form Completed..........................................................

function FormCompleted({
  Completedres,
  Orderviewpage,
  Cancelmyorders,
  Moment,
  Rating,
}) {
  return (
    <Form6order>
      {Completedres?.map((res) => (
        <div className="Box" data-aos="fade-left">
          <div className="box">
            <div className="left">
              <h5>{res.id}</h5>
              <ul>
                <li>
                  Order date :{" "}
                  <b>
                    <Moment format="D MMM YYYY HH:mm">{res.ordered_at}</Moment>
                  </b>
                </li>
                <li>
                  {" "}
                  Order status :
                  {res.order_status === "up-coming" ? (
                    <Tag color="magenta">{res.order_status}</Tag>
                  ) : res.order_status === "cancelled" ? (
                    <Tag color="red">{res.order_status}</Tag>
                  ) : res.order_status === "delivered" ? (
                    <Tag color="purple">{res.order_status}</Tag>
                  ) : res.order_status === "completed" ? (
                    <Tag color="green">{res.order_status}</Tag>
                  ) : res.order_status === "placed" ? (
                    <Tag color="cyan">{res.order_status}</Tag>
                  ) : res.order_status === "unpaid" ? (
                    <Tag color="volcano">{res.order_status}</Tag>
                  ) : (
                    ""
                  )}
                </li>
                <li>
                  Event Name: <b>{res.event_name}</b>
                </li>
                <li>
                  Due amount :<b>₹ {res.due_amount}</b>
                </li>
                <li>
                  Order Total :<b>₹ {res.total_amount}</b>
                </li>
                <li>
                  <b>Ordered from : {res.shop_name}</b>
                </li>

                <li>
                  <b>Last date For advance : {res.advance_due_date}</b>
                </li>
                <li>
                  <b>
                    Verification code :{" "}
                    {res.verification_code === null ? (
                      <Tag color="red">Null</Tag>
                    ) : (
                      <Tag color="#2db7f5">{res.verification_code}</Tag>
                    )}{" "}
                  </b>
                </li>
              </ul>
            </div>
          </div>
          <div className="btn">
            <div className="btnsection">
              {/* <button className="mailbtn">
              <MailOutlined />
            </button>  */}
              {/* {res.order_status === "upcoming" ? (
                ""
              ) : (
                <button
                  className="orderbtn"
                  type="button"
                  onClick={() => Cancelmyorders(res.id)}
                >
                  Cancel Order
                </button>
              )} */}
              <div className="small">
                <button
                  className="orderbtn1"
                  type="button"
                  onClick={() => Rating(res.id)}
                >
                  Rate your Order
                </button>
                <button
                  className="orderbtn1"
                  type="button"
                  onClick={() => Orderviewpage(res.id)}
                >
                  View more
                </button>

                {res.order_status === "upcoming" ? (
                  <button
                    style={{
                      width: 150,
                      backgroundColor: "#4BB543",
                      border: "none",
                      borderRadius: 6,
                      color: "white",
                      padding: 3,
                    }}
                    type="button"
                  >
                    Final Payment
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </Form6order>
  );
}
//Form7delivered......................................
function FormDelivered({
  Orderviewpage,
  Cancelmyorders,
  Moment,
  Rating,
  Deliveredres,
}) {
  return (
    <Form6order>
      {Deliveredres?.map((res) => (
        <div className="Box" data-aos="fade-left">
          <div className="box">
            <div className="left">
              <h5>{res.id}</h5>
              <ul>
                <li>
                  Order date :{" "}
                  <b>
                    <Moment format="D MMM YYYY HH:mm">{res.ordered_at}</Moment>
                  </b>
                </li>
                <li>
                  {" "}
                  Order status :
                  {res.order_status === "up-coming" ? (
                    <Tag color="magenta">{res.order_status}</Tag>
                  ) : res.order_status === "cancelled" ? (
                    <Tag color="red">{res.order_status}</Tag>
                  ) : res.order_status === "delivered" ? (
                    <Tag color="purple">{res.order_status}</Tag>
                  ) : res.order_status === "completed" ? (
                    <Tag color="green">{res.order_status}</Tag>
                  ) : res.order_status === "placed" ? (
                    <Tag color="cyan">{res.order_status}</Tag>
                  ) : res.order_status === "unpaid" ? (
                    <Tag color="volcano">{res.order_status}</Tag>
                  ) : (
                    ""
                  )}
                </li>
                <li>
                  Event Name: <b>{res.event_name}</b>
                </li>
                <li>
                  Due amount :<b>₹ {res.due_amount}</b>
                </li>
                <li>
                  Order Total :<b>₹ {res.total_amount}</b>
                </li>
                <li>
                  <b>Ordered from : {res.shop_name}</b>
                </li>

                <li>
                  <b>Last date For advance : {res.advance_due_date}</b>
                </li>
                <li>
                  <b>
                    Verification code :{" "}
                    {res.verification_code === null ? (
                      <Tag color="red">Null</Tag>
                    ) : (
                      <Tag color="#2db7f5">{res.verification_code}</Tag>
                    )}{" "}
                  </b>
                </li>
              </ul>
            </div>
          </div>
          <div className="btn">
            <div className="btnsection">
              {/* <button className="mailbtn">
              <MailOutlined />
            </button>  */}
              {/* {res.order_status === "upcoming" ? (
                ""
              ) : (
                <button
                  className="orderbtn"
                  type="button"
                  onClick={() => Cancelmyorders(res.id)}
                >
                  Cancel Order
                </button>
              )} */}
              <div className="small">
                <button
                  className="orderbtn1"
                  type="button"
                  onClick={() => Rating(res.id)}
                >
                  Rate your Order
                </button>
                <button
                  className="orderbtn1"
                  type="button"
                  onClick={() => Orderviewpage(res.id)}
                >
                  View more
                </button>

                {res.order_status === "upcoming" ? (
                  <button
                    style={{
                      width: 150,
                      backgroundColor: "#4BB543",
                      border: "none",
                      borderRadius: 6,
                      color: "white",
                      padding: 3,
                    }}
                    type="button"
                  >
                    Final Payment
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </Form6order>
  );
}
