import { Getratingsreview } from "../../../infrastructure/Rating";
import { notification } from "antd";
const actions = {
  setLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setRating:
    (rating) =>
    ({ setState }) => {
      setState({ rating });
    },
  setHospitality:
    (hospitality) =>
    ({ setState }) => {
      setState({ hospitality });
    },
  setTaste:
    (taste) =>
    ({ setState }) => {
      setState({ taste });
    },
  setPrice:
    (price) =>
    ({ setState }) => {
      setState({ price });
    },
  setQuality:
    (quality) =>
    ({ setState }) => {
      setState({ quality });
    },
  setQuantity:
    (quantity) =>
    ({ setState }) => {
      setState({ quantity });
    },
  setPacking:
    (packing) =>
    ({ setState }) => {
      setState({ packing });
    },
  setTiming:
    (timing) =>
    ({ setState }) => {
      setState({ timing });
    },
  setWastemanagement:
    (wastemanagement) =>
    ({ setState }) => {
      setState({ wastemanagement });
    },
  setComment:
    (comment) =>
    ({ setState }) => {
      setState({ comment });
    },
  setiddd:
    (order_id) =>
    ({ setState }) => {
      setState({ order_id });
    },
  Getmyratingdata:
    (history) =>
    async ({ dispatch, getState }) => {
      dispatch(actions.setLoading(true));
      try {
        const {
          hospitality,
          taste,
          price,
          quality,
          quantity,
          packing,
          timing,
          wastemanagement,
          comment,
          order_id,
        } = getState();
        let formData = new FormData();
        formData.append("order_id", order_id);
        formData.append("comment", comment);
        formData.append("hospitality", hospitality);
        formData.append("taste", taste);
        formData.append("price", price);
        formData.append("quality", quality);
        formData.append("quantity", quantity);
        formData.append("packing", packing);
        formData.append("timing", timing);
        formData.append("waste_management", wastemanagement);
        await Getratingsreview(formData);
        history.push(`/order`);
        notification["success"]({
          message: "success",
          description: "Ratings Submited Successfully",
        });
      } catch (error) {
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
};
export default actions;
