import styled from "styled-components";
import picture from "../../Images/bannerimage.jpg";
// import picture from "../../Images/banner.jpg"
import Colors from "../common/Colors/Colors"
export const DIV = styled.div`

  ${
    "" /* background: linear-gradient(
      180deg,
      rgba(225, 172, 37, 0.16) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(0deg, rgba(11, 23, 35, 0.43), rgba(11, 23, 35, 0.43)),
    url(${picture}); */
  }
 
  .head{
    #recaptcha-div {
      display: flex;
      justify-content: center;
    }
    
    background:url(${picture});
    background-blend-mode: multiply, multiply, normal;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 100px;

    @media(max-width:402px){
      padding-top:unset;
    }
   
  }
  .top {
    width: 80%;
    display: flex;
    justify-content: space-between;
  
      @media(max-width:762px){
        flex-direction:column;
        align-items: center;
      }
     
  }
 
    
  .main {
   
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media(max-width:1080px){
      width:610px;
    }
    @media(max-width:980px){
      width:570px;
    }
    @media(max-width:932px){
      width:548px;
    }
   @media(max-width:762px){
     width:unset;
     justify-content:center;
     align-items:center;

   }
  
  
  }
  .banner-content {
    display: flex;
    justify-content: center;
    width: 614px;
    h1 {
      margin: 0px;
      padding: 0px;
      color: white;
      font-size: 55px;
      width: 22ch;
      animation: typing 2s steps(22), blink 0.5s step-end infinite alternate;
      white-space: nowrap;
      overflow: hidden;
      border-right: 3px solid;
      font-family: "paytone_oneregular";
      @media(max-width:1500px){
        font-size:46px;
      }
      @media(max-width:1317px){
        font-size:46px;
      }
      @media(max-width:1080px){
        font-size:44px;
      }
      @media (max-width: 980px) {
        font-size:38px;
      }
      
     
      @media (max-width: 920px) {
        font-size:35px;
      }
      @media (max-width: 880px) {
        font-size:33px;
      }
      @media (max-width: 880px) {
        font-size:32px;
      }
      @media(max-width:762px){
        font-size:33px;
      }
      @media(max-width:536px){
        font-size:30px;
      }
      @media(max-width:536px){
        font-size:28px;
      }
      @media(max-width:470px){
        font-size:25px;
      }
      @media(max-width:402px){
        font-size:23px;
      }
      @media(max-width:364px){
        font-size:20px;
      }
      
    }
    @media(max-width:1500px){
      width:550px;
    }
    @media(max-width:1317px){
      width:536px;
    }
    @media(max-width:1080px){
      width:526px;
    }
    @media(max-width:980px){
      width:450px;
    }
    @media(max-width:920px){
      width:400px;
    }
    @media(max-width:880px){
      width:380px;
    }
    @media(max-width:780px){
      width:361px;
    }
    @media(max-width:536px){
      width:318px;
    }
    @media(max-width:470px){
      width:288px;
    }
    @media(max-width:402px){
      width:240px;
    }
    @media(max-width:344px){
      width:208px;
    }
    }
    @keyframes typing {
      from {
        width: 0;
      }
    }
  }
  .quotes {
    display: flex;
    p {
      margin: 0px;
      padding: 0px;
      font-style: italic;
      font-weight: normal;
      font-size: 62.631px;
      color:white;
      letter-spacing:1px;
      line-height: 83px;
      /* identical to box height */
      font-family: "paytone_oneregular";
      font-size: 20px;
      @media(max-width:1500px){
        font-size:16px;
      }
     
      @media(max-width:1317px){
        font-size:17px;
      }
      @media(max-width:1080px){
        font-size:16px;
      }
      @media(max-width:980px){
        font-size:16px;
      }
   
      @media(max-width:920px){
        font-size:16px;
      }
      @media(max-width:880px){
        font-size:13px;
      }
      @media(max-width:762px){
        font-size:18px;
      }
      @media(max-width:602px){
        font-size:16px;
      }
      @media(max-width:536px){
        font-size:14px;
        line-height:50px;
      }
      @media(max-width:470px){
        font-size:12px;
      }
      @media(max-width:402px){
        font-size:11px;
        line-height:40px;
      }
      @media(max-width:364px){
        font-size:10px;
      }
      @media(max-width:328px){
        font-size:9px;
      }
    }
   
  }
  .name {
    display: flex;
    justify-content: center;
    font-size: 19px;
    color: white;
    font-weight: bold;
    @media(max-width:1500px){
      font-size: -0.125em;
    }
    @media(max-width:980px){
      font-size:18px;
    }
    @media(max-width:920px){
      font-size:17px;
    }
    @media(max-width:880px){
      font-size:15px;
    }
    @media(max-width:364px){
      font-size:12px;
    }
  }

  .signup-page:before {
    content: "";
    position: absolute;
    background: inherit;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 2000px;
    margin: -20px;
  }
  
    
  .signup-page {
    width: 30rem;
    height: 28rem;
    box-shadow: 0 0 5px 0 white;
    border-radius: 20px;
    position: relative;
    z-index: 1;
    background: inherit;
    overflow: hidden;
    margin-bottom: 100px;
   @media(max-width:762px){
     margin-top:30px;
   }
      
    .signup {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 140px;
    
      h1 {
        font-family: "dm_sansregular";
        font-size: 40px;
        font-weight: bold;
        color: white;
        @media(max-width:1080px){
          font-size:37px;
        }
        @media(max-width:880px){
          font-size:31px;
        }
        @media(max-width:780px){
          font-size:28px;
        }
        @media(max-width:536px){
          font-size:30px;
        }
        @media(max-width:402px){
          font-size:23px;
        }
      }
      @media(max-width:1500px){
        margin-bottom:80px;
      }
      @media(max-width:1173px){
        margin-bottom:76px;
      }
      @media(max-width:1080px){
        margin-bottom:66px;
      }
      @media(max-width:980px){
        margin-bottom:55px;
      }
     
      @media(max-width:880px){
        margin-bottom:38px;
      }
      @media(max-width:780px){
        margin-bottom:43px;
      }
      @media(max-width:402px){
        margin-bottom:50px;
      }
    }
    
    .input {
      display: flex;
      justify-content: center;
      margin-top: 30px;
      align-items: center;
      border-radius: 8px;
      .box {
        display: flex;
        justify-content: center;
        width: 300px;
        align-items: center;
        label {
          font-family: "dm_sansregular";
          color: white;
          font-size: 18px;
          font-weight: bold;
        }
        input {
          width: 300px;
          height: 40px;
          border-radius: 20px;
          padding-left: 10px;
          border-color: white;
          outline: none;
          font-size: 18px;
          @media(max-width:1173px){
              width:285px;
              height:34px;
          }
          @media (max-width: 1080px) {
           width:242px;
           height:32px;
          }
          @media(max-width:980px){
            width: 220px;
            height: 32px;
            font-size:16px;
          }
          @media(max-width:920px){
            width:230px;
            height:36px;
           
          }
          @media(max-width:880px){
            width: 194px;
            height: 30px;
          }
          @media(max-width:762px){
            width: 211px;
            height: 34px;
          }
          @media(max-width:402px){
            width:200px;
            height:30px;
            font-size:16px;
          }
        
        }
        input::placeholder {
          color: black;
        }
      }
      .box1 {
        display: flex;
        justify-content: center;
        width: 300px;
        align-items: center;
        label {
          font-family: "dm_sansregular";
          font-size: 16px;
          font-weight: bold;
        }
        input {
          width: 300px;
          height: 40px;
          border-radius: 20px;
          padding-left: 10px;
          border-color: white;
          font-size: 18px;
          outline: none;
          @media(max-width:980px){
            width:240px;
            height:38px;
          }
          @media(max-width:920px){
            width:230px;
            height:36px;
          }
          @media(max-width:880px){
            width:208px;
            height:34px;
          }
          @media(max-width:402px){
            width:200px;
            height:30px;
            font-size:16px;
          }
          @media(max-width:364px){
            width:180px;
            height:25px;
            font-size:15px;
          }
         
        }
        input::placeholder {
          color: grey;
        }
      }
    }
    .button {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      button {
        background: ${Colors.loginbutton};
        padding: 5px;
        width: 290px;
        border: none;
        height: 50px;
        border-radius: 8px;
        font-size: 17px;
        font-family: "dm_sansregular";
        font-weight: bold;
        color: white;
        text-align: center;
        cursor: pointer;
        &:active {
          background-color: black;
          color: white;
        }
      @media(max-width:1500px){
        height:38px;
      }
      @media(max-width:1173px){
         height:34px;
         width:276px;
         font-size:16px;
      }
      @media(max-width:1080px){
         height: 32px;
         width: 235px;
         font-size: 14px;
      }
      @media(max-width:980px){
        width: 216px;
        height: 30px;
      }
    
      @media(max-width:880px){
        width:190px;
        height:28px;
      }
      @media(max-width:762px){
        width: 215px;
        height: 36px;
      }
      @media(max-width:402px){
        width:190px;
        height:30px;
        font-size:15px;
      }
     
      }
        
    }
  
    @media(max-width:1500px){
      width:23rem;
      height:21rem;
    }
    @media(max-width:1173px){
      width:21rem;
      height:18rem;
    }
    @media(max-width:1080px){
      width:21rem;
      height:18rem;
    }
    @media(max-width:980px){
      height:16rem;
      width:20rem;
    }
    @media(max-width:880px){
      height:14rem;
    }
    @media(max-width:780px){
      height:14rem;
    }
    @media(max-width:762px){
    width:20rem;
    height:16rem;
    }
    @media(max-width:536px){
      width:17rem;
    }
  
  }

`;
