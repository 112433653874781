import React from "react";
import pizza from "../../../../Images/spinnerpiza.gif";
function Pizzaspinner() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        paddingBottom: 280,
        paddingTop: 280,
      }}
    >
      <img src={pizza} style={{ width: 100 }}></img>
    </div>
  );
}

export default Pizzaspinner;
