import React from "react";
import { Banner } from "./style";
import plate from "../../Images/plates.png";
function Count() {
  return (
    <Banner>
      <div className="banner-section">
        <div className="content-section1">
          <div className="section">
            <div className="img">
              <img src={plate} alt="" />
              <div className="details">
                <h3>275</h3>
                <span>Total Orders</span>
              </div>
            </div>
            <div className="img">
              <img src={plate} alt="" />
              <div className="details">
                <h3>925</h3>
                <span>Active Restaurants</span>
              </div>
            </div>
            <div className="img">
              <img src={plate} alt="" />
              <div className="details">
                <h3>989</h3>
                <span>Daily Traffic</span>
              </div>
            </div>
            <div className="img">
              <img src={plate} alt="" />
              <div className="details">
                <h3>125</h3>
                <span>Happy Customers</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Banner>
  );
}

export default Count;
