const initialState = {
  isLoading: true,
  mycart: [],
  Cartt:"",
  Removedata:"",
  quantity:"",
  // carttotal:"",
  rerendercart: false,
  
};
export default initialState;
