import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Footer from "./presentation/Footer";
import Header from "./presentation/Header";
import Home from "./presentation/HomePage";
import LoginPage from "./presentation/Loginpage";
import Aboutus from "./presentation/About";
import Contactus from "./presentation/Contact";
import EventPage from "./presentation/EventsPage";
import EventCreate from "./presentation/Eventcreate";
import Cart from "./presentation/CartPage";
import CartSummary from "./presentation/Cartsummary";
import Orders from "./presentation/OrderPlace/index";
import FoodTypes from "./presentation/ShopTypes";
import "././App.css";
import Profile from "./presentation/UserProfile";
import Products from "./presentation/Products";
import "bootstrap/dist/css/bootstrap.min.css";
import Landing from "./presentation/common/Loadingpage";
import CartEmpty from "./presentation/CartPage/Component/EmptyCart";
import { firebase } from "./common/Firebase/index";
import Shopinformation from "./presentation/Shopinformation";
import Privacypolicy from "./presentation/PrivacyPolicy";
import Notifications from "./presentation/Notifications";
import Shopsearch from "./presentation/Shopsearch";
import Pagenotfound from "./presentation/PageNotFond";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Rating from "./presentation/Rating";
import UpdateEvent from "./presentation/UpdateEvent";
import ReturnPolicy from "./presentation/Return_Policy";
import TermsAndCondition from "./presentation/Terms & Condition";
import "react-chatbot-kit/build/main.css";
import { onMessageListener } from "./common/Firebase/index";
import Notificationsfirebase from "./presentation/Notifications/firebaseNotification/Notifications";
import ReactNotificationComponent from "./presentation/Notifications/firebaseNotification/ReactNotification";
import { Getmyfcm } from "./presentation/FCM/store";
import { getToken } from "./common/Firebase/index";
import { notification } from "antd";
import DetailedPage from "./presentation/Groceries/detailedPage";
export default function App() {
  const [{}, { setPhone_Number }] = Getmyfcm();
  const [isTokenFound, setTokenFound] = useState(false);
  getToken(setTokenFound);
  const [token, setToken] = useState("");
  const [phone, setphone] = useState();
  let user = firebase.auth().currentUser;

  localStorage.setItem("token", token);
  useEffect(() => {
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)

          .then((latestToken) => setToken(latestToken))

          .catch((err) => console.log(err));
      }
    });
  }, []);

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });


  onMessageListener()
    .then((payload) => {
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });

    })
    .catch((err) => console.log("failed: ", err));

  return (
    <BrowserRouter>
      <Header />
      {show ? "" : <></>}
      <Notificationsfirebase />
      <Switch>
        <Route exact path="/">
          <Landing />
        </Route>
        <Route exact path="/login">
          {user !== null ? <Redirect to="/home" /> : <LoginPage />}
        </Route>
      
        <Route exact path="/home"  component={Home} />
        {/* <Route exact path="/foodbuoy" component={Landing} /> */}
        <Route exact path="/about" component={Aboutus} />
        <Route exact path="/contact" component={Contactus} />
        <Route exact path="/eventpage">
          <EventPage />
        </Route>
        <Route exact path="/eventcreate" component={EventCreate} />
        <Route exact path="/cartpage" component={Cart} />
        <Route exact path="/cartsummary/:id" component={CartSummary} />
        <Route exact path="/order" component={Orders} />
        <Route exact path="/foodtypes/:id" component={FoodTypes} />
        <Route exact path="/account" component={Profile} />
        <Route exact path="/products/:id" component={Products} />
        <Route exact path="/cartpage/cartempty" component={CartEmpty} />
        <Route exact path="/shopinformation/:id" component={Shopinformation} />
        <Route exact path="/privacypolicy" component={Privacypolicy} />
        <Route exact path="/notification" component={Notifications} />
        <Route exact path="/shopsearch" component={Shopsearch} />
        <Route exact path="/rating" component={Rating} />
        <Route exact path="/updateevent/:id" component={UpdateEvent} />
        <Route exact path="/rating/:id" component={Rating} />
        <Route exact path="/returnPolicy" component={ReturnPolicy} />
        <Route exact path="/TermsAndConditions" component={TermsAndCondition} />
        <Route exact path="/deals" component={DetailedPage} />
        <Route component={Pagenotfound} />
       
      </Switch>
      <Footer />
      
    </BrowserRouter>
  );
}
