import { get, post } from "../common/remote/base_api";
export const Getshopview = (id) => {
  return get(`user/shop_view/${id}`);
};
export const GetFoodview = (id) => {
  return get(`user/shop_food_list/${id}`);
};
export const AddToCart = (data) => {
  return post(`user/update_cart/`, data);
};
