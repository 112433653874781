import { get } from "../common/remote/base_api";

export const Shoplistget = (id, idd) => {
  return get(
    `user/shop_list/type=${id}/category=${
      idd === "Restaurant"
        ? 1
        : idd === "Caters"
        ? 2
        : idd === "Chef"
        ? 3
        : idd === "All"
        ? 0
        : 0
    }/`
  );
};
