import React, { useEffect } from "react";
import Count from "../CountsLoginPage";
import Footerpart from "../Footerpart";
import Popup from "../Groceries/Popup";
import RequestForm from "../Groceries/RequestForm";

import Work from "../OurService";

function LoginPageCommon() {
  return (
    <div>
      <Popup />
      {/* <RequestForm /> */}
      <Work />
      <Count />
      <Footerpart />
    </div>
  );
}

export default LoginPageCommon;
