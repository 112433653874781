import React, { useEffect, useState } from "react";
import BackArrow from "../BackArrow";
import { Noti } from "./style";
import { Notificationlist } from "./store";
import { Tag, Divider } from "antd";
import { firebase } from "../../common/Firebase/index";
import { Badge, Avatar } from "antd";
import bell from "../../Images/bell.png";
import Loader from "../common/widgets/Loader";
import Colors from "../common/Colors/Colors";
import { Popconfirm } from "antd";
function Notifications() {
  const [
    { isLoading, Notify, DelNotfy },
    { setNotification, GetNotifications, setDelNotifi, DelNotifications },
  ] = Notificationlist();


  const [token, setToken] = useState("");
  useEffect(() => {
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
      if (token === "") {
      } else {
        GetNotifications();
      }
    });
  }, [token]);

  let gain = [];
  let mainreversesort = Notify;
  var firstRun =
    mainreversesort.length === 0
      ? gain
      : mainreversesort.sort(function (a, b) {
          return a.id < b.id;
        });

  let Not = Notify;
  let result = Not.map((foo) => foo.id);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <Noti>
      <div className="arrownoti" style={{ marginLeft: 50 }}>
        <BackArrow />
      </div>

      <div className="bellicon">
        <Badge count={Notify?.length}>
          <img src={bell} style={{ width: 60 }}></img>
        </Badge>
      </div>
      <div className="notisection">
        <div className="innersection">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "80%",
            }}
          >
            {Notify.length === 0 ? (
              ""
            ) : (
              <Popconfirm
                placement={"rightBottom"}
                title="Are you sure？"
                onConfirm={() => DelNotifications(setDelNotifi(result))}
              >
                <button
                  style={{
                    padding: 10,
                    borderRadius: 12,
                    backgroundColor: Colors.secondary,
                    border: "none",
                    color: "white",
                    height: 25,
                    display: "flex",
                    alignItems: "center",
                    width: 80,
                    justifyContent: "center",
                    fontSize: 12,
                  }}
                >
                  Delete All
                </button>
              </Popconfirm>
            )}
          </div>
          {firstRun.map((res) => (
            <div className="smallsection">
              {/* <div className="image">
                <img alt="" src={res?.notification_type?.image} />
              </div> */}
              <div className="noticontent">
                <small>
                  {" "}
                  <Tag color="purple">{res?.title}</Tag>
                </small>
                <small>
                  {" "}
                  <Tag color="green">{res?.subject}</Tag>
                </small>
                <small style={{ color: "green" }}>{res?.body}</small>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Noti>
  );
}

export default Notifications;
