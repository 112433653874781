import React, { useState, useEffect } from "react";
import { DIV } from "./style";
import { Createcustomer } from "../Loginpage/store/index";
import { useHistory } from "react-router-dom";
import LoginPageCommon from "../Logincommon";
import { useLocation } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import Popup from "../Popup";
function LoginPage() {
  const location = useLocation();

  let history = useHistory();
  const [
    { signres, number, otp, token, defaultnum },
    { onSubmit, setNumber, setOtp, sentVarify },
  ] = Createcustomer();

  useEffect(() => {
    window.addEventListener("keypress", (e) => {
      if (e.key === "Enter") {
        if (signres === null) {
          onSubmit();
        }
        if (signres !== null) {
          sentVarify();
        }
      }
    });
  }, [signres]);

  return (
    <DIV>
      <div className="head">
        <div className="top">
          <div className="main">
            <div className="banner-content">
              <h1 data-text="Find Fine Food Fast">Find Fine Food Fast</h1>
            </div>
            <div className="quotes">
              <p>"People Who Love to Eat Are Always the Best People..."</p>
            </div>
            <div className="name">
              <small>-Julia Child</small>
            </div>
          </div>
          {signres === null ? (
            <div className="signup-page">
              <div className="signup">
                <h1>Sign Up</h1>
              </div>
              <div className="input">
                <div className="box">
                  <input
                    maxlength="13"
                    type="text"
                    placeholder="Enter your Number"
                    onChange={(e) => setNumber(e.target.value)}
                    value={number}
                  ></input>
                </div>
              </div>
              <div id="recaptcha-container"></div>
              <div className="button">
                <button type="button" onClick={() => onSubmit()}>
                  Send OTP
                </button>
              </div>
            </div>
          ) : (
            <div className="signup-page">
              <div className="signup">
                <h1>Sign Up</h1>
              </div>
              <div className="input">
                <div className="box1">
                  <input
                    type="text"
                    placeholder="Enter your OTP"
                    onChange={(e) => setOtp(e.target.value)}
                    value={otp}
                  ></input>
                </div>
              </div>

              <div className="button">
                <button type="button" onClick={() => sentVarify(history)}>
                  Sign in
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {location.pathname === "/login" ? <LoginPageCommon /> : ""}
    </DIV>
  );
}

export default LoginPage;
