import { firebase } from "../../../common/Firebase";
import {
  CreateEvents,
  Dropdownevent,
} from "../../../infrastructure/EventCreate";
import { notification } from "antd";
import initialState from "./initialState";
const actions = {
  setLoading:
  (loading) =>
  ({ setState }) => {
    setState({ loading});
  },
  setEventname:
    (event_name) =>
    ({ setState }) => {
      setState({ event_name });
    },
  setname:
    (name) =>
    ({ setState }) => {
      setState({ name });
    },
  setdelivery_address:
    (delivery_address) =>
    ({ setState }) => {
      setState({ delivery_address });
    },
  setpincode:
    (pincode) =>
    ({ setState }) => {
      setState({ pincode });
    },
  setlandmark:
    (landmark) =>
    ({ setState }) => {
      setState({ landmark });
    },
  setlatitude:
    (latitude) =>
    ({ setState }) => {
      setState({ latitude });
    },
  setlongitude:
    (longitude) =>
    ({ setState }) => {
      setState({ longitude });
    },
  setdelivery_date:
    (delivery_date) =>
    ({ setState }) => {
      setState({ delivery_date });
    },
  setdelivery_time:
    (delivery_time) =>
    ({ setState }) => {
      setState({ delivery_time });
    },
  setcaretaker_name:
    (caretaker_name) =>
    ({ setState }) => {
      setState({ caretaker_name });
    },
  setcaretaker_contact:
    (caretaker_contact) =>
    ({ setState }) => {
      setState({ caretaker_contact });
    },
  setalternate_contact:
    (alternate_contact) =>
    ({ setState }) => {
      setState({ alternate_contact });
    },
  setgeo_location:
    (geo_location) =>
    ({ setState }) => {
      setState({ geo_location });
    },
  setLoading:
    (isloading) =>
    ({ setState }) => {
      setState({ isloading });
    },
  seteventfun:
    (eventfun) =>
    ({ setState }) => {
      setState({ eventfun });
    },
  setAddress:
    (address) =>
    ({ setState }) => {
      setState({ address });
    },
  setcoordinates:
    (coordinates) =>
    ({ setState }) => {
      setState({ coordinates });
    },

  onSubmit:
    (history) =>
    async ({ setState, dispatch, getState }) => {
      dispatch(actions.setLoading(true));
      const {
        event_name,
        name,
        delivery_address,
        pincode,
        landmark,
        latitude,
        longitude,
        delivery_date,
        delivery_time,
        caretaker_name,
        caretaker_contact,
        alternate_contact,
        geo_location,
        address,
      } = getState();

      try {
        if (event_name === "") {
          notification["warning"]({
            message: "warning",
            description: "Event name field is empty!",
          });
          return false;
        } else if (name === "") {
          notification["warning"]({
            message: "warning",
            description: "Name field is empty!",
          });
          return false;
        } else if (delivery_address === "") {
          notification["warning"]({
            message: "warning",
            description: "Delivery address field is empty!",
          });
          return false;
        } else if (pincode === "") {
          notification["warning"]({
            message: "warning",
            description: "pincode field is empty!",
          });
          return false;
        } else if (landmark === "") {
          notification["warning"]({
            message: "warning",
            description: "Landmark field is empty",
          });
          return false;
        } else if (delivery_date === null) {
          notification["warning"]({
            message: "warning",
            description: "Delivery date field is empty!",
          });
          return false;
        } else if (delivery_time === null) {
          notification["warning"]({
            message: "warning",
            description: "Delivery time field is empty!",
          });
          return false;
        } else if (caretaker_name === "") {
          notification["warning"]({
            message: "warning",
            description: "Caretaker name field is empty",
          });
          return false;
        } else if (caretaker_contact === "") {
          notification["warning"]({
            message: "warning",
            description: "Caretaker contact field is empty!",
          });
          return false;
        } else {
          await CreateEvents({
            event_name,
            name,
            delivery_address,
            pincode,
            landmark,
            latitude,
            longitude,
            delivery_date,
            delivery_time,
            caretaker_name,
            caretaker_contact,
            alternate_contact,
            geo_location,
            address,
          });

          history.push("/eventpage");
          notification["success"]({
            message: "success",
            description: "Event Created succesfully",
          });
        }
      } catch (err) {
        notification["warning"]({
          message: "warning",
          description: "something went wrong",
        });
      } finally {
        dispatch(actions.setLoading(false));
        dispatch(actions.setEventname(initialState.event_name));
        dispatch(actions.setname(initialState.name));
        dispatch(actions.setdelivery_address(initialState.delivery_address));
        dispatch(actions.setpincode(initialState.pincode));
        dispatch(actions.setlandmark(initialState.landmark));
        dispatch(actions.setcaretaker_name(initialState.caretaker_name));
        dispatch(actions.setcaretaker_contact(initialState.caretaker_contact));
        dispatch(actions.setalternate_contact(initialState.alternate_contact));
        dispatch(actions.setAddress(initialState.address));
        // setState({ event_name:initialState.event_name});
        // setState({name:initialState.name});
        // setState({delivery_address:initialState.delivery_address});
        // setState({pincode:initialState.pincode});
        // setState({landmark:initialState.landmark});
        // setState({caretaker_name:initialState.caretaker_name});
        // setState({caretaker_contact:initialState.caretaker_contact});
        // setState({ alternate_contact:initialState.alternate_contact});
        // setState({address:initialState.address});
      }
    },
  getoptions:
    () =>
    async ({ setState,dispatch }) => {
      dispatch(actions.setLoading(true));
      try {
        const res = await Dropdownevent();
        console.log(res, "res..............................");
        // setState({eventfun:res?.data?.data})
        dispatch(actions.seteventfun(res?.data?.data));
      } catch (err) {
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
};
export default actions;
