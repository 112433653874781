import React,{ useEffect} from "react";
import { createChatBotMessage } from "react-chatbot-kit";
import { OurProfile } from "../UserProfile/store";
import Avatar from "./Components/Avatar/Avatar";
import Closebutton from "./Components/CloseButton/Closebutton";

import Options from "./Components/Options/Options";
import Quiz from "./Components/Quiz/Quiz";
import UserAvatar from "./Components/UserAvatar/UserAvatar";
var today = new Date();
var curHr = today.getHours();
var time = null;

if (curHr < 12) {
  var time = "Good Morning";
} else if (curHr < 18) {
  var time = "Good Afternoon";
} else {
  var time = "Good Evening";
}




let gender = localStorage.getItem("gender");


var campare = null;

if (gender === "male") {
  var campare = "Sir,";
} else if (gender === "female") {
  var campare = "Madam,";
}else if (gender === "other"){
  var campare = "";
}else if (gender === null){
  var campare = ""
}

const botName = time;
const config = {
  botName: "Foodbuoy",

  initialMessages: [
    createChatBotMessage(` H i ! ${botName} ${campare} How can i help you?`, {
      widget: "options",
    }),
  ],
  customStyles: {
    botMessageBox: { backgroundColor: "#185db5" },
    chatButton: { backgroundColor: "rgb(21, 176, 140)" },
  },
  customComponents: {
    header: () => <Closebutton />,
    botAvatar: (props) => <Avatar {...props} />,
    userAvatar: (props) => <UserAvatar {...props} />,
  },
  widgets: [
    {
      widgetName: "options",
      widgetFunc: (props) => <Options {...props} />,
    },
  ],
};

export default config;
