import styled from "styled-components";
import backgroundimg from '../../Images/mainus.jpg'
export const Middle = styled.div`
  .page {
    background-image: url(${backgroundimg});
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .page-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    h1 {
      font-family: "paytone_oneregular";
      color: white;
      font-size: 45px;
      margin: 0px;
      padding: 0px;
      margin-top: 100px;
    }
    ul {
      list-style: none;
      display: flex;
      gap: 15px;
      font-size: 20px;
    }
  }
  .arrow {
    margin-bottom: 100px;
  }
  .page-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    text-align: center;

    .pagewidth {
      width: 70%;
      margin-top: 50px;
      margin-bottom: 50px;
    }
    p {
      font-family: "dm_sansregular";
      text-align: justify;
      line-height: 30px;
      font-size: 18px;
    }
    h1 {
      font-family: "dm_sansregular";
      color: gray;
      display: flex;
      justify-content: start;
      font-size: 28px;
      font-weight:bold;
    }
    ul {
      list-style: none;
    }
  }
`;