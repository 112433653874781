const initialState = {
  isloading: true,
  user: "",
  name: "",
  email: "",
  phone_number: "",
  address: "",
  picode: "",
  gender: "",
  profile_photo: "",
  account_status: "",
  namee: "",
  emaile: "",
  phone_numbere: "",
  addresse: "",
  picodee: "",
  gendere: null,
  profile_photoe: "",
  account_statuse: "",
  premiume: "",
  gender_option:"",
};

export default initialState;
