import { Shopsearchrequest } from "../../../infrastructure/Shopsearch";

const actions = {
  setLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setSearch:
    (search) =>
    ({ setState }) => {
      setState({ search });
    },
  setShopdata:
    (shopdata) =>
    ({ setState }) => {
      setState({ shopdata });
    },
  Postrequestsearch:
    () =>
    async ({ dispatch, setState, getState }) => {
      dispatch(actions.setLoading(true));
      const{search}=getState();
      try {
        var bodyFormdata = new FormData();
        bodyFormdata.append("search",search)
        const res = await Shopsearchrequest(bodyFormdata);
        dispatch(actions.setShopdata(res.data.data));
      } catch (err) {
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
};
export default actions;
