import React, { useState, useEffect } from "react";
import { Type } from "./style";
import arrows from "../../Images/arrow.png";
import { ArrowRightOutlined, StarOutlined } from "@ant-design/icons";
import BackArrow from "../BackArrow";

import { Shoplisttype } from "./store";
import { useHistory } from "react-router-dom";

import { firebase } from "../../common/Firebase/index";
import { Modal, Select } from "antd";

import AOS from "aos";

import "aos/dist/aos.css";
function Types({ match }) {
  const { Option } = Select;
  const [opt, setopt] = useState(0);
  function handleChange(value) {
    setopt(value);
  }

  AOS.init();
  let History = useHistory();
  const Productlink = (id) => {
    History.push(`/products/${id}`);
  };
  const Createanevent = () => {
    History.push(`/eventcreate`);
  };
  const Cancelmodal = () => {
    History.push(`/home`);
  };
  const [
    { shopall, isLoading, iserrormodal, reserror, search },
    { Getallshoplist, setIserrormodal, setSearch },
  ] = Shoplisttype();

  const [idd, setidd] = useState(match.params.id);

  const [token, setToken] = useState("");
  useEffect(() => {
    // import { firebase } from "../../common/Firebase/index";
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
      if (token === "") {
      } else {
        Getallshoplist(idd, opt);
      }
    });
  }, [opt, token]);

  const showModal = () => {
    setIserrormodal(true);
  };

  const handleOk = () => {
    Createanevent();
    setIserrormodal(false);
  };

  const handleCancel = () => {
    Cancelmodal();
    setIserrormodal(false);
  };

  // let num = Shopview.ratings;
  // let rating = Math.round(num * 100) / 100;

  return (
    <Type>
      <div className="btns">
        <BackArrow />
      </div>
      <div className="background">
        <div className="wrapper">
          <div className="img">
            <img src={arrows} alt="img" />
          </div>
          <div className="separate">
            <div className="left">
              <h2>Findout What You Need.......</h2>
            </div>
            <div className="right">
              <span>Select Your Categories</span>
              <div className="select">
                {/* <select onChange={(e) => setopt(e.target.value)}>
                  <option value="All" style={{ cursor: "pointer" }}>
                    All
                  </option>
                  <option value="Restaurant">Restaurant</option>
                  <option value="Caters">Caters</option>
                  <option value="Chef">Chef</option>
                </select> */}
                <Select
                  defaultValue="All"
                  style={{ width: 150 }}
                  onChange={handleChange}
                >
                  <Option value="All">All</Option>
                  <Option value="Restaurant">Restaurant</Option>
                  <Option value="Caters">Caters</Option>

                  <Option value="Chef">Chef</Option>
                </Select>
              </div>
            </div>
          </div>
          <div className="search">
            <input
              type="text"
              placeholder="search for restaurant/caters/chef/food...."
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="heading">
        <div className="wrapper2">
          <div className="categories">
            <h1>Shops</h1>
            <h1>
              {" "}
              <ArrowRightOutlined />
            </h1>
            <h1>
              {" "}
              {opt === 0
                ? "All"
                : opt === "Restaurant"
                ? "Restaurant"
                : opt === "Caters"
                ? "Caters"
                : opt === "Chef"
                ? "Chef"
                : opt === "All"
                ? "All"
                : ""}
            </h1>
          </div>
          <div className="border"></div>
          <div className="item">
            {shopall
              ?.filter((res) => {
                if (search === "") {
                  return res;
                } else if (
                  res.name.toLowerCase().includes(search.toLowerCase())
                ) {
                  return res;
                }
              })
              .map((res) => (
                <div data-aos="zoom-in">
                  <div className="prods" onClick={() => Productlink(res.id)}>
                    <div className="image">
                      <img alt="img" src={res.image}></img>
                    </div>
                    <div className="heading"></div>
                  </div>
                  <div className="shopnamess" style={{ padding: "5px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h2>{res.name}</h2>
                      <small>
                        {res.ratings.toFixed(1)} <StarOutlined />
                      </small>
                    </div>

                    <div className="small">
                      <span>{res.opens_at} Am to </span>
                      <span>{res.close_at} PM</span>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="pagin"></div>
        </div>
      </div>
      <Modal visible={iserrormodal} onOk={handleOk} onCancel={handleCancel}>
        <h1 style={{ fontSize: 24 }}>Create event to view shop</h1>
      </Modal>
    </Type>
  );
}

export default Types;
