import React, { useState, useEffect } from "react";
import Logo from "../../Images/logo2.png";
import { Navbardiv, Notify } from "./style";
import {
  MenuOutlined,
  CloseOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import { Badge, Popconfirm, Popover, Drawer } from "antd";
import { useHistory } from "react-router";
import { notification } from "antd";
import {
  SearchOutlined,
  BellOutlined,
  UserOutlined,
  ShoppingCartOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { OurProfile } from "../UserProfile/store/index";
import AOS from "aos";
import "aos/dist/aos.css";
import { firebase } from "../../common/Firebase/index";
import { Getmycartitems } from "../CartPage/store";
import { Notificationdata } from "../Header/store";
import { Shopsanditems } from "../Products/store";
function Navbar() {
  AOS.init();
  //usestate................................................................

  const [{ profile_photo }, { getUser }] = OurProfile();
  // const [{ mycart }, { Getcartitems }] = Getmycartitems();
  const [{ Notification }, { setNotification, Getnotifications }] =
    Notificationdata();
    const [{ rendercart }, { setrendercart }] = Shopsanditems();
  //....

  let myuser = firebase.auth().currentUser;
  //slicing an array................................................................

  let gain = [];
  let mainreversesort = Notification;
  var firstRun =
    mainreversesort.length === 0
      ? gain
      : mainreversesort.sort(function (a, b) {
          return a.id < b.id;
        });

  const slicedarray =
    firstRun.length === 0
      ? gain
      : firstRun.filter((month, idx) => idx < 5) || gain;
  //useEffect...............
  useEffect(() => {
    getUser();
    // Getcartitems();
    Getnotifications();
  }, []);

  const location = useLocation();

  //routing

  let History = useHistory();
  const logoLink = () => {
    if (myuser === null ? History.push("/login") : History.push("/home"));
  };
  const HomeLink = () => {
    History.push("/home");
  };
  const ProfileLink = () => {
    History.push("/account");
  };
  const EventLink = () => {
    History.push("/eventpage");
  };
  const OrderLink = () => {
    History.push("/order");
  };
  const PowerLink = () => {
    firebase.auth().signOut();
    window.location.replace(`/login`);
    notification["success"]({
      message: "success",
      description: "User Signout Successfully",
    });
  };
  const ShopLink = () => {
    History.push("/cartpage");
  };
  const UserLink = () => {
    History.push("/account");
  };
  //drawerlink
  const logoLinkd = () => {
    History.push("/home");
  };
  const HomeLinkd = () => {
    History.push("/home");
    onClose();
  };
  const EventLinkd = () => {
    History.push("/eventpage");
    onClose();
  };
  const OrderLinkd = () => {
    History.push("/order");
    onClose();
  };
  const PowerLinkd = () => {
    History.push("/");
    onClose();
  };
  const ShopLinkd = () => {
    History.push("/cartpage");
    onClose();
  };
  const UserLinkd = () => {
    History.push("/account");
    onClose();
  };
  const NotificationLink = () => {
    History.push("/notification");
  };

  //
  const style = {
    cursor: "pointer",
    // height: "10px",

    color: "black",

    fontFamily: "montserratregular",
  };

  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  let mycarta = localStorage.getItem("cart");
  console.log(mycarta)
  //
  const contents = (
    <div>
      <small>Sign Out</small>
    </div>
  );
  return (
    <Navbardiv
      data-aos="fade-down"
      data-aos-easing="linear"
      data-aos-duration="600"
    >
      <div className="wrapper">
        {myuser === null ? (
          <div className="logo">
            <img src={Logo} alt="logo" onClick={() => logoLink()} />
          </div>
        ) : (
          <div className="logo">
            <img src={Logo} alt="logo" onClick={() => logoLink()} />
          </div>
        )}
        <div className="link">
          {location.pathname === `/` || myuser === null ? (
            ""
          ) : (
            <ul>
              <li onClick={() => HomeLink()} className="hoover">
                Home
              </li>

              <li onClick={() => EventLink()} className="hoover">
                Event
              </li>

              <li onClick={() => OrderLink()} className="hoover">
                Order
              </li>

              <li
                className="pic"
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  src={profile_photo}
                  alt="img"
                  style={{
                    width: "50px",
                    borderRadius: 40,
                    objectFit: "cover",
                    height: 50,
                  }}
                ></img>
              </li>
              <li>
                <Popover
                  content={
                    <Notify>
                      {slicedarray?.map((res) => (
                        <div className="main">
                          <small>{res?.body}</small>
                        </div>
                      ))}
                      <div className="second">
                        <h1>
                          <NotificationOutlined
                            onClick={() => NotificationLink()}
                            style={{ fontSize: "26px", color: "black" }}
                          />
                        </h1>
                      </div>
                    </Notify>
                  }
                  placement="bottom"
                >
                  <Badge
                    count={slicedarray.length}
                    style={{ backgroundColor: "#52c41a" }}
                  >
                    <BellOutlined
                      style={{ fontSize: "26px", color: "white",
                      opacity: ".5", }}
                    />
                  </Badge>
                </Popover>
              </li>

              <li>
                <Badge
                  count={mycarta === "undefined" ? 0 : mycarta}
                  showZero
                  onClick={() => ShopLink()}
                >
                  <ShoppingCartOutlined
                    onClick={() => ShopLink()}
                    style={{ fontSize: "26px",  color: "white",
                    opacity: ".5", }}
                  />
                </Badge>
              </li>

              <li>
                <UserOutlined
                  onClick={() => UserLink()}
                  style={{ fontSize: "26px", color: "white",
                  opacity: ".5",}}
                />
              </li>
              <li>
                <Popover content={contents} placement="right">
                  <Popconfirm
                    placement="bottom"
                    title="Are you sure"
                    onConfirm={() => PowerLink()}
                    okText="Yes"
                    cancelText="Cancel"
                  >
                    <PoweroffOutlined
                      style={{
                        fontSize: "36px",
                        color: "white",
                        opacity: ".5",
                      }}
                    />
                  </Popconfirm>
                </Popover>
              </li>
            </ul>
          )}
          {location.pathname === "/login" ? (
            ""
          ) : (
            <ul className="icon">
              <li>
                {" "}
                <MenuOutlined
                  onClick={showDrawer}
                  style={{ color: "white" }}
                />{" "}
              </li>
            </ul>
          )}
        </div>
        <Drawer
          placement="top"
          title="Basic Drawer"
          onClose={onClose}
          visible={visible}
        >
          <div
            className="icon"
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: "20px",
              marginRight: "40px",
            }}
          >
            <img
              src={Logo}
              alt="logo"
              style={{ width: "136px", marginTop: "-10px" }}
              onClick={() => logoLinkd()}
            />
            <CloseOutlined onClick={onClose} />
          </div>
          <div
            className="links"
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <ul
              style={{
                listStyle: "none",
                fontSize: "18px",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                cursor: "pointer",
                fontWeight: "bold",
              }}
            >
              <li onClick={() => HomeLinkd()}>Home</li>

              <li onClick={() => EventLinkd()}>Event</li>

              <li onClick={() => OrderLinkd()}>Order</li>
            </ul>
          </div>
          <div
            className="links"
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <ul
              style={{
                listStyle: "none",
                fontSize: "18px",
                display: "flex",
                gap: "40px",
                cursor: "pointer",
                fontWeight: "bold",
              }}
            >
              <li>
                <Badge count={slicedarray?.length}>
                  <BellOutlined
                    style={{
                      fontSize: "18px",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  />
                </Badge>
              </li>
              <li>
                <Badge
                  // count={mycart?.order_items?.length}
                  showZero
                  onClick={() => ShopLink()}
                >
                  <ShoppingCartOutlined
                    onClick={() => ShopLinkd()}
                    style={{
                      fontSize: "18px",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  />
                </Badge>
              </li>
              <li>
                <UserOutlined
                  onClick={() => UserLinkd()}
                  style={{ fontSize: "18px", fontWeight: "bold" }}
                />
              </li>
              <li>
                <Popover content={contents} placement="right">
                  <Popconfirm
                    placement="bottom"
                    title="Are you sure"
                    onConfirm={() => PowerLink()}
                    okText="Yes"
                    cancelText="Cancel"
                  >
                    <PoweroffOutlined
                      style={{ fontSize: "18px", fontWeight: "bold" }}
                    />
                  </Popconfirm>
                </Popover>
              </li>
            </ul>
          </div>
        </Drawer>
      </div>
    </Navbardiv>
  );
}

export default Navbar;
